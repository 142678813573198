export const pools = [
  {
    id: 'bifi-maxi',
    logo: 'single-assets/BIFI.png',
    name: 'BIFI Maxi',
    token: 'BIFI',
    tokenDescription: 'Beefy.Finance',
    tokenAddress: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://docs.beefy.finance/beefyfinance/products/bifi-maxi',
    earnedToken: 'mooBIFI',
    earnedTokenAddress: '0xf7069e41C57EcC5F122093811d8c75bdB5f7c14e',
    earnContractAddress: '0xf7069e41C57EcC5F122093811d8c75bdB5f7c14e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BIFI',
    oraclePrice: 0,
    status: 'active',
    platform: 'Beefy.Finance',
    assets: ['BIFI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Maxi',
    callFee: 0.5,
    withdrawalFee: '0.05%',
    buyTokenUrl: 'https://app.1inch.io/#/56/swap/BNB/BIFI',
    createdAt: 1623706144,
    network: 'bsc',
  },
  {
    id: '1inch-1inch',
    logo: 'single-assets/INCH.png',
    name: '1INCH',
    token: '1INCH',
    tokenDescription: '1Inch',
    tokenAddress: '0x111111111117dC0aa78b770fA6A738034120C302',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'moo1INCH1INCH',
    earnedTokenAddress: '0xBa53AF4C2f1649F82e8070FB306DDBF2771A1950',
    earnContractAddress: '0xBa53AF4C2f1649F82e8070FB306DDBF2771A1950',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: '1INCH',
    oraclePrice: 0,
    status: 'active',
    platform: '1Inch',
    assets: ['1INCH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    callFee: 0.5,
    stratType: 'SingleStake',
    buyTokenUrl:
      'https://1inch.exchange/#/r/0xF4cb25a1FF50E319c267b3E51CBeC2699FB2A43B/BNB/1INCH/?network=56',
    network: 'bsc',
  },
  {
    id: 'cake-cakev2',
    logo: 'single-assets/CAKE.png',
    name: 'CAKE',
    token: 'CAKE',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2',
    earnedTokenAddress: '0x97e5d50Fe0632A95b9cf1853E744E02f7D816677',
    earnContractAddress: '0x97e5d50Fe0632A95b9cf1853E744E02f7D816677',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['CAKE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    network: 'bsc',
  },
  {
    id: 'cakev2-ampl-bsc-mp-busd',
    logo: 'cake-pairs/ampl-bsc-mp-busd.svg',
    name: 'AMPL-BSC-mp-BUSD LP',
    token: 'AMPL-BSC-mp-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x6E98Beb694Ff1CDb1eE130eDD2b21b0298683d58',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2AMPL-BSC-MP-BUSD',
    earnedTokenAddress: '0x6F327421a2B4c96C3E6e7e9588423F02A5A0165B',
    earnContractAddress: '0x6F327421a2B4c96C3E6e7e9588423F02A5A0165B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ampl-bsc-mp-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['AMPL-BSC-mp', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'ALGO_STABLE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },
  {
    id: 'mdex-bsc-mdx',
    logo: 'single-assets/MDX.png',
    name: 'MDX',
    token: 'MDX',
    tokenDescription: 'Mdex',
    tokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexMDX',
    earnedTokenAddress: '0xc34C7923B73eB68E363AaEB2b7fbBeC11Cf90884',
    earnContractAddress: '0xc34C7923B73eB68E363AaEB2b7fbBeC11Cf90884',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'MDX',
    oraclePrice: 0,
    status: 'active',
    platform: 'Mdex',
    assets: ['MDX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl:
      'https://bsc.mdex.com/#/swap?inputCurrency=WBNB&outputCurrency=0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
    network: 'bsc',
  },
  {
    id: 'wex-eth-bnb',
    logo: 'eth-pairs/ETH-BNB.png',
    name: 'ETH-BNB WLP',
    token: 'ETH-BNB WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x04253aB3ff54D2E03b717BF6810a0a2Fd228365a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWaultETH-BNB',
    earnedTokenAddress: '0x8B238319E06009F5A8d89BC4667727f9624E52E1',
    earnContractAddress: '0x8B238319E06009F5A8d89BC4667727f9624E52E1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wex-eth-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['ETH', 'BNB'],
    risks: ['COMPLEXITY_LOW', 'IL_LOW', 'MCAP_SMALL', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.wault.finance/#/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    buyTokenUrl:
      'https://swap.wault.finance/#/swap?inputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c&outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    network: 'bsc',
  },
  {
    id: 'wex-usdt-alpaca',
    logo: 'usdt-pairs/ALPACA-USDT.png',
    name: 'USDT-ALPACA WLP',
    token: 'USDT-ALPACA WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0xCb1b3C77606c61C9C2eCA86f554f5806343757AD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWaultUSDT-ALPACA',
    earnedTokenAddress: '0x68f967F903Fb99D6e00d825ebaE482bE031584dA',
    earnContractAddress: '0x68f967F903Fb99D6e00d825ebaE482bE031584dA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wex-usdt-alpaca',
    oraclePrice: 0,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['USDT', 'ALPACA'],
    risks: ['COMPLEXITY_LOW', 'IL_HIGH', 'MCAP_SMALL', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.wault.finance/#/add/0x55d398326f99059ff775485246999027b3197955/0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
    buyTokenUrl:
      'https://swap.wault.finance/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
    network: 'bsc',
  },
  {
    id: 'wex-usdt-matic',
    logo: 'usdt-pairs/MATIC-USDT.png',
    name: 'USDT-MATIC WLP',
    token: 'USDT-MATIC WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x4aeA71115DAB37e79C0005a042d6c3985255f3CB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWaultUSDT-MATIC',
    earnedTokenAddress: '0x6Bc93525A78c151c6392b93A3f1E2DB7313fc2c7',
    earnContractAddress: '0x6Bc93525A78c151c6392b93A3f1E2DB7313fc2c7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wex-usdt-matic',
    oraclePrice: 0,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['USDT', 'MATIC'],
    risks: ['COMPLEXITY_LOW', 'IL_LOW', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.wault.finance/#/add/0x55d398326f99059ff775485246999027b3197955/0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
    buyTokenUrl:
      'https://swap.wault.finance/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
    network: 'bsc',
  },
  {
    id: 'wex-ltc-busd',
    logo: 'busd-pairs/LTC-BUSD.png',
    name: 'LTC-BUSD WLP',
    token: 'LTC-BUSD WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x1209067dC48B3E15B25126EC785EC6CE492E997d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWaultLTC-BUSD',
    earnedTokenAddress: '0x6281b00F67b84cB424d4A9b29bbdd904dCb61126',
    earnContractAddress: '0x6281b00F67b84cB424d4A9b29bbdd904dCb61126',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wex-ltc-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['LTC', 'BUSD'],
    risks: ['COMPLEXITY_LOW', 'IL_LOW', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.wault.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    buyTokenUrl:
      'https://swap.wault.finance/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    network: 'bsc',
  },
  {
    id: 'wex-ada-busd',
    logo: 'busd-pairs/ADA-BUSD.png',
    name: 'ADA-BUSD WLP',
    token: 'ADA-BUSD WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0xB24a49d02380fCDFbc320c25B0dCa997d2ed435D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWaultADA-BUSD',
    earnedTokenAddress: '0x847bA37DeE3d8757cB2493e14715ba079afe6865',
    earnContractAddress: '0x847bA37DeE3d8757cB2493e14715ba079afe6865',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wex-ada-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['ADA', 'BUSD'],
    risks: ['COMPLEXITY_LOW', 'IL_LOW', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.wault.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    buyTokenUrl:
      'https://swap.wault.finance/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    network: 'bsc',
  },
  {
    id: 'wex-xrp-busd',
    logo: 'busd-pairs/XRP-BUSD.png',
    name: 'XRP-BUSD WLP',
    token: 'XRP-BUSD WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x0e3ccA3D602eBEA46CF349B991Ee0d857A096d3D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWaultXRP-BUSD',
    earnedTokenAddress: '0x3b82B5740505908E181c9449E23f89Dc9F19B7a4',
    earnContractAddress: '0x3b82B5740505908E181c9449E23f89Dc9F19B7a4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wex-xrp-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['XRP', 'BUSD'],
    risks: ['COMPLEXITY_LOW', 'IL_LOW', 'MCAP_LARGE', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.wault.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
    buyTokenUrl:
      'https://swap.wault.finance/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
    network: 'bsc',
  },
  {
    id: 'wex-alpaca-bnb',
    logo: 'bnb-pairs/ALPACA-BNB.png',
    name: 'ALPACA-BNB WLP',
    token: 'ALPACA-BNB WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0xC70fE75DAE1d69245F315Cec8BF4aebe41893807',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWaultALPACA-BNB',
    earnedTokenAddress: '0xD2111F59eaE79B7066D2B5970D6755d2e3bEa358',
    earnContractAddress: '0xD2111F59eaE79B7066D2B5970D6755d2e3bEa358',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wex-alpaca-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['ALPACA', 'BNB'],
    risks: ['COMPLEXITY_LOW', 'IL_HIGH', 'MCAP_SMALL', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.wault.finance/#/add/BNB/0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
    buyTokenUrl:
      'https://swap.wault.finance/#/swap?outputCurrency=0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
    network: 'bsc',
  },
  {
    id: 'wex-usdt-wex',
    logo: 'usdt-pairs/WEX-USDT.png',
    name: 'USDT-WEX WLP',
    token: 'USDT-WEX WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0x50e8D9Aa83eBDe9608074eC1faaDfD2E792D9B81',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWaultUSDT-WEX',
    earnedTokenAddress: '0xdC048A42a6a57483EDC40C549b58d3A5fE5E5218',
    earnContractAddress: '0xdC048A42a6a57483EDC40C549b58d3A5fE5E5218',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wex-usdt-wex',
    oraclePrice: 0,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['USDT', 'WEX'],
    risks: ['COMPLEXITY_LOW', 'IL_HIGH', 'MCAP_SMALL', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.wault.finance/#/add/0x55d398326f99059ff775485246999027b3197955/0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
    buyTokenUrl:
      'https://swap.wault.finance/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
    network: 'bsc',
  },
  {
    id: 'wex-wex-bnb',
    logo: 'bnb-pairs/WEX-BNB.png',
    name: 'WEX-BNB WLP',
    token: 'WEX-BNB WLP',
    tokenDescription: 'WaultFinance',
    tokenAddress: '0xb4814f1ca673dBE9a2637C5dd4e94A4a0ED834C6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooWaultWEX-BNB',
    earnedTokenAddress: '0x8988EE959828A41872734D1408d7dC612f085872',
    earnContractAddress: '0x8988EE959828A41872734D1408d7dC612f085872',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'wex-wex-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'WaultFinance',
    assets: ['WEX', 'BNB'],
    risks: ['COMPLEXITY_LOW', 'IL_HIGH', 'MCAP_SMALL', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.wault.finance/#/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
    buyTokenUrl:
      'https://swap.wault.finance/#/swap?inputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c&outputCurrency=0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
    network: 'bsc',
  },
  {
    id: 'cakev2-btcb-eth',
    logo: 'cake-pairs/btcb-eth.svg',
    name: 'BTCB-ETH LP',
    token: 'BTCB-ETH LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xD171B26E4484402de70e3Ea256bE5A2630d7e88D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BTCB-ETH',
    earnedTokenAddress: '0xEf43E54Bb4221106953951238FC301a1f8939490',
    earnContractAddress: '0xEf43E54Bb4221106953951238FC301a1f8939490',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-btcb-eth',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ETH', 'BTCB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8&outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    network: 'bsc',
  },
  {
    id: 'cakev2-eth-usdc',
    logo: 'cake-pairs/eth-usdc.svg',
    name: 'ETH-USDC LP',
    token: 'ETH-USDC LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xEa26B78255Df2bBC31C1eBf60010D78670185bD0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ETH-USDC',
    earnedTokenAddress: '0x26107644A6dbC38385F4B7263d9bA96D829eC090',
    earnContractAddress: '0x26107644A6dbC38385F4B7263d9bA96D829eC090',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-eth-usdc',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ETH', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8&outputCurrency=0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    network: 'bsc',
  },
  {
    id: 'banana-typh-bnb',
    logo: 'bnb-pairs/typh-bnb.svg',
    name: 'TYPH-BNB LP',
    token: 'TYPH-BNB ApeLP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xFEaf192c2662E5700bDa860c58d2686d9cc12Ec8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeTYPH-BNB',
    earnedTokenAddress: '0xdAfE6095F8e2509CfF05E489eE971dB0EdED8d6f',
    earnContractAddress: '0xdAfE6095F8e2509CfF05E489eE971dB0EdED8d6f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-typh-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['TYPH', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x4090e535f2e251f5f88518998b18b54d26b3b07c',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x4090e535f2e251f5f88518998b18b54d26b3b07c',
    network: 'bsc',
  },
  {
    id: 'tenfi-tenfi-bnb',
    logo: 'single-assets/TENFI.png',
    name: 'TENFI-BNB LP',
    token: 'TENFI-BNB LP',
    tokenDescription: 'PancakeSwap (Ten)',
    tokenAddress: '0x09F39f9B7d6395155396Fed7347620dD54Da1dc6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTenTEN-BNB',
    earnedTokenAddress: '0x0Ce6FeC7521b7d054b5B0a5F43D49120f9d6CFbc',
    earnContractAddress: '0x0Ce6FeC7521b7d054b5B0a5F43D49120f9d6CFbc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tenfi-tenfi-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['TENFI', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'AUDIT',
      'PLATFORM_NEW',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xd15c444f1199ae72795eba15e8c1db44e47abf62',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xd15c444f1199ae72795eba15e8c1db44e47abf62',
    network: 'bsc',
  },
  {
    id: 'tenfi-tenfi-busd',
    logo: 'single-assets/TENFI.png',
    name: 'TENFI-BUSD LP',
    token: 'TENFI-BUSD LP',
    tokenDescription: 'PancakeSwap (Ten)',
    tokenAddress: '0xac6EE351e2E9108f03c7F5c49296505B8F336Be3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTenTEN-BUSD',
    earnedTokenAddress: '0x00F204F524a433f45a6278c2C0E10F5292b3C5B9',
    earnContractAddress: '0x00F204F524a433f45a6278c2C0E10F5292b3C5B9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tenfi-tenfi-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['TENFI', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'AUDIT',
      'PLATFORM_NEW',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xd15c444f1199ae72795eba15e8c1db44e47abf62',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xd15c444f1199ae72795eba15e8c1db44e47abf62',
    network: 'bsc',
  },
  {
    id: 'cakev2-mbox-bnb',
    logo: 'bnb-pairs/MBOX-BNB.svg',
    name: 'MBOX-BNB LP',
    token: 'MBOX-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x8FA59693458289914dB0097F5F366d771B7a7C3F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2MBOX-BNB',
    earnedTokenAddress: '0x2Dd2a81d1B44723f6106f621d0D64848fbdbCc77',
    earnContractAddress: '0x2Dd2a81d1B44723f6106f621d0D64848fbdbCc77',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-mbox-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['MBOX', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
    network: 'bsc',
  },
  {
    id: 'banana-celr-bnb',
    logo: 'bnb-pairs/CELR-BNB.svg',
    name: 'CELR-BNB LP',
    token: 'CELR-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xB7F42e58Cf2364ac994F93f7afF3b158CFA3dC76',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeCELR-BNB',
    earnedTokenAddress: '0x04E56F75EDD898BEe4A136bDBAb2b47Be59109c8',
    earnContractAddress: '0x04E56F75EDD898BEe4A136bDBAb2b47Be59109c8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-celr-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['CELR', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x1f9f6a696C6Fd109cD3956F45dC709d2b3902163',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x1f9f6a696C6Fd109cD3956F45dC709d2b3902163',
    network: 'bsc',
  },
  {
    id: 'banana-shib-bnb',
    logo: 'bnb-pairs/SHIB-BNB.svg',
    name: 'SHIB-BNB LP',
    token: 'SHIB-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xC0AFB6078981629F7eAe4f2ae93b6DBEA9D7a7e9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSHIB-BNB',
    earnedTokenAddress: '0x25699A5Bdcb524e3030a89c2f5088a076DC562bC',
    earnContractAddress: '0x25699A5Bdcb524e3030a89c2f5088a076DC562bC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-shib-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['SHIB', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
    network: 'bsc',
  },
  {
    id: 'cakev2-ata-bnb',
    logo: 'bnb-pairs/ATA-BNB.svg',
    name: 'ATA-BNB LP',
    token: 'ATA-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xeF7767677867552CFA699148b96a03358A9be779',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ATA-BNB',
    earnedTokenAddress: '0x76746cDb7C1DDA92c6211D5Cc4bC03859c5Ee8a1',
    earnContractAddress: '0x76746cDb7C1DDA92c6211D5Cc4bC03859c5Ee8a1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ata-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ATA', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xa2120b9e674d3fc3875f415a7df52e382f141225',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xa2120b9e674d3fc3875f415a7df52e382f141225',
    network: 'bsc',
  },
  {
    id: 'panther-panther-busd',
    logo: 'degens/panther-busd.png',
    name: 'PANTHER-BUSD LP',
    token: 'PANTHER-BUSD LP',
    tokenDescription: 'PantherSwap',
    tokenAddress: '0x9287F5Ad55D7eE8EAE90B865718EB9A7cF3fb71A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPantherPANTHER-BUSD',
    earnedTokenAddress: '0x3f8664Bc085aCceb2bC311C0F56bAA44F9C17204',
    earnContractAddress: '0x3f8664Bc085aCceb2bC311C0F56bAA44F9C17204',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'panther-panther-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['PANTHER', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'AUDIT',
      'PLATFORM_NEW',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.pantherswap.com/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x1f546aD641B56b86fD9dCEAc473d1C7a357276B7',
    buyTokenUrl:
      'https://dex.pantherswap.com/#/swap?outputCurrency=0x1f546ad641b56b86fd9dceac473d1c7a357276b7',
    network: 'bsc',
  },
  {
    id: 'panther-panther-bnb',
    logo: 'degens/panther-bnb.png',
    name: 'PANTHER-BNB LP',
    token: 'PANTHER-BNB LP',
    tokenDescription: 'PantherSwap',
    tokenAddress: '0xC24AD5197DaeFD97DF28C70AcbDF17d9fF92a49B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPantherPANTHER-BNB',
    earnedTokenAddress: '0xF2E57C6156aE3bFD107AE6187D53dd621D508D02',
    earnContractAddress: '0xF2E57C6156aE3bFD107AE6187D53dd621D508D02',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'panther-panther-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['PANTHER', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'AUDIT',
      'PLATFORM_NEW',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.pantherswap.com/#/add/ETH/0x1f546aD641B56b86fD9dCEAc473d1C7a357276B7',
    buyTokenUrl:
      'https://dex.pantherswap.com/#/swap?outputCurrency=0x1f546ad641b56b86fd9dceac473d1c7a357276b7',
    network: 'bsc',
  },
  {
    id: 'garuda-garuda-bnb',
    logo: 'degens/garuda-bnb.png',
    name: 'GARUDA-BNB LP',
    token: 'GARUDA-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x1AB609b5d930c64Be12A05D040A9822b0Da84A8F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGarudaGARUDA-BNB',
    earnedTokenAddress: '0xeC16330D826459C8497Cb7B5e44f8Aa88f28470B',
    earnContractAddress: '0xeC16330D826459C8497Cb7B5e44f8Aa88f28470B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'garuda-garuda-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['GARUDA', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x854086dC841e1bfae50Cb615bF41f55BF432a90b',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x854086dC841e1bfae50Cb615bF41f55BF432a90b',
    network: 'bsc',
  },
  {
    id: 'cakev2-mtrg-busd',
    logo: 'busd-pairs/MTRG-BUSD.svg',
    name: 'MTRG-BUSD LP',
    token: 'MTRG-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x4dcA4D427511bC327639b222DA18FA5e334F686F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2MTRG-BUSD',
    earnedTokenAddress: '0xD1A43172f05D0D9a3DD1B9c861332378FdaDB88c',
    earnContractAddress: '0xD1A43172f05D0D9a3DD1B9c861332378FdaDB88c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-mtrg-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['MTRG', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F/0xe9e7cea3dedca5984780bafc599bd69add087d56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F',
    network: 'bsc',
  },
  {
    id: 'cakev2-qkc-busd',
    logo: 'busd-pairs/QKC-BUSD.svg',
    name: 'QKC-BUSD LP',
    token: 'QKC-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x8853E3309a31583eA438F7704681F46F0D4D909b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2QKC-BUSD',
    earnedTokenAddress: '0xE61b206339EA172824fBF334cADFe2A6b0dEbAeD',
    earnContractAddress: '0xE61b206339EA172824fBF334cADFe2A6b0dEbAeD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-qkc-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['QKC', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xa1434f1fc3f437fa33f7a781e041961c0205b5da/0xe9e7cea3dedca5984780bafc599bd69add087d56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0xa1434f1fc3f437fa33f7a781e041961c0205b5da',
    network: 'bsc',
  },
  {
    id: 'cakev2-mx-busd',
    logo: 'busd-pairs/MX-BUSD.svg',
    name: 'MX-BUSD LP',
    token: 'MX-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x41F049D990D38305504631C9835f6F856Bf1BA67',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2MX-BUSD',
    earnedTokenAddress: '0x7e7CEbCC006c74a406138BF94CA3815CE04461e0',
    earnContractAddress: '0x7e7CEbCC006c74a406138BF94CA3815CE04461e0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-mx-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['MX', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x9f882567a62a5560d147d64871776eea72df41d3/0xe9e7cea3dedca5984780bafc599bd69add087d56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x9f882567a62a5560d147d64871776eea72df41d3',
    network: 'bsc',
  },
  {
    id: 'cakev2-bcfx-busd',
    logo: 'busd-pairs/BCFX-BUSD.svg',
    name: 'BCFX-BUSD LP',
    token: 'BCFX-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xA0387eBeA6be90849c2261b911fBBD52B4C9eAC4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BCFX-BUSD',
    earnedTokenAddress: '0x642b255718D365212f1CD2c7DFc8409573158e1d',
    earnContractAddress: '0x642b255718D365212f1CD2c7DFc8409573158e1d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bcfx-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BCFX', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x045c4324039dA91c52C55DF5D785385Aab073DcF/0xe9e7cea3dedca5984780bafc599bd69add087d56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x045c4324039dA91c52C55DF5D785385Aab073DcF',
    network: 'bsc',
  },
  {
    id: 'cakev2-ktn-bnb',
    logo: 'bnb-pairs/KTN-BNB.svg',
    name: 'KTN-BNB LP',
    token: 'KTN-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x48028De4A9b0d3D91180333d796021ec7757bA1B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2KTN-BNB',
    earnedTokenAddress: '0xBD322911Bd9E944743CDb88Cb5f9da33B5CD55BE',
    earnContractAddress: '0xBD322911Bd9E944743CDb88Cb5f9da33B5CD55BE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ktn-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['KTN', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xDAe6c2A48BFAA66b43815c5548b10800919c993E',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xDAe6c2A48BFAA66b43815c5548b10800919c993E',
    network: 'bsc',
  },
  {
    id: 'ellipsis-eps',
    logo: 'uncategorized/EPS.png',
    name: 'EPS',
    token: 'EPS',
    tokenDescription: 'Ellipsis',
    tokenAddress: '0xA7f552078dcC247C2684336020c03648500C6d9F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooEllipsisEPS',
    earnedTokenAddress: '0x0CDAf84f90F0BBFF225A82D7165967Fff93A7bea',
    earnContractAddress: '0x0CDAf84f90F0BBFF225A82D7165967Fff93A7bea',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'EPS',
    oraclePrice: 0,
    status: 'active',
    platform: 'Ellipsis',
    assets: ['EPS'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xA7f552078dcC247C2684336020c03648500C6d9F',
    network: 'bsc',
  },
  {
    id: 'dopple-dop-lp',
    logo: 'uncategorized/DOPPLE-DOP-LP.svg',
    name: 'BUSD/USDT/USDC/DAI',
    token: 'BUSD/USDT/USDC/DAI',
    tokenDescription: 'Dopple',
    tokenAddress: '0x9116F04092828390799514Bac9986529d70c3791',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDoppleDopLP',
    earnedTokenAddress: '0x8464365600d6A1b86A7f7c80DCD63AFa861069dB',
    earnContractAddress: '0x8464365600d6A1b86A7f7c80DCD63AFa861069dB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dopple-dop-lp',
    oraclePrice: 0,
    status: 'active',
    assets: ['BUSD', 'USDT', 'USDC', 'DAI'],
    platform: 'Dopple',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    callFee: 0.5,
    addLiquidityUrl: 'https://dopple.finance/Deposit/dop-lps',
    network: 'bsc',
  },
  {
    id: 'cakev2-tusd-busd',
    logo: 'busd-pairs/TUSD-BUSD.svg',
    name: 'TUSD-BUSD LP',
    token: 'TUSD-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x2E28b9B74D6d99D4697e913b82B41ef1CAC51c6C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2TUSD-BUSD',
    earnedTokenAddress: '0x62Cce60Bca43926e062ca2A4e2DcdAbEA5D2EAD2',
    earnContractAddress: '0x62Cce60Bca43926e062ca2A4e2DcdAbEA5D2EAD2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-tusd-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['TUSD', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x14016e85a25aeb13065688cafb43044c2ef86784',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x14016e85a25aeb13065688cafb43044c2ef86784',
    network: 'bsc',
  },
  {
    id: 'mdex-bsc-bnb-usdt',
    logo: 'usdt-pairs/BNB-USDT.png',
    name: 'BNB-USDT LP',
    token: 'BNB-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x09CB618bf5eF305FadfD2C8fc0C26EeCf8c6D5fd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexBNB-USDT',
    earnedTokenAddress: '0x7638db36fc21E030D5F8158B27181c3b27418bE2',
    earnContractAddress: '0x7638db36fc21E030D5F8158B27181c3b27418bE2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-bsc-bnb-usdt',
    oraclePrice: 0,
    status: 'active',
    platform: 'Mdex',
    assets: ['BNB', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    buyTokenUrl:
      'https://bsc.mdex.com/#/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955',
    addLiquidityUrl: 'https://bsc.mdex.com/#/add/BNB/0x55d398326f99059fF775485246999027B3197955',
    network: 'bsc',
  },
  {
    id: 'mdex-bsc-fil-usdt',
    logo: 'usdt-pairs/FIL-USDT.png',
    name: 'FIL-USDT LP',
    token: 'FIL-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0xCAABda10a3ac99Fc15f5B636Aa18E6B4Fd8db16D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexFIL-USDT',
    earnedTokenAddress: '0xE4E5795596E153e8f39D35Ff8559ae4D7bd03622',
    earnContractAddress: '0xE4E5795596E153e8f39D35Ff8559ae4D7bd03622',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-bsc-fil-usdt',
    oraclePrice: 0,
    status: 'active',
    platform: 'Mdex',
    assets: ['FIL', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    buyTokenUrl:
      'https://bsc.mdex.com/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
    addLiquidityUrl:
      'https://bsc.mdex.com/#/add/0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153/0x55d398326f99059fF775485246999027B3197955',
    network: 'bsc',
  },
  {
    id: 'cakev2-vrt-bnb',
    logo: 'bnb-pairs/VRT-BNB.svg',
    name: 'VRT-BNB LP',
    token: 'VRT-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xEf5212aDa83EC2cc105C409DF10b8806D20E3b35',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2VRT-BNB',
    earnedTokenAddress: '0xA8cC688E9B387b0cBe41D0CcF55875CE2318695A',
    earnContractAddress: '0xA8cC688E9B387b0cBe41D0CcF55875CE2318695A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-vrt-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['VRT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x5f84ce30dc3cf7909101c69086c50de191895883',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x5f84ce30dc3cf7909101c69086c50de191895883',
    network: 'bsc',
  },
  {
    id: 'cakev2-ez-bnb',
    logo: 'bnb-pairs/EZ-BNB.svg',
    name: 'EZ-BNB LP',
    token: 'EZ-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x51BF99bBae59b67E5cE2fA9c17b683384773F8b3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2EZ-BNB',
    earnedTokenAddress: '0x3Ee4b86Bf6BbceBf46695C0F3703F87Ff18a776b',
    earnContractAddress: '0x3Ee4b86Bf6BbceBf46695C0F3703F87Ff18a776b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ez-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['EZ', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x5512014efa6cd57764fa743756f7a6ce3358cc83',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x5512014efa6cd57764fa743756f7a6ce3358cc83',
    network: 'bsc',
  },
  {
    id: 'cakev2-kalm-bnb',
    logo: 'bnb-pairs/KALM-BNB.svg',
    name: 'KALM-BNB LP',
    token: 'KALM-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xc74f7243766269DeC5b85b0eF4aF186e909c1b06',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2KALM-BNB',
    earnedTokenAddress: '0x487D8014F8803757e0542e127854DEBd213A39e5',
    earnContractAddress: '0x487D8014F8803757e0542e127854DEBd213A39e5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-kalm-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['KALM', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x4ba0057f784858a48fe351445c672ff2a3d43515',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4ba0057f784858a48fe351445c672ff2a3d43515',
    network: 'bsc',
  },
  {
    id: 'jetswap-wings',
    logo: 'degens/WINGS.svg',
    name: 'WINGS',
    token: 'WINGS',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x0487b824c8261462F88940f97053E65bDb498446',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetswapWINGS',
    earnedTokenAddress: '0x5e784882E8D2ac69777713f0e71100741b8aC2F6',
    earnContractAddress: '0x5e784882E8D2ac69777713f0e71100741b8aC2F6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WINGS',
    oraclePrice: 0,
    status: 'active',
    platform: 'JetSwap',
    assets: ['WINGS'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ADMIN_WITHOUT_TIMELOCK',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.jetswap.finance/#/swap?outputCurrency=0x0487b824c8261462f88940f97053e65bdb498446',
    network: 'bsc',
  },
  {
    id: 'dopple-ust-lp',
    logo: 'uncategorized/DOPPLE-UST-LP.png',
    name: 'UST/BUSD/USDT',
    token: 'UST/BUSD/USDT',
    tokenDescription: 'Dopple',
    tokenAddress: '0x7Edcdc8cD062948CE9A9bc38c477E6aa244dD545',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDoppleUstLP',
    earnedTokenAddress: '0x4Af8ED7c9f5D0C672F71a82c827FC3A02485791F',
    earnContractAddress: '0x4Af8ED7c9f5D0C672F71a82c827FC3A02485791F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dopple-ust-lp',
    oraclePrice: 0,
    status: 'active',
    assets: ['BUSD', 'USDT', 'UST'],
    platform: 'Dopple',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    callFee: 0.5,
    addLiquidityUrl: 'https://dopple.finance/Deposit/ust-pools-lps',
    network: 'bsc',
  },
  {
    id: 'cakev2-bifi-bnb',
    logo: 'bnb-pairs/BIFI-BNB.svg',
    name: 'BIFI-BNB LP',
    token: 'BIFI-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x3f1A9f3D9aaD8bD339eD4853F345d2eF89fbfE0c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BIFI-BNB',
    earnedTokenAddress: '0x59960d624600aE1669584Bb504a5f4F2e90EbF46',
    earnContractAddress: '0x59960d624600aE1669584Bb504a5f4F2e90EbF46',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bifi-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BIFI', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    network: 'bsc',
  },
  {
    id: 'banana-sushi-bnb',
    logo: 'degens/BANANA-SUSHI-BNB.svg',
    name: 'SUSHI-BNB LP',
    token: 'SUSHI-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x1D0C3044eBf055986c52D38b19B5369374E6Bc6A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSUSHI-BNB',
    earnedTokenAddress: '0x0DC52004341103FF00C65b9c1370c2c2d125CBc5',
    earnContractAddress: '0x0DC52004341103FF00C65b9c1370c2c2d125CBc5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-sushi-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['SUSHI', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x947950bcc74888a40ffa2593c5798f11fc9124c4',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x947950bcc74888a40ffa2593c5798f11fc9124c4',
    network: 'bsc',
  },
  {
    id: 'banana-dot-bnb',
    logo: 'degens/BANANA-DOT-BNB.svg',
    name: 'DOT-BNB LP',
    token: 'DOT-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x21CBb561c5d7D70e9E6Cc42136CB22F07552aEef',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeDOT-BNB',
    earnedTokenAddress: '0x036D059Dbf58CB624CfdFDaAbCa6D3f80c2f8b3C',
    earnContractAddress: '0x036D059Dbf58CB624CfdFDaAbCa6D3f80c2f8b3C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-dot-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['DOT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
    network: 'bsc',
  },
  {
    id: 'banana-xrp-bnb',
    logo: 'degens/BANANA-XRP-BNB.svg',
    name: 'XRP-BNB LP',
    token: 'XRP-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x6f0f18f5fcC1466ec41c8106689e10BeFE68D1c0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeXRP-BNB',
    earnedTokenAddress: '0xb8624845cfaC1B65Da120bD25d0C1a921f8B178d',
    earnContractAddress: '0xb8624845cfaC1B65Da120bD25d0C1a921f8B178d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-xrp-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['XRP', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
    network: 'bsc',
  },
  {
    id: 'banana-snx-bnb',
    logo: 'bnb-pairs/SNX-BNB.svg',
    name: 'SNX-BNB LP',
    token: 'SNX-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x8b1F1F28a8CcbaA8a8Bc1582921ECe97Ce99d9e1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeSNX-BNB',
    earnedTokenAddress: '0x0e4FCD49Bd0A50D31f321509C8B2e7f21Fb87D26',
    earnContractAddress: '0x0e4FCD49Bd0A50D31f321509C8B2e7f21Fb87D26',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-snx-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['SNX', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0x9Ac983826058b8a9C7Aa1C9171441191232E8404/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x9Ac983826058b8a9C7Aa1C9171441191232E8404',
    network: 'bsc',
  },
  {
    id: 'banana-blz-bnb',
    logo: 'bnb-pairs/BLZ-BNB.svg',
    name: 'BLZ-BNB LP',
    token: 'BLZ-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x015f807d0186f7e62810D0C597A23CB19Ff57E4d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBLZ-BNB',
    earnedTokenAddress: '0x6081601e62F51d9e956aa704B506E4c202E0E016',
    earnContractAddress: '0x6081601e62F51d9e956aa704B506E4c202E0E016',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-blz-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BLZ', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0x935a544bf5816e3a7c13db2efe3009ffda0acda2/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x935a544bf5816e3a7c13db2efe3009ffda0acda2',
    network: 'bsc',
  },
  {
    id: 'cakev2-popen-bnb',
    logo: 'bnb-pairs/POPEN-BNB.svg',
    name: 'pOPEN-BNB LP',
    token: 'pOPEN-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x1090c996Fd1490D15dD7906322eE676A5cC3cF82',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2POPEN-BNB',
    earnedTokenAddress: '0xC1D74e394ed525A780f3a02d6b3716803035e6bE',
    earnContractAddress: '0xC1D74e394ed525A780f3a02d6b3716803035e6bE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-popen-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['pOPEN', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=BNB&outputCurrency=0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5',
    network: 'bsc',
  },
  {
    id: 'cakev2-well-busd',
    logo: 'busd-pairs/WELL-BUSD.svg',
    name: 'WELL-BUSD LP',
    token: 'WELL-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x1d94cb25895AbD6ccFeF863c53372bb462AA6b86',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2WELL-BUSD',
    earnedTokenAddress: '0x78B8AfFA682ef34ac92b800715C3AdfF7E2D3879',
    earnContractAddress: '0x78B8AfFA682ef34ac92b800715C3AdfF7E2D3879',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-well-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['WELL', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xf07a32eb035b786898c00bb1c64d8c6f8e7a46d5/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xf07a32eb035b786898c00bb1c64d8c6f8e7a46d5',
    network: 'bsc',
  },
  {
    id: 'banana-ltc-bnb',
    logo: 'degens/banana-ltc-bnb.svg',
    name: 'LTC-BNB LP',
    token: 'LTC-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x0F12362c017Fe5101c7bBa09390f1CB729f5B318',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeLTC-BNB',
    earnedTokenAddress: '0xB25e82640c062365B973E9eB55EEF77C47E81296',
    earnContractAddress: '0xB25e82640c062365B973E9eB55EEF77C47E81296',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-ltc-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['LTC', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    network: 'bsc',
  },
  {
    id: 'cakev2-cake-busd',
    logo: 'cake-pairs/CAKE-BUSD.svg',
    name: 'CAKE-BUSD LP',
    token: 'CAKE-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x804678fa97d91B974ec2af3c843270886528a9E6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2CAKE-BUSD',
    earnedTokenAddress: '0x1542885D6EeD4EE3AC1a110d3f159003a0774101',
    earnContractAddress: '0x1542885D6EeD4EE3AC1a110d3f159003a0774101',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-cake-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['CAKE', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },
  {
    id: 'cakev2-chr-busd',
    logo: 'cake-pairs/chr-busd.svg',
    name: 'CHR-BUSD LP',
    token: 'CHR-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x6045931E511EF7e53A4A817F971e0CA28c758809',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2CHR-BUSD',
    earnedTokenAddress: '0x324888479Bfe3903cB405959Bc9Cd644000e495A',
    earnContractAddress: '0x324888479Bfe3903cB405959Bc9Cd644000e495A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-chr-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['CHR', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
    network: 'bsc',
  },
  {
    id: 'cakev2-deri-busd',
    logo: 'cake-pairs/deri-busd.svg',
    name: 'DERI-BUSD LP',
    token: 'DERI-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xDc7188AC11e124B1fA650b73BA88Bf615Ef15256',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2DERI-BUSD',
    earnedTokenAddress: '0x6D02b2d38902689aF2c18eafb8B8c84161c6341b',
    earnContractAddress: '0x6D02b2d38902689aF2c18eafb8B8c84161c6341b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-deri-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['DERI', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },
  {
    id: 'hps-hps',
    logo: 'single-assets/HPS.png',
    name: 'HPS',
    token: 'HPS',
    tokenDescription: 'BillionHappiness',
    tokenAddress: '0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBillionHPS',
    earnedTokenAddress: '0xAC8E64BE515fAe8c6026fb22954d4D1375938Bb5',
    earnContractAddress: '0xAC8E64BE515fAe8c6026fb22954d4D1375938Bb5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'HPS',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['HPS'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl:
      'https://julswap.com/#/swap?inputCurrency=BNB&outputCurrency=0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D',
    network: 'bsc',
  },
  {
    id: 'dopple-dolly-lp',
    logo: 'uncategorized/DOPPLE-DOLLY-LP.svg',
    name: 'DOLLY/BUSD/USDT',
    token: 'DOLLY/BUSD/USDT',
    tokenDescription: 'Dopple',
    tokenAddress: '0xAA5509Ce0ecEA324bff504A46Fc61EB75Cb68B0c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDoppleDollyLP',
    earnedTokenAddress: '0xCcb7A9E2e4F08407065f51d32C365304fbF2FD01',
    earnContractAddress: '0xCcb7A9E2e4F08407065f51d32C365304fbF2FD01',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dopple-dolly-lp',
    oraclePrice: 0,
    status: 'active',
    assets: ['DOLLY', 'USDT', 'BUSD'],
    platform: 'Dopple',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'LIQ_LOW',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    callFee: 0.5,
    addLiquidityUrl: 'https://dopple.finance/Deposit/dolly-lps',
    network: 'bsc',
  },

  {
    id: 'cakev2-xend-bnb',
    logo: 'bnb-pairs/XEND-BNB.svg',
    name: 'XEND-BNB LP',
    token: 'XEND-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xcECfC2789af72ed151589a96a59f3A1ABC65c3b5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2XEND-BNB',
    earnedTokenAddress: '0x6F3ED82fbE292055D509D0200f02d8F9fE62E5D3',
    earnContractAddress: '0x6F3ED82fbE292055D509D0200f02d8F9fE62E5D3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-xend-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['XEND', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x4a080377f83D669D7bB83B3184a8A5E61B500608',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4a080377f83D669D7bB83B3184a8A5E61B500608',
    network: 'bsc',
  },

  {
    id: 'cakev2-cyc-bnb',
    logo: 'bnb-pairs/CYC-BNB.svg',
    name: 'CYC-BNB LP',
    token: 'CYC-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xECf30FbecfA642012f54212a3bE92eEF1e48eDaC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2CYC-BNB',
    earnedTokenAddress: '0xe4e94eAD9D926443C02c11E6453D31f8810D5186',
    earnContractAddress: '0xe4e94eAD9D926443C02c11E6453D31f8810D5186',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-cyc-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['CYC', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x810EE35443639348aDbbC467b33310d2AB43c168',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x810EE35443639348aDbbC467b33310d2AB43c168',
    network: 'bsc',
  },
  {
    id: 'banana-near-bnb',
    logo: 'bnb-pairs/NEAR-BNB.svg',
    name: 'NEAR-BNB LP',
    token: 'NEAR-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xB75724635A6cDa850f08b578F23a568CeDba099D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeNEAR-BNB',
    earnedTokenAddress: '0x37E7A54830877Ec8c163aAD72A4aa7B0dA2Ad6cb',
    earnContractAddress: '0x37E7A54830877Ec8c163aAD72A4aa7B0dA2Ad6cb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-near-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['NEAR', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0x1fa4a73a3f0133f0025378af00236f3abdee5d63/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x1fa4a73a3f0133f0025378af00236f3abdee5d63',
    network: 'bsc',
  },

  {
    id: 'banana-coti-bnb',
    logo: 'bnb-pairs/COTI-BNB.svg',
    name: 'COTI-BNB LP',
    token: 'COTI-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xaCfDCF0486ADC2421aAc3FFc0923b9c56FAEBC47',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeCOTI-BNB',
    earnedTokenAddress: '0x0dd3afc66F1250eC402E703CA093EE17537478CB',
    earnContractAddress: '0x0dd3afc66F1250eC402E703CA093EE17537478CB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-coti-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['COTI', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0xAdBAF88B39D37Dc68775eD1541F1bf83A5A45feB/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0xAdBAF88B39D37Dc68775eD1541F1bf83A5A45feB',
    network: 'bsc',
  },

  {
    id: 'banana-etc-bnb',
    logo: 'bnb-pairs/ETC-BNB.svg',
    name: 'ETC-BNB LP',
    token: 'ETC-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xDd6C7A955C72B3FFD546d8dadBf7669528d8F785',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeETC-BNB',
    earnedTokenAddress: '0x3D64f2950412a7eed6B59704D18B07B3C22411F5',
    earnContractAddress: '0x3D64f2950412a7eed6B59704D18B07B3C22411F5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-etc-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['ETC', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0x3d6545b08693daE087E957cb1180ee38B9e3c25E/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x3d6545b08693daE087E957cb1180ee38B9e3c25E',
    network: 'bsc',
  },

  {
    id: 'banana-zec-bnb',
    logo: 'bnb-pairs/ZEC-BNB.svg',
    name: 'ZEC-BNB LP',
    token: 'ZEC-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x2B2C771e44aF4C6f858598308e05FB89b23f11cE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeZEC-BNB',
    earnedTokenAddress: '0xCe3870506C57bd6A891aDc8d35D51557A5B55769',
    earnContractAddress: '0xCe3870506C57bd6A891aDc8d35D51557A5B55769',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-zec-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['ZEC', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0x1Ba42e5193dfA8B03D15dd1B86a3113bbBEF8Eeb/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x1Ba42e5193dfA8B03D15dd1B86a3113bbBEF8Eeb',
    network: 'bsc',
  },

  {
    id: 'belt-beltbtc',
    logo: 'single-assets/BTCB.svg',
    name: 'beltBTC',
    token: 'beltBTC',
    tokenDescription: 'Belt (Autofarm)',
    tokenAddress: '0x51bd63F240fB13870550423D208452cA87c44444',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeltBTC',
    earnedTokenAddress: '0xD411121C948Cff739857513E1ADF25ED448623f8',
    earnContractAddress: '0xD411121C948Cff739857513E1ADF25ED448623f8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'belt-beltbtc',
    oraclePrice: 0,
    status: 'active',
    platform: 'Belt',
    vamp: 'Autofarm',
    assets: ['BTCB'],
    risks: [
      'COMPLEXITY_HIGH',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Vamp',
    callFee: 0.5,
    depositFee: '<0.1%',
    withdrawalFee: '0.1%',
    addLiquidityUrl: 'https://belt.fi/',
    network: 'bsc',
  },
  {
    id: 'garuda-garuda-usdc',
    logo: 'degens/garuda-usdc.png',
    name: 'GARUDA-USDC LP',
    token: 'GARUDA-USDC LP',
    tokenDescription: 'PancakeSwap (Garuda)',
    tokenAddress: '0xeD90DEEc60a9b03844aDE0e4b086BAdA0aCC5B8D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGarudaGARUDA-USDC',
    earnedTokenAddress: '0xE783464c205Ddf7240D5A86C62AD31DdfEdFCfde',
    earnContractAddress: '0xE783464c205Ddf7240D5A86C62AD31DdfEdFCfde',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'garuda-garuda-usdc',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['GARUDA', 'USDC'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x854086dC841e1bfae50Cb615bF41f55BF432a90b/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x854086dC841e1bfae50Cb615bF41f55BF432a90b',
    network: 'bsc',
  },

  {
    id: 'jetswap-wings-bnb',
    logo: 'degens/WINGS-BNB.svg',
    name: 'WINGS-BNB LP',
    token: 'WINGS-BNB LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x1CD0fe829d83Fb49c8831Ae860d19c6062adA6e9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetswapWINGS-BNB',
    earnedTokenAddress: '0x2dEF6c368248d665082Db40a7Dc9F772CAD1DE75',
    earnContractAddress: '0x2dEF6c368248d665082Db40a7Dc9F772CAD1DE75',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-wings-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'JetSwap',
    assets: ['WINGS', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ADMIN_WITHOUT_TIMELOCK',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.jetswap.finance/#/add/BNB/0x0487b824c8261462f88940f97053e65bdb498446',
    buyTokenUrl:
      'https://exchange.jetswap.finance/#/swap?outputCurrency=0x0487b824c8261462f88940f97053e65bdb498446',
    network: 'bsc',
  },
  {
    id: 'jetswap-wings-busd',
    logo: 'degens/WINGS-BUSD.svg',
    name: 'WINGS-BUSD LP',
    token: 'WINGS-BUSD LP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0xFBa740304f3fc39d0e79703a5D7788E13f877DC0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetswapWINGS-BUSD',
    earnedTokenAddress: '0xE16f790B414C235Ada8cBC419C5C1Ae61ff49A84',
    earnContractAddress: '0xE16f790B414C235Ada8cBC419C5C1Ae61ff49A84',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-wings-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'JetSwap',
    assets: ['WINGS', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ADMIN_WITHOUT_TIMELOCK',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.jetswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x0487b824c8261462f88940f97053e65bdb498446',
    buyTokenUrl:
      'https://exchange.jetswap.finance/#/swap?outputCurrency=0x0487b824c8261462f88940f97053e65bdb498446',
    network: 'bsc',
  },
  {
    id: 'jetswap-busd-bnb',
    logo: 'degens/WINGS-BNB-BUSD.svg',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB JLP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0xc53eE6446101F2128f887b8c75083E74dca3e973',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetswapBUSD-BNB',
    earnedTokenAddress: '0xDb207D797cA68112f5AA2677ee01301cF91c3c6E',
    earnContractAddress: '0xDb207D797cA68112f5AA2677ee01301cF91c3c6E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-busd-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'JetSwap',
    assets: ['BUSD', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
      'ADMIN_WITHOUT_TIMELOCK',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.jetswap.finance/#/add/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'grand-grand',
    logo: 'degens/grand.png',
    name: 'GRAND',
    token: 'GRAND',
    tokenDescription: 'Grand',
    tokenAddress: '0xeE814F5B2bF700D2e843Dc56835D28d095161dd9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGrandGRAND',
    earnedTokenAddress: '0x547c889C676fe449018A3987866cFC674518b700',
    earnContractAddress: '0x547c889C676fe449018A3987866cFC674518b700',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'GRAND',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['GRAND'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'AUDIT',
      'PLATFORM_NEW',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xeE814F5B2bF700D2e843Dc56835D28d095161dd9',
    network: 'bsc',
  },
  {
    id: 'grand-grand-bnb',
    logo: 'degens/grand-bnb.png',
    name: 'GRAND-BNB LP',
    token: 'GRAND-BNB LP',
    tokenDescription: 'PancakeSwap (Grand)',
    tokenAddress: '0x7BA02AFCB8112648D27772f0d73c759C6963a666',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGrandGRAND-BNB',
    earnedTokenAddress: '0x40dA18484963Fb672c3D2FE4942ddF363265b410',
    earnContractAddress: '0x40dA18484963Fb672c3D2FE4942ddF363265b410',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'grand-grand-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['GRAND', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'AUDIT',
      'PLATFORM_NEW',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xeE814F5B2bF700D2e843Dc56835D28d095161dd9',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xeE814F5B2bF700D2e843Dc56835D28d095161dd9',
    network: 'bsc',
  },
  {
    id: 'belt-4belt',
    logo: 'uncategorized/BELT-VENUSBLP.png',
    name: 'BUSD/USDT/USDC/DAI',
    token: '4BELT LP',
    tokenDescription: 'Belt (Autofarm)',
    tokenAddress: '0x9cb73F20164e399958261c289Eb5F9846f4D1404',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBelt4Belt',
    earnedTokenAddress: '0xc1fcf50ccaCd1583BD9d3b41657056878C94e592',
    earnContractAddress: '0xc1fcf50ccaCd1583BD9d3b41657056878C94e592',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'belt-4belt',
    oraclePrice: 0,
    status: 'active',
    platform: 'Belt',
    assets: ['USDT', 'BUSD', 'USDC', 'DAI', '4BELT'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Vamp',
    vamp: 'Autofarm',
    callFee: 0.5,
    depositFee: '<0.1%',
    withdrawalFee: '0.1%',
    addLiquidityUrl: 'https://belt.fi/',
    network: 'bsc',
  },

  {
    id: 'dumpling-sdump-bnb',
    logo: 'degens/sdump-bnb.png',
    name: 'SDUMP-BNB LP',
    token: 'SDUMP-BNB LP',
    tokenDescription: 'PancakeSwap (Dumpling)',
    tokenAddress: '0x8B3C67ca3dA1B389A1252AF79F284d2FaAD07358',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDumplingSDUMP-BNB',
    earnedTokenAddress: '0x0396F46444D9256753E36408FC89D829d1f12A09',
    earnContractAddress: '0x0396F46444D9256753E36408FC89D829d1f12A09',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dumpling-sdump-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['SDUMP', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x13F6751ba11337BC67aBBdAd638a56194ee133B8',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x13F6751ba11337BC67aBBdAd638a56194ee133B8',
    network: 'bsc',
  },
  {
    id: 'dumpling-sdump-busd',
    logo: 'degens/sdump-bnb.png',
    name: 'SDUMP-BUSD LP',
    token: 'SDUMP-BUSD LP',
    tokenDescription: 'PancakeSwap (Dumpling)',
    tokenAddress: '0x8B19Bb58DbdA8B5303aBc96Bb418E9bF4100F34b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDumplingSDUMP-BUSD',
    earnedTokenAddress: '0xAF142b8678917C57703604fD4A4de2600f9e7551',
    earnContractAddress: '0xAF142b8678917C57703604fD4A4de2600f9e7551',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dumpling-sdump-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['SDUMP', 'BUSD'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x13F6751ba11337BC67aBBdAd638a56194ee133B8',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x13F6751ba11337BC67aBBdAd638a56194ee133B8',
    network: 'bsc',
  },

  {
    id: 'satis-xsat-busd',
    logo: 'degens/SAT-BUSD.png',
    name: 'xSAT-BUSD LP',
    token: 'xSAT-BUSD LP',
    tokenDescription: 'PancakeSwap (Satis)',
    tokenAddress: '0x3357387B7341c341Ffac18ada7cF2C242BC82b84',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSatisXSAT-BUSD',
    earnedTokenAddress: '0x538c84e85e71F775Df1A896927008ecE3e14f47e',
    earnContractAddress: '0x538c84e85e71F775Df1A896927008ecE3e14f47e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'satis-xsat-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Satis',
    assets: ['SAT', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'AUDIT',
      'PLATFORM_NEW',
      'LIQ_LOW',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
    network: 'bsc',
  },
  {
    id: 'satis-xsat-bnb',
    logo: 'degens/SAT-BNB.png',
    name: 'xSAT-BNB LP',
    token: 'xSAT-BNB LP',
    tokenDescription: 'PancakeSwap (Satis)',
    tokenAddress: '0x15a24f7850bCe4818617c1efA2D24132E65809dc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSatisXSAT-BNB',
    earnedTokenAddress: '0x0F2A749bD7C64BC7E4f9cAE7C54eAEFD72E27193',
    earnContractAddress: '0x0F2A749bD7C64BC7E4f9cAE7C54eAEFD72E27193',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'satis-xsat-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Satis',
    assets: ['SAT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'CONTRACTS_VERIFIED',
      'AUDIT',
      'PLATFORM_NEW',
      'LIQ_LOW',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
    network: 'bsc',
  },

  {
    id: 'iron-dnd',
    logo: 'degens/DND.png',
    name: 'DND',
    token: 'DND',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x34EA3F7162E6f6Ed16bD171267eC180fD5c848da',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronDND',
    earnedTokenAddress: '0xc2B68b16ABA7444D07258CACA55cD3fD3a3C5268',
    earnContractAddress: '0xc2B68b16ABA7444D07258CACA55cD3fD3a3C5268',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DND',
    oraclePrice: 0,
    status: 'eol',
    platform: 'IronFinance',
    assets: ['DND'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x34EA3F7162E6f6Ed16bD171267eC180fD5c848da',
    network: 'bsc',
  },
  {
    id: 'iron-dnd-bnb',
    logo: 'degens/DND.png',
    name: 'DND-BNB LP',
    token: 'DND-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xAcC75DaDB2Df216528F6Cb5E545e1284Ca258B1C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronDND-BNB',
    earnedTokenAddress: '0x0a4f69E053EE16fA127De5EA5d23EAd075e429D3',
    earnContractAddress: '0x0a4f69E053EE16fA127De5EA5d23EAd075e429D3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-dnd-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'IronFinance',
    assets: ['DND', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x34EA3F7162E6f6Ed16bD171267eC180fD5c848da',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x34EA3F7162E6f6Ed16bD171267eC180fD5c848da',
    network: 'bsc',
  },
  {
    id: 'cakev2-hotcross-bnb',
    logo: 'cake-pairs/hotcross-bnb.svg',
    name: 'HOTCROSS-BNB LP',
    token: 'HOTCROSS-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xF23BAD605E94dE0e3B60c9718a43A94A5aF43915',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2HOTCROSS-BNB',
    earnedTokenAddress: '0xfA4f79Cf4Eb504eD42fd77a931057815c1E41EC0',
    earnContractAddress: '0xfA4f79Cf4Eb504eD42fd77a931057815c1E41EC0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-hotcross-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['HOTCROSS', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
    network: 'bsc',
  },
  {
    id: 'cakev2-rfox-bnb',
    logo: 'cake-pairs/rfox-bnb.svg',
    name: 'RFOX-BNB LP',
    token: 'RFOX-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x8E04b3972b5C25766c681dFD30a8A1cBf6dcc8c1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2RFOX-BNB',
    earnedTokenAddress: '0x070Dcc132461571676eF5e3F64b0D730136C0898',
    earnContractAddress: '0x070Dcc132461571676eF5e3F64b0D730136C0898',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-rfox-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['RFOX', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x0a3a21356793b49154fd3bbe91cbc2a16c0457f5',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0a3a21356793b49154fd3bbe91cbc2a16c0457f5',
    network: 'bsc',
  },
  {
    id: 'cakev2-wmass-busd',
    logo: 'busd-pairs/WMASS-BUSD.svg',
    name: 'WMASS-BUSD LP',
    token: 'WMASS-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xeC95ff6281c3aD8E27372fA6675EB337640b8E5E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2WMASS-BUSD',
    earnedTokenAddress: '0x84D4B046c060730b0A2fd65703F20c4A61EaAff7',
    earnContractAddress: '0x84D4B046c060730b0A2fd65703F20c4A61EaAff7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-wmass-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['WMASS', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x7e396bfc8a2f84748701167c2d622f041a1d7a17',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x7e396bfc8a2f84748701167c2d622f041a1d7a17',
    network: 'bsc',
  },
  {
    id: 'cakev2-ubxt-busd',
    logo: 'busd-pairs/UBXT-BUSD.svg',
    name: 'UBXT-BUSD LP',
    token: 'UBXT-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x8D3FF27D2aD6a9556B7C4F82F4d602D20114bC90',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2UBXT-BUSD',
    earnedTokenAddress: '0x173BCBDd7E5f8AadC1e9dEC2f030c9567230b253',
    earnContractAddress: '0x173BCBDd7E5f8AadC1e9dEC2f030c9567230b253',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ubxt-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['UBXT', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xbbeb90cfb6fafa1f69aa130b7341089abeef5811',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xbbeb90cfb6fafa1f69aa130b7341089abeef5811',
    network: 'bsc',
  },
  {
    id: 'cakev2-btr-busd',
    logo: 'busd-pairs/BTR-BUSD.svg',
    name: 'BTR-BUSD LP',
    token: 'BTR-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xBC7AC609Fa730239190A70952e64eE1DFC2530AC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BTR-BUSD',
    earnedTokenAddress: '0x6051785dBEA79BDF2e0B278a5cb4741Fe1a2Fd12',
    earnContractAddress: '0x6051785dBEA79BDF2e0B278a5cb4741Fe1a2Fd12',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-btr-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BTR', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x5a16e8ce8ca316407c6e6307095dc9540a8d62b3',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x5a16e8ce8ca316407c6e6307095dc9540a8d62b3',
    network: 'bsc',
  },
  {
    id: 'cakev2-pmon-busd',
    logo: 'busd-pairs/PMON-BUSD.svg',
    name: 'PMON-BUSD LP',
    token: 'PMON-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xcdb0016d97FD0E7EC2C3B78aA4786Cbd8e19C14C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2PMON-BUSD',
    earnedTokenAddress: '0x82eaf01Bc5947d23Ca140221F2cd3f27250DF5Ac',
    earnContractAddress: '0x82eaf01Bc5947d23Ca140221F2cd3f27250DF5Ac',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-pmon-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['PMON', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x1796ae0b0fa4862485106a0de9b654efe301d0b2',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x1796ae0b0fa4862485106a0de9b654efe301d0b2',
    network: 'bsc',
  },
  {
    id: 'cakev2-one-busd',
    logo: 'busd-pairs/ONE-BUSD.svg',
    name: 'ONE-BUSD LP',
    token: 'ONE-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x4d057F769D930eaFD597B49d6Fb2e1009A73a702',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ONE-BUSD',
    earnedTokenAddress: '0x94A901E76140e27dD72Ba9a22988419e95324826',
    earnContractAddress: '0x94A901E76140e27dD72Ba9a22988419e95324826',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-one-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ONE', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x04baf95fd4c52fd09a56d840baee0ab8d7357bf0',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x04baf95fd4c52fd09a56d840baee0ab8d7357bf0',
    network: 'bsc',
  },
  {
    id: 'cakev2-oin-busd',
    logo: 'busd-pairs/OIN-BUSD.svg',
    name: 'OIN-BUSD LP',
    token: 'OIN-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x6a00E41561ac36A78dbA1D09091b0F00C4E53724',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2OIN-BUSD',
    earnedTokenAddress: '0x50bFd46216afcCA24890Ebd6eC67a0Cb92ce9aED',
    earnContractAddress: '0x50bFd46216afcCA24890Ebd6eC67a0Cb92ce9aED',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-oin-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['OIN', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x658e64ffcf40d240a43d52ca9342140316ae44fa',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x658e64ffcf40d240a43d52ca9342140316ae44fa',
    network: 'bsc',
  },

  {
    id: 'cakev2-tdoge-bnb',
    logo: 'bnb-pairs/TDOGE-BNB.svg',
    name: 'tDOGE-BNB LP',
    token: 'tDOGE-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x2030845Ce7d4224523fd2F03Ca20Afe4aAD1D890',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2TDOGE-BNB',
    earnedTokenAddress: '0x6AF821Aaf981D54aD37d58bFbA7fD2E2c41b623d',
    earnContractAddress: '0x6AF821Aaf981D54aD37d58bFbA7fD2E2c41b623d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-tdoge-bnb',
    oraclePrice: 0,
    status: 'deposits-paused',
    platform: 'PancakeSwap',
    assets: ['tDOGE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xe550a593d09fbc8dcd557b5c88cea6946a8b404a',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xe550a593d09fbc8dcd557b5c88cea6946a8b404a',
    network: 'bsc',
  },
  {
    id: 'cakev2-fine-bnb',
    logo: 'bnb-pairs/FINE-BNB.svg',
    name: 'FINE-BNB LP',
    token: 'FINE-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xC309a6d2F1537922E06f15aA2eb21CaA1b2eEDb6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2FINE-BNB',
    earnedTokenAddress: '0xDCbf0c895d641FB67FB993456b6E99E478c7B087',
    earnContractAddress: '0xDCbf0c895d641FB67FB993456b6E99E478c7B087',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-fine-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['FINE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x4e6415a5727ea08aae4580057187923aec331227',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4e6415a5727ea08aae4580057187923aec331227',
    network: 'bsc',
  },
  {
    id: 'cakev2-doge-bnb',
    logo: 'bnb-pairs/DOGE-BNB-PCS.svg',
    name: 'DOGE-BNB LP',
    token: 'DOGE-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xac109C8025F272414fd9e2faA805a583708A017f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2DOGE-BNB',
    earnedTokenAddress: '0x3b3bc8AE6dcAcCeaaC3C19E196ebD3341Cfe9c4e',
    earnContractAddress: '0x3b3bc8AE6dcAcCeaaC3C19E196ebD3341Cfe9c4e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-doge-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['DOGE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xba2ae424d960c26247dd6c32edc70b295c744c43',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xba2ae424d960c26247dd6c32edc70b295c744c43',
    network: 'bsc',
  },
  {
    id: 'cakev2-hyfi-bnb',
    logo: 'bnb-pairs/HYFI-BNB.svg',
    name: 'HYFI-BNB LP',
    token: 'HYFI-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x0716725d78081A9E0E1Ff81516f5415b399e274D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2HYFI-BNB',
    earnedTokenAddress: '0xfe9bB1AFF4D1A2928F0aFEcaBF7d96479ece7eF4',
    earnContractAddress: '0xfe9bB1AFF4D1A2928F0aFEcaBF7d96479ece7eF4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-hyfi-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['HYFI', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x9a319b959e33369C5eaA494a770117eE3e585318',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x9a319b959e33369C5eaA494a770117eE3e585318',
    network: 'bsc',
  },

  {
    id: 'banana-avax-bnb',
    logo: 'bnb-pairs/AVAX-BNB.svg',
    name: 'AVAX-BNB LP',
    token: 'AVAX-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x40aFc7CBd0Dc2bE5860F0035b717d20Afb4827b2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeAVAX-BNB',
    earnedTokenAddress: '0x97697d89e490196eE7dbF6660424B80D276ae7d9',
    earnContractAddress: '0x97697d89e490196eE7dbF6660424B80D276ae7d9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-avax-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['AVAX', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
    network: 'bsc',
  },
  {
    id: 'garuda-garuda-usdt',
    logo: 'degens/garuda-usdt.png',
    name: 'GARUDA-USDT LP',
    token: 'GARUDA-USDT LP',
    tokenDescription: 'PancakeSwap (Garuda)',
    tokenAddress: '0x7F6369476756B06D2b70BD77917fbc35A7801EFD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGarudaGARUDA-USDT',
    earnedTokenAddress: '0x1e8Cf92A803781b4ecB9102031fc5506A476E2A4',
    earnContractAddress: '0x1e8Cf92A803781b4ecB9102031fc5506A476E2A4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'garuda-garuda-usdt',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['GARUDA', 'USDT'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x854086dC841e1bfae50Cb615bF41f55BF432a90b/0x55d398326f99059fF775485246999027B3197955',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x854086dC841e1bfae50Cb615bF41f55BF432a90b',
    network: 'bsc',
  },
  {
    id: 'banana-ftm-bnb',
    logo: 'bnb-pairs/FTM-BNB.svg',
    name: 'FTM-BNB LP',
    token: 'FTM-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x47A0B7bA18Bb80E4888ca2576c2d34BE290772a6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeFTM-BNB',
    earnedTokenAddress: '0xb61fa9e4548f0D3E1405cfbA7a12de8fF11C0E4B',
    earnContractAddress: '0xb61fa9e4548f0D3E1405cfbA7a12de8fF11C0E4B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-ftm-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['FTM', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0xad29abb318791d579433d831ed122afeaf29dcfe',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0xad29abb318791d579433d831ed122afeaf29dcfe',
    network: 'bsc',
  },
  {
    id: 'banana-comp-bnb',
    logo: 'bnb-pairs/COMP-BNB.svg',
    name: 'COMP-BNB LP',
    token: 'COMP-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xb4c0c621B2eDfE6C22585ebAC56b0e634907B8A7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeCOMP-BNB',
    earnedTokenAddress: '0x117298872069E8e3BcB5E02727852f9Ca94930Cb',
    earnContractAddress: '0x117298872069E8e3BcB5E02727852f9Ca94930Cb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-comp-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['COMP', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x52ce071bd9b1c4b00a0b92d298c512478cad67e8',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x52ce071bd9b1c4b00a0b92d298c512478cad67e8',
    network: 'bsc',
  },
  {
    id: 'banana-aave-bnb',
    logo: 'bnb-pairs/AAVE-BNB.svg',
    name: 'AAVE-BNB LP',
    token: 'AAVE-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xf13e007e181A8F57eD3a4D4CcE0A9ff9E7241CEf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeAAVE-BNB',
    earnedTokenAddress: '0x2EF1B47174d9f9bC6e9a37E863928C0E6100324d',
    earnContractAddress: '0x2EF1B47174d9f9bC6e9a37E863928C0E6100324d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-aave-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['AAVE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0xfb6115445bff7b52feb98650c87f44907e58f802',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0xfb6115445bff7b52feb98650c87f44907e58f802',
    network: 'bsc',
  },
  {
    id: 'banana-link-bnb',
    logo: 'degens/LINK-BNB.svg',
    name: 'LINK-BNB LP',
    token: 'LINK-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x092ADA3818DB7FBb8e0A2124Ff218C5125C1CcE6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeLINK-BNB',
    earnedTokenAddress: '0xfb04ba534ED800d9bFFce22B5F86eDaA4fc76D50',
    earnContractAddress: '0xfb04ba534ED800d9bFFce22B5F86eDaA4fc76D50',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-link-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['LINK', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    network: 'bsc',
  },
  {
    id: 'banana-ada-bnb',
    logo: 'degens/ADA-BNB.svg',
    name: 'ADA-BNB LP',
    token: 'ADA-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x40d4543887E4170A1A40Cd8dB15A6b297c812Cb1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeADA-BNB',
    earnedTokenAddress: '0xb8E6a5EDbC8ae71f74Fd02f65E95Ba0a8733c5a1',
    earnContractAddress: '0xb8E6a5EDbC8ae71f74Fd02f65E95Ba0a8733c5a1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-ada-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['ADA', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    network: 'bsc',
  },
  {
    id: 'iron-iron-busd',
    logo: 'degens/IRON.png',
    name: 'IRON-BUSD LP',
    token: 'IRON-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x26e49b5aE7e9b6c8C7C9b8506DA6b3b321c7431c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronIRON-BUSD',
    earnedTokenAddress: '0x4b1dF138339627416a8b991a75769919F65cCD8d',
    earnContractAddress: '0x4b1dF138339627416a8b991a75769919F65cCD8d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-iron-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'IronFinance',
    assets: ['BUSD', 'IRON'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x7b65B489fE53fCE1F6548Db886C08aD73111DDd8/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x7b65B489fE53fCE1F6548Db886C08aD73111DDd8',
    network: 'bsc',
  },
  {
    id: 'iron-steel-bnb',
    logo: 'degens/STEEL.png',
    name: 'STEEL-BNB LP',
    token: 'STEEL-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xcB7aA7F125DD9c237f6Df8f6D149DbBE779692E5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronSTEEL-BNB',
    earnedTokenAddress: '0xCB01313B8Cf1d2742D0bc7209B2E5d1D0d172b36',
    earnContractAddress: '0xCB01313B8Cf1d2742D0bc7209B2E5d1D0d172b36',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-steel-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'IronFinance',
    assets: ['STEEL', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x9001eE054F1692feF3A48330cB543b6FEc6287eb/BNB',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x9001eE054F1692feF3A48330cB543b6FEc6287eb',
    network: 'bsc',
  },
  {
    id: 'iron-steel-iron',
    logo: 'degens/IRON.png',
    name: 'STEEL-IRON LP',
    token: 'STEEL-IRON LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x453a688c33696c42a73230B0b3AC1e4676D32aA6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIronSTEEL-IRON',
    earnedTokenAddress: '0x1384Ed18E881C0CC9027DC04ab88bFBF641c6106',
    earnContractAddress: '0x1384Ed18E881C0CC9027DC04ab88bFBF641c6106',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'iron-steel-iron',
    oraclePrice: 0,
    status: 'eol',
    platform: 'IronFinance',
    assets: ['STEEL', 'IRON'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x7b65B489fE53fCE1F6548Db886C08aD73111DDd8/0x9001eE054F1692feF3A48330cB543b6FEc6287eb',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x9001eE054F1692feF3A48330cB543b6FEc6287eb',
    network: 'bsc',
  },
  {
    id: 'bhc-bhc',
    logo: 'single-assets/BHC.png',
    name: 'BHC',
    token: 'BHC',
    tokenDescription: 'BillionHappiness',
    tokenAddress: '0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBHC',
    earnedTokenAddress: '0x56E899E56F6B8635B4A01b724db23674a64A7231',
    earnContractAddress: '0x56E899E56F6B8635B4A01b724db23674a64A7231',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BHC',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['BHC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
    network: 'bsc',
  },
  {
    id: 'belt-belteth',
    logo: 'single-assets/ETH.png',
    name: 'beltETH',
    token: 'beltETH',
    tokenDescription: 'Belt (Autofarm)',
    tokenAddress: '0xAA20E8Cb61299df2357561C2AC2e1172bC68bc25',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeltETH',
    earnedTokenAddress: '0xf2064C230b285AA6Cf45c6267DA86a8E3505D0AA',
    earnContractAddress: '0xf2064C230b285AA6Cf45c6267DA86a8E3505D0AA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'belt-belteth',
    oraclePrice: 0,
    status: 'active',
    platform: 'Belt',
    assets: ['ETH'],
    risks: [
      'COMPLEXITY_HIGH',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Vamp',
    vamp: 'Autofarm',
    callFee: 0.5,
    depositFee: '<0.1%',
    withdrawalFee: '0.1%',
    addLiquidityUrl: 'https://belt.fi/',
    network: 'bsc',
  },
  {
    id: 'banana-matic-bnb',
    logo: 'bnb-pairs/MATIC-BNB.svg',
    name: 'MATIC-BNB LP',
    token: 'MATIC-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x29A4A3D77c010CE100A45831BF7e798f0f0B325D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeMATIC-BNB',
    earnedTokenAddress: '0xB9dA6EdEeF7e3db24265EcFBeBF4e06B7c7A455f',
    earnContractAddress: '0xB9dA6EdEeF7e3db24265EcFBeBF4e06B7c7A455f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-matic-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['MATIC', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
    network: 'bsc',
  },
  {
    id: 'bunny-bunny',
    logo: 'single-assets/BUNNY.png',
    name: 'BUNNY',
    token: 'BUNNY',
    tokenDescription: 'Pancakebunny',
    tokenAddress: '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBunnyV2',
    earnedTokenAddress: '0x2288dC6e2D2FaBd94a0ab63DD558AE149Bbc9eD9',
    earnContractAddress: '0x2288dC6e2D2FaBd94a0ab63DD558AE149Bbc9eD9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BUNNY',
    oraclePrice: 0,
    status: 'active',
    platform: 'Pancakebunny',
    assets: ['BUNNY'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl: 'https://app.1inch.io/#/56/swap/BNB/BUNNY',
    network: 'bsc',
  },

  {
    id: 'mdex-bsc-bifi-usdt',
    logo: 'usdt-pairs/BIFI-USDT.png',
    name: 'BIFI-USDT LP',
    token: 'BIFI-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0xDA4e9Ad1Db5546Ac5cF9BCDb6a0285b97b39f747',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexBIFI-USDT',
    earnedTokenAddress: '0x78dB17F440A223464B15046A25A273De510A14Ce',
    earnContractAddress: '0x78dB17F440A223464B15046A25A273De510A14Ce',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-bsc-bifi-usdt',
    oraclePrice: 0,
    status: 'active',
    platform: 'Mdex',
    assets: ['BIFI', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.25,
    buyTokenUrl:
      'https://bsc.mdex.com/#/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    addLiquidityUrl:
      'https://bsc.mdex.com/#/add/0xCa3F508B8e4Dd382eE878A314789373D80A5190A/0x55d398326f99059fF775485246999027B3197955',
    network: 'bsc',
  },

  {
    id: 'tofy-tofy',
    logo: 'degens/tofy.png',
    name: 'TOFY',
    token: 'TOFY',
    tokenDescription: 'Marshmallow',
    tokenAddress: '0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTofyTOFY',
    earnedTokenAddress: '0xE9F13aBE42A29BA302E7d57171f57430A2a2f8d0',
    earnContractAddress: '0xE9F13aBE42A29BA302E7d57171f57430A2a2f8d0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'TOFY',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['TOFY'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.marshmallowdefi.com/#/swap?outputCurrency=0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
    network: 'bsc',
  },
  {
    id: 'tofy-tofy-mash',
    logo: 'degens/tofy-mash.png',
    name: 'TOFY-MASH LP',
    token: 'TOFY-MASH LP',
    tokenDescription: 'Marshmallow',
    tokenAddress: '0xc36e93F6D92be42E2eAFB009dF74a0eBFeD5c0C2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTofyTOFY-MASH',
    earnedTokenAddress: '0xceCa548809FBb923974609EF3a2013134815741A',
    earnContractAddress: '0xceCa548809FBb923974609EF3a2013134815741A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tofy-tofy-mash',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['TOFY', 'MASH'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.marshmallowdefi.com/#/add/ETH/0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
    buyTokenUrl:
      'https://exchange.marshmallowdefi.com/#/swap?outputCurrency=0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
    network: 'bsc',
  },
  {
    id: 'tofy-tofy-bnb',
    logo: 'degens/tofy-bnb.png',
    name: 'TOFY-BNB LP',
    token: 'TOFY-BNB LP',
    tokenDescription: 'Marshmallow',
    tokenAddress: '0x753f40F5CdeB085AD4540Dd02a1c0c712EaF51F6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTofyTOFY-BNB',
    earnedTokenAddress: '0x67C079A0bd936270981A8399f5d38c39f00941F4',
    earnContractAddress: '0x67C079A0bd936270981A8399f5d38c39f00941F4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tofy-tofy-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['TOFY', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.marshmallowdefi.com/#/add/ETH/0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
    buyTokenUrl:
      'https://exchange.marshmallowdefi.com/#/swap?outputCurrency=0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
    network: 'bsc',
  },
  {
    id: 'goal-goal-busd',
    logo: 'degens/GOAL-BUSD.png',
    name: 'GOAL-BUSD LP',
    token: 'GOAL-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xE4305690e9B765bb7B85a48BBe6b16b82e12A3cC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooGoalGOAL-BUSD',
    earnedTokenAddress: '0xF18784Ba03fA5017E1ae4D82c4a3e00F5E8fF16f',
    earnContractAddress: '0xF18784Ba03fA5017E1ae4D82c4a3e00F5E8fF16f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'goal-goal-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['GOAL', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_NEW',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xE5b57E6e1b945B91FEE368aC108d2ebCcA78Aa8F/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xE5b57E6e1b945B91FEE368aC108d2ebCcA78Aa8F',
    network: 'bsc',
  },
  {
    id: 'cakev2-kun-busd',
    logo: 'busd-pairs/KUN-BUSD.svg',
    name: 'KUN-BUSD LP',
    token: 'KUN-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xeA61020E5a128D2BEC67d48F7CFbe3408dB7E391',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2KUN-BUSD',
    earnedTokenAddress: '0x52823D1A0F3745D9481557eDF3ACff04857D28B6',
    earnContractAddress: '0x52823D1A0F3745D9481557eDF3ACff04857D28B6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-kun-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['KUN', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x1a2fb0af670d0234c2857fad35b789f8cb725584/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x1a2fb0af670d0234c2857fad35b789f8cb725584',
    network: 'bsc',
  },
  {
    id: 'cakev2-math-busd',
    logo: 'busd-pairs/MATH-BUSD.svg',
    name: 'MATH-BUSD LP',
    token: 'MATH-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xB7CADa0F120Ca46745A024E6b9FE907B2Fe10CF3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2MATH-BUSD',
    earnedTokenAddress: '0xE8A2455e485440EFB359267ce23402912324A3bE',
    earnContractAddress: '0xE8A2455e485440EFB359267ce23402912324A3bE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-math-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['MATH', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xf218184af829cf2b0019f8e6f0b2423498a36983/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xf218184af829cf2b0019f8e6f0b2423498a36983',
    network: 'bsc',
  },

  {
    id: 'cakev2-mcoin-ust',
    logo: 'ust-pairs/MCOIN-UST.svg',
    name: 'mCOIN-UST LP',
    token: 'mCOIN-UST LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xBCf01a42f6BC42F3Cfe81B05519565044d65D22a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2MCOIN-UST',
    earnedTokenAddress: '0xF803F277D5aAe38ac5CBfe8BA7B2e265983cc2a6',
    earnContractAddress: '0xF803F277D5aAe38ac5CBfe8BA7B2e265983cc2a6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-mcoin-ust',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['mCOIN', 'UST'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f/0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f',
    network: 'bsc',
  },
  {
    id: 'banana-watch-bnb',
    logo: 'bnb-pairs/WATCH-BNB-APE.svg',
    name: 'WATCH-BNB LP',
    token: 'WATCH-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xa00A91fBB39051e2a6368424A93895c3f1F2290b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeWATCH-BNB',
    earnedTokenAddress: '0xc795E63738d6207dFE4450D280013aCFdb1Cc526',
    earnContractAddress: '0xc795E63738d6207dFE4450D280013aCFdb1Cc526',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-watch-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['WATCH', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
    network: 'bsc',
  },
  {
    id: 'jetfuel-fts-bnb-eol',
    logo: 'bnb-pairs/FTS-BNB.svg',
    name: 'FTS-BNB LP',
    token: 'FTS-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xc69f2139a6Ce6912703AC10e5e74ee26Af1b4a7e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetfuelFTS-BNB',
    earnedTokenAddress: '0xcE7cC761217CEaBE2eC7dD56a13Ff0056c36Aa79',
    earnContractAddress: '0xcE7cC761217CEaBE2eC7dD56a13Ff0056c36Aa79',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-fts-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['FTS', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x4437743ac02957068995c48e08465e0ee1769fbe',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4437743ac02957068995c48e08465e0ee1769fbe',
    network: 'bsc',
  },
  {
    id: 'jetfuel-fuel-bnb-eol',
    logo: 'bnb-pairs/FUEL-BNB.png',
    name: 'FUEL-BNB LP',
    token: 'FUEL-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x4613442C3AB1542b54C721D661F5044843099E39',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetfuelFUEL-BNB',
    earnedTokenAddress: '0xE7105Aa79eD8DFAeBAa0bB0434e8A0f36534669d',
    earnContractAddress: '0xE7105Aa79eD8DFAeBAa0bB0434e8A0f36534669d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-fuel-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['FUEL', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A',
    network: 'bsc',
  },

  {
    id: 'banana-bxbtc-bnb',
    logo: 'bnb-pairs/BXBTC-BNB.svg',
    name: 'BXBTC-BNB LP',
    token: 'BXBTC-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xc2feF4BEC915315beF9f6E8a06b2516E64D29D06',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBXBTC-BNB',
    earnedTokenAddress: '0x8D112FcdF377A2C4cb41b60AAe32199F939a866C',
    earnContractAddress: '0x8D112FcdF377A2C4cb41b60AAe32199F939a866C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-bxbtc-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BXBTC', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0xab111D5948470Ba73d98D66BBdf2798FBE093546',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0xab111D5948470Ba73d98D66BBdf2798FBE093546',
    network: 'bsc',
  },

  {
    id: 'cakev2-btt-bnb',
    logo: 'bnb-pairs/BTT-BNB.svg',
    name: 'BTT-BNB LP',
    token: 'BTT-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x946696344e7d4346b223e1Cf77035a76690d6A73',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BTT-BNB',
    earnedTokenAddress: '0x561860359244877A6dE987165a5A89Ef288C178D',
    earnContractAddress: '0x561860359244877A6dE987165a5A89Ef288C178D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-btt-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BTT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x8595f9da7b868b1822194faed312235e43007b49',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8595f9da7b868b1822194faed312235e43007b49',
    network: 'bsc',
  },
  {
    id: 'cakev2-trx-bnb',
    logo: 'bnb-pairs/TRX-BNB.svg',
    name: 'TRX-BNB LP',
    token: 'TRX-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x3cd338c3BB249B6b3C55799F85a589FEbBBFf9Dd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2TRX-BNB',
    earnedTokenAddress: '0xa60D572AAfC217a76F73475644716cA63a4E7528',
    earnContractAddress: '0xa60D572AAfC217a76F73475644716cA63a4E7528',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-trx-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['TRX', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
    network: 'bsc',
  },
  {
    id: 'cakev2-win-bnb',
    logo: 'bnb-pairs/WIN-BNB.svg',
    name: 'WIN-BNB LP',
    token: 'WIN-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x894bd57afD8EFc93D9171cb585d11d0977557425',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2WIN-BNB',
    earnedTokenAddress: '0x76Fcc8e2566ff7b60D11A9d5D76de12eC16cbf15',
    earnContractAddress: '0x76Fcc8e2566ff7b60D11A9d5D76de12eC16cbf15',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-win-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['WIN', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xaef0d72a118ce24fee3cd1d43d383897d05b4e99',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xaef0d72a118ce24fee3cd1d43d383897d05b4e99',
    network: 'bsc',
  },

  {
    id: 'banana-naut-bnb',
    logo: 'bnb-pairs/NAUT-BNB.svg',
    name: 'NAUT-BNB LP',
    token: 'NAUT-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x288EA5437c7aaD045a393cee2F41E548df24d1C8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeNAUT-BNB',
    earnedTokenAddress: '0x9f670c6CdB8A6133016eC7C8552Dd42624FaC53F',
    earnContractAddress: '0x9f670c6CdB8A6133016eC7C8552Dd42624FaC53F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-naut-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['NAUT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x05B339B0A346bF01f851ddE47a5d485c34FE220c',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x05b339b0a346bf01f851dde47a5d485c34fe220c',
    network: 'bsc',
  },
  {
    id: 'cakev2-pnt-pbtc',
    logo: 'uncategorized/PNT-PBTC.svg',
    name: 'PNT-pBTC LP',
    token: 'PNT-pBTC LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xDAA89d335926628367b47852989bb22EE62CA5DE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2PNT-PBTC',
    earnedTokenAddress: '0xd6d2e8c9f9a276811A8994Bf7610807f4116115f',
    earnContractAddress: '0xd6d2e8c9f9a276811A8994Bf7610807f4116115f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-pnt-pbtc',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['PNT', 'pBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C/0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
    network: 'bsc',
  },
  {
    id: 'ica-ica-btcb-eol',
    logo: 'single-assets/ICA.svg',
    name: 'ICA-BTCB LP',
    token: 'ICA-BTCB LP',
    tokenDescription: 'PancakeSwap (Icarus)',
    tokenAddress: '0xA2e5A7Bf668ECb74186e0E4BFBA8a0C58BCc760d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIcarusICA-BTCB',
    earnedTokenAddress: '0xbf29b8AdaE718A3A372ba90040A9acBD8A5794f4',
    earnContractAddress: '0xbf29b8AdaE718A3A372ba90040A9acBD8A5794f4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ica-ica-btcb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['ICA', 'BTCB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x95111f630aC215Eb74599ED42C67E2c2790d69e2',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x95111f630ac215eb74599ed42c67e2c2790d69e2',
    network: 'bsc',
  },
  {
    id: 'ica-ica-eth-eol',
    logo: 'single-assets/ICA.svg',
    name: 'ICA-ETH LP',
    token: 'ICA-ETH LP',
    tokenDescription: 'PancakeSwap (Icarus)',
    tokenAddress: '0x2cb2C54795b6714dA829FfA580B9692A261F8Bdd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooIcarusICA-ETH',
    earnedTokenAddress: '0x389e5F8e23e88cA2D971a69b2AAADE264A9DE5Fb',
    earnContractAddress: '0x389e5F8e23e88cA2D971a69b2AAADE264A9DE5Fb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ica-ica-eth',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['ICA', 'ETH'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x95111f630aC215Eb74599ED42C67E2c2790d69e2',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x95111f630ac215eb74599ed42c67e2c2790d69e2',
    network: 'bsc',
  },
  {
    id: 'zefiv2-zefi-bnb',
    logo: 'degens/ZEFI-BNB.svg',
    name: 'ZEFI-BNB LP',
    token: 'ZEFI-BNB LP2',
    tokenDescription: 'PancakeSwap (ZCore)',
    tokenAddress: '0x7C020B60fBDeB668834BE0367CfBA395CaAE5dFc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooZefiV2ZEFI-BNB',
    earnedTokenAddress: '0xB62eE36A03ab043Eb5b57E1eE88dEFbe9959d4C3',
    earnContractAddress: '0xB62eE36A03ab043Eb5b57E1eE88dEFbe9959d4C3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zefiv2-zefi-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ZCore',
    assets: ['ZEFI', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    network: 'bsc',
  },
  {
    id: 'zefiv2-zefi-busd',
    logo: 'degens/ZEFI-BUSD.svg',
    name: 'ZEFI-BUSD LP',
    token: 'ZEFI-BUSD LP2',
    tokenDescription: 'PancakeSwap (ZCore)',
    tokenAddress: '0x09B0123e36A2b0A5b95474455E437e8861a6C61E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooZefiV2ZEFI-BUSD',
    earnedTokenAddress: '0x6b57697eb320F40d5F386547DB3d468111fF0B92',
    earnContractAddress: '0x6b57697eb320F40d5F386547DB3d468111fF0B92',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zefiv2-zefi-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'ZCore',
    assets: ['ZEFI', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    network: 'bsc',
  },

  {
    id: 'satis-sat-bnb-eol',
    logo: 'degens/SAT-BNB.png',
    name: 'SAT-BNB LP',
    token: 'SAT-BNB LP',
    tokenDescription: 'PancakeSwap (Satis)',
    tokenAddress: '0x0AB22917fA941edCa92A5b7E030C59fbC7D9019a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSatisSAT-BNB',
    earnedTokenAddress: '0x81a00F9789041E8CE270fCdd98dB65D8504C990B',
    earnContractAddress: '0x81a00F9789041E8CE270fCdd98dB65D8504C990B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'satis-sat-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['SAT', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/remove/BNB/0xA1928c0D8F83C0bFB7ebE51B412b1FD29A277893',
    network: 'bsc',
  },
  {
    id: 'satis-sat-busd-eol',
    logo: 'degens/SAT-BUSD.png',
    name: 'SAT-BUSD LP',
    token: 'SAT-BUSD LP',
    tokenDescription: 'PancakeSwap (Satis)',
    tokenAddress: '0x020DB9ff8648AcFc9C590eafb8f6bcd8f254D248',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSatisSAT-BUSD',
    earnedTokenAddress: '0xF2ce03745790fCE2F707157B008f981caA7c00b2',
    earnContractAddress: '0xF2ce03745790fCE2F707157B008f981caA7c00b2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'satis-sat-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['SAT', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/remove/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xA1928c0D8F83C0bFB7ebE51B412b1FD29A277893',
    network: 'bsc',
  },

  {
    id: 'banana-busd-usdc',
    logo: 'busd-pairs/BUSD-USDC.svg',
    name: 'BUSD-USDC LP',
    token: 'BUSD-USDC LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xC087C78AbaC4A0E900a327444193dBF9BA69058E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBUSD-USDC',
    earnedTokenAddress: '0xc6631af32c5Bd444626274C9FD1dc8A759534222',
    earnContractAddress: '0xc6631af32c5Bd444626274C9FD1dc8A759534222',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-busd-usdc',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BUSD', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d/0xe9e7cea3dedca5984780bafc599bd69add087d56',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
    network: 'bsc',
  },

  {
    id: 'banana-doge-bnb',
    logo: 'bnb-pairs/DOGE-BNB.svg',
    name: 'DOGE-BNB LP',
    token: 'DOGE-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xfd1ef328A17A8e8Eeaf7e4Ea1ed8a108E1F2d096',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeDOGE-BNB',
    earnedTokenAddress: '0x6c978fe7E78c52a4c40107f081bA92b5d3daEfBF',
    earnContractAddress: '0x6c978fe7E78c52a4c40107f081bA92b5d3daEfBF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-doge-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['DOGE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    network: 'bsc',
  },
  {
    id: 'cakev2-btcb-busd',
    logo: 'busd-pairs/BTCB-BUSD.svg',
    name: 'BTCB-BUSD LP',
    token: 'BTCB-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BTCB-BUSD',
    earnedTokenAddress: '0x40D21bCd71B0Be8864ff3Dd62373CFFA4E151d6F',
    earnContractAddress: '0x40D21bCd71B0Be8864ff3Dd62373CFFA4E151d6F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-btcb-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BTCB', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },
  {
    id: 'banana-ont-bnb',
    logo: 'bnb-pairs/ONT-BNB.svg',
    name: 'ONT-BNB LP',
    token: 'ONT-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x924D3f2F94618e8Ee837d4C2b8d703F0de12a57e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeONT-BNB',
    earnedTokenAddress: '0xf8ED3D2E36eA4eA13eA4cCcaD5c72ac55B64e651',
    earnContractAddress: '0xf8ED3D2E36eA4eA13eA4cCcaD5c72ac55B64e651',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-ont-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['ONT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c/0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&outputCurrency=0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
    network: 'bsc',
  },

  {
    id: 'banana-keyfi-bnb',
    logo: 'bnb-pairs/KEYFI-BNB.svg',
    name: 'KEYFI-BNB LP',
    token: 'KEYFI-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x7A8ACAEAfC4Fa051De4EAbff8D1abdD0388aE08a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeKEYFI-BNB',
    earnedTokenAddress: '0x4A62e870EdF51EdAe9Cc9a4BF5D17919c7759AF3',
    earnContractAddress: '0x4A62e870EdF51EdAe9Cc9a4BF5D17919c7759AF3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-keyfi-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['KEYFI', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    network: 'bsc',
  },
  {
    id: 'typhv2-typh-bnb',
    logo: 'bnb-pairs/TYPH-BNB.png',
    name: 'TYPH-BNB LP',
    token: 'TYPH-BNB LP2',
    tokenDescription: 'PancakeSwap (Typhoon)',
    tokenAddress: '0x687efea126216159b628Fd3F5B7dE996C6cd42D6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTyphV2TYPH-BNB',
    earnedTokenAddress: '0xC598B22552a981d565eB85e5E953928Ee0a26E42',
    earnContractAddress: '0xC598B22552a981d565eB85e5E953928Ee0a26E42',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'typhv2-typh-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['TYPH', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x4090e535f2e251f5f88518998b18b54d26b3b07c',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4090e535f2e251f5f88518998b18b54d26b3b07c',
    network: 'bsc',
  },
  {
    id: 'cakev2-lmt-bnb',
    logo: 'bnb-pairs/LMT-BNB.svg',
    name: 'LMT-BNB LP',
    token: 'LMT-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x8271d7eAfeEb8F24d7C9fE1AccE2AE20611972E5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2LMT-BNB',
    earnedTokenAddress: '0x6261443B70d045f02B20Cc33e0faB096718563DF',
    earnContractAddress: '0x6261443B70d045f02B20Cc33e0faB096718563DF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-lmt-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['LMT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x9617857e191354dbea0b714d78bc59e57c411087',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x9617857e191354dbea0b714d78bc59e57c411087',
    network: 'bsc',
  },

  {
    id: 'swampv2-cake-bnb',
    logo: 'bnb-pairs/CAKE-BNB.svg',
    name: 'CAKE-BNB LP',
    token: 'CAKE-BNB LP2',
    tokenDescription: 'PancakeSwap (Swamp)',
    tokenAddress: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSwampV2CAKE-BNB',
    earnedTokenAddress: '0xe503B4c52a9220E20fe5Cf499D551977A6A12796',
    earnContractAddress: '0xe503B4c52a9220E20fe5Cf499D551977A6A12796',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-cake-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Pancake',
    assets: ['CAKE', 'BNB'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Vamp',
    vamp: 'Swamp',
    callFee: 0.5,
    depositFee: '<0.1%',
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    network: 'bsc',
  },

  {
    id: 'beltv2-belt-bnb',
    logo: 'bnb-pairs/BELT-BNB-BELT.png',
    name: 'BELT-BNB LP',
    token: 'BELT-BNB LP2',
    tokenDescription: 'PancakeSwap (Belt)',
    tokenAddress: '0xF3Bc6FC080ffCC30d93dF48BFA2aA14b869554bb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeltV2BeltBNB',
    earnedTokenAddress: '0x190DD361Ee7EDB1801d66e9E957C5CDF1e7Be75b',
    earnContractAddress: '0x190DD361Ee7EDB1801d66e9E957C5CDF1e7Be75b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-belt-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Belt',
    assets: ['BELT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
    network: 'bsc',
  },
  {
    id: 'belt-beltbnb',
    logo: 'single-assets/BNB.png',
    name: 'beltBNB',
    token: 'beltBNB',
    tokenDescription: 'Belt (Autofarm)',
    tokenAddress: '0xa8Bb71facdd46445644C277F9499Dd22f6F0A30C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeltBNB',
    earnedTokenAddress: '0xC34Ae91312A3c3F9420691922040a5DEe1698E52',
    earnContractAddress: '0xC34Ae91312A3c3F9420691922040a5DEe1698E52',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'belt-beltbnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Belt',
    assets: ['BNB'],
    risks: [
      'COMPLEXITY_HIGH',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Vamp',
    vamp: 'Autofarm',
    callFee: 0.5,
    depositFee: '<0.1%',
    withdrawalFee: '0.1%',
    addLiquidityUrl: 'https://belt.fi/',
    network: 'bsc',
  },
  {
    id: 'cakev2-dfd-busd',
    logo: 'busd-pairs/DFD-BUSD.svg',
    name: 'DFD-BUSD LP',
    token: 'DFD-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x029D66f9C0469450B7b4834B8dDC6a1118CeC3e1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2DFD-BUSD',
    earnedTokenAddress: '0xd80d646A28d60494A22Df83AA6b629df4F52DAeb',
    earnContractAddress: '0xd80d646A28d60494A22Df83AA6b629df4F52DAeb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-dfd-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['DFD', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'ALGO_STABLE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x9899a98b222fcb2f3dbee7df45d943093a4ff9ff',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x9899a98b222fcb2f3dbee7df45d943093a4ff9ff',
    network: 'bsc',
  },
  {
    id: 'cakev2-alpaca-busd',
    logo: 'busd-pairs/ALPACA-BUSD.svg',
    name: 'ALPACA-BUSD LP',
    token: 'ALPACA-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x7752e1FA9F3a2e860856458517008558DEb989e3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ALPACA-BUSD',
    earnedTokenAddress: '0x8d89BF6A2CbEAD5577399EAc9e76222b4c41eD69',
    earnContractAddress: '0x8d89BF6A2CbEAD5577399EAc9e76222b4c41eD69',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-alpaca-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ALPACA', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
    network: 'bsc',
  },
  {
    id: 'swampv2-swamp-bnb',
    logo: 'bnb-pairs/SWAMP-BNB.svg',
    name: 'SWAMP-BNB LP',
    token: 'SWAMP-BNB LP2',
    tokenDescription: 'PancakeSwap (Swamp)',
    tokenAddress: '0x59Fe1dcdCD8918fe7B4e76E21f27599d5a1eAADb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSwampV2SWAMP-BNB',
    earnedTokenAddress: '0xe77Ec021543985E914C54F81938039FF58e5B0b1',
    earnContractAddress: '0xe77Ec021543985E914C54F81938039FF58e5B0b1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'swampv2-swamp-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Swamp',
    assets: ['SWAMP', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    depositFee: '<0.1%',
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
    network: 'bsc',
  },

  {
    id: 'swampv2-swamp-busd',
    logo: 'busd-pairs/SWAMP-BUSD.svg',
    name: 'SWAMP-BUSD LP',
    token: 'SWAMP-BUSD LP2',
    tokenDescription: 'PancakeSwap (Swamp)',
    tokenAddress: '0x90946d2f9fB68ecc40ad8Bc0C1cB15D6cCbC4022',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSwampV2SWAMP-BUSD',
    earnedTokenAddress: '0xF5898cB211fAE8538253F08930e6b350cEe16444',
    earnContractAddress: '0xF5898cB211fAE8538253F08930e6b350cEe16444',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'swampv2-swamp-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Swamp',
    assets: ['SWAMP', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    depositFee: '<0.1%',
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d/0xe9e7cea3dedca5984780bafc599bd69add087d56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
    network: 'bsc',
  },

  {
    id: 'cakev2-bunny-bnb',
    logo: 'bnb-pairs/BUNNY-BNB.svg',
    name: 'BUNNY-BNB LP',
    token: 'BUNNY-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x5aFEf8567414F29f0f927A0F2787b188624c10E2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BUNNY-BNB',
    earnedTokenAddress: '0x5b1c50a1B0FfFB762b0431D6b0E1309a48Ac95a9',
    earnContractAddress: '0x5b1c50a1B0FfFB762b0431D6b0E1309a48Ac95a9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bunny-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BUNNY', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
    network: 'bsc',
  },
  {
    id: 'cakev2-eos-bnb',
    logo: 'bnb-pairs/EOS-BNB.svg',
    name: 'EOS-BNB LP',
    token: 'EOS-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xB6e34b5C65Eda51bb1BD4ea5F79d385Fb94b9504',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2EOS-BNB',
    earnedTokenAddress: '0x23ca638D912C98870bCd03CB3a7D4084072f4474',
    earnContractAddress: '0x23ca638D912C98870bCd03CB3a7D4084072f4474',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-eos-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['EOS', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
    network: 'bsc',
  },
  {
    id: 'cakev2-xrp-bnb',
    logo: 'bnb-pairs/XRP-BNB.svg',
    name: 'XRP-BNB LP',
    token: 'XRP-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x03F18135c44C64ebFdCBad8297fe5bDafdBbdd86',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2XRP-BNB',
    earnedTokenAddress: '0x1a39846045288d55f48Bc6AB2b77A03d93aB5e05',
    earnContractAddress: '0x1a39846045288d55f48Bc6AB2b77A03d93aB5e05',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-xrp-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['XRP', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    network: 'bsc',
  },
  {
    id: 'cakev2-uni-bnb',
    logo: 'bnb-pairs/UNI-BNB.svg',
    name: 'UNI-BNB LP',
    token: 'UNI-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x014608E87AF97a054C9a49f81E1473076D51d9a3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2UNI-BNB',
    earnedTokenAddress: '0xb3127Fe584E0A522857D1dAfb8ef0b8CE7E9A9c2',
    earnContractAddress: '0xb3127Fe584E0A522857D1dAfb8ef0b8CE7E9A9c2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-uni-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['UNI', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    network: 'bsc',
  },
  {
    id: 'cakev2-sxp-bnb',
    logo: 'bnb-pairs/SXP-BNB.svg',
    name: 'SXP-BNB LP',
    token: 'SXP-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xD8E2F8b6Db204c405543953Ef6359912FE3A88d6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2SXP-BNB',
    earnedTokenAddress: '0x4d92dfd7975910d63D0bfBc2A02b4BFad6E1FFC4',
    earnContractAddress: '0x4d92dfd7975910d63D0bfBc2A02b4BFad6E1FFC4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-sxp-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['SXP', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
    network: 'bsc',
  },
  {
    id: 'cakev2-blk-bnb',
    logo: 'bnb-pairs/BLK-BNB.svg',
    name: 'BLK-BNB LP',
    token: 'BLK-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x356Dd24BfF8e23BdE0430f00ad0C290E33438bD7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BLK-BNB',
    earnedTokenAddress: '0x9Cfd2F621546575721d1f59D0075D31ed6F8b5d7',
    earnContractAddress: '0x9Cfd2F621546575721d1f59D0075D31ed6F8b5d7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-blk-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BLK', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
    network: 'bsc',
  },
  {
    id: 'cakev2-helmet-bnb',
    logo: 'bnb-pairs/HELMET-BNB.svg',
    name: 'HELMET-BNB LP',
    token: 'HELMET-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xC869A9943b702B03770B6A92d2b2d25cf3a3f571',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2Helmet-BNB',
    earnedTokenAddress: '0x0882cB3746a17FAd5701BdA3E79036453F5b1fE0',
    earnContractAddress: '0x0882cB3746a17FAd5701BdA3E79036453F5b1fE0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-helmet-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['Helmet', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
    network: 'bsc',
  },
  {
    id: 'cakev2-jgn-bnb',
    logo: 'bnb-pairs/JGN-BNB.svg',
    name: 'JGN-BNB LP',
    token: 'JGN-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x7275278C94b5e20708380561C4Af98F38dDC6374',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2JGN-BNB',
    earnedTokenAddress: '0x211A8D59Cc44d86e30F5b5F298253B0EB4F9bc13',
    earnContractAddress: '0x211A8D59Cc44d86e30F5b5F298253B0EB4F9bc13',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-jgn-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['JGN', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75',
    network: 'bsc',
  },
  {
    id: 'cakev2-eps-bnb',
    logo: 'bnb-pairs/EPS-BNB.svg',
    name: 'EPS-BNB LP',
    token: 'EPS-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xddE420cbB3794ebD8FFC3Ac69F9c78e5d1411870',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2EPS-BNB',
    earnedTokenAddress: '0xDdfE5F59C735977d33f36f9B8D06E6A212CB9094',
    earnContractAddress: '0xDdfE5F59C735977d33f36f9B8D06E6A212CB9094',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-eps-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['EPS', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xA7f552078dcC247C2684336020c03648500C6d9F',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xA7f552078dcC247C2684336020c03648500C6d9F',
    network: 'bsc',
  },
  {
    id: 'cakev2-nrv-bnb',
    logo: 'bnb-pairs/NRV-BNB.svg',
    name: 'NRV-BNB LP',
    token: 'NRV-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xE482249Cd295C0d1e9D2baAEE71e66de21024C68',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2NRV-BNB',
    earnedTokenAddress: '0xD059034677f2E57489beF03f942CE6ffAccBEdD2',
    earnContractAddress: '0xD059034677f2E57489beF03f942CE6ffAccBEdD2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-nrv-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['NRV', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
    network: 'bsc',
  },
  {
    id: 'cakev2-bux-bnb',
    logo: 'bnb-pairs/BUX-BNB.svg',
    name: 'BUX-BNB LP',
    token: 'BUX-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x222C3CbB89647bF77822435Bd4c234A04272A77A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BUX-BNB',
    earnedTokenAddress: '0x139a3434F56b12335Db3d4c8AC3b9BBcA96FC438',
    earnContractAddress: '0x139a3434F56b12335Db3d4c8AC3b9BBcA96FC438',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bux-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BUX', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x211FfbE424b90e25a15531ca322adF1559779E45',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x211FfbE424b90e25a15531ca322adF1559779E45',
    network: 'bsc',
  },
  {
    id: 'cakev2-bfi-bnb',
    logo: 'bnb-pairs/BFI-BNB.svg',
    name: 'BFI-BNB LP',
    token: 'BFI-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x153Ad7d25B0b810497483d0cEE8AF42Fc533FeC8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BFI-BNB',
    earnedTokenAddress: '0x2a6163c0C608e8C2F90E94ae0D4A0df9ABc4A818',
    earnContractAddress: '0x2a6163c0C608e8C2F90E94ae0D4A0df9ABc4A818',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bfi-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BFI', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x81859801b01764D4f0Fa5E64729f5a6C3b91435b',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x81859801b01764D4f0Fa5E64729f5a6C3b91435b',
    network: 'bsc',
  },
  {
    id: 'cakev2-bdo-bnb',
    logo: 'bnb-pairs/BDO-BNB.svg',
    name: 'BDO-BNB LP',
    token: 'BDO-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x4288706624e3dD839b069216eB03B8B9819C10d2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BDO-BNB',
    earnedTokenAddress: '0x0dc321E38BfF5693e6c003Ab71eD85693919B04e',
    earnContractAddress: '0x0dc321E38BfF5693e6c003Ab71eD85693919B04e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bdo-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BDO', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
    network: 'bsc',
  },
  {
    id: 'cakev2-for-busd',
    logo: 'busd-pairs/FOR-BUSD.svg',
    name: 'FOR-BUSD LP',
    token: 'FOR-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xE60B4e87645093A42fa9dcC5d0C8Df6E67f1f9d2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2FOR-BUSD',
    earnedTokenAddress: '0x010ef61eb2240154C8779201be0914a8AaDcA828',
    earnContractAddress: '0x010ef61eb2240154C8779201be0914a8AaDcA828',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-for-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['FOR', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x658A109C5900BC6d2357c87549B651670E5b0539/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x658A109C5900BC6d2357c87549B651670E5b0539',
    network: 'bsc',
  },
  {
    id: 'cakev2-vai-busd',
    logo: 'busd-pairs/VAI-BUSD.svg',
    name: 'VAI-BUSD LP',
    token: 'VAI-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x133ee93FE93320e1182923E1a640912eDE17C90C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2VAI-BUSD',
    earnedTokenAddress: '0xf5eA38B6b9644224dA1aECbC1219e8543c0689b2',
    earnContractAddress: '0xf5eA38B6b9644224dA1aECbC1219e8543c0689b2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-vai-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['VAI', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x4bd17003473389a42daf6a0a729f6fdb328bbbd7/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x4bd17003473389a42daf6a0a729f6fdb328bbbd7&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'cakev2-usdt-busd',
    logo: 'busd-pairs/USDT-BUSD.svg',
    name: 'USDT-BUSD LP',
    token: 'USDT-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2USDT-BUSD',
    earnedTokenAddress: '0x4c66ef364F54FCdD540ad29E075bc23cFe9F9FF6',
    earnContractAddress: '0x4c66ef364F54FCdD540ad29E075bc23cFe9F9FF6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-usdt-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['USDT', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x55d398326f99059ff775485246999027b3197955',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955',
    network: 'bsc',
  },

  {
    id: 'cakev2-alpha-bnb',
    logo: 'bnb-pairs/ALPHA-BNB.svg',
    name: 'ALPHA-BNB LP',
    token: 'ALPHA-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xACF47CBEaab5c8A6Ee99263cfE43995f89fB3206',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ALPHA-BNB',
    earnedTokenAddress: '0xfcA433b1c071737F92B76234984aBA10D04De57E',
    earnContractAddress: '0xfcA433b1c071737F92B76234984aBA10D04De57E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-alpha-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ALPHA', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xa1faa113cbe53436df28ff0aee54275c13b40975',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xa1faa113cbe53436df28ff0aee54275c13b40975',
    network: 'bsc',
  },

  {
    id: 'cakev2-inj-bnb',
    logo: 'bnb-pairs/INJ-BNB.svg',
    name: 'INJ-BNB LP',
    token: 'INJ-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x1BdCebcA3b93af70b58C41272AEa2231754B23ca',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2INJ-BNB',
    earnedTokenAddress: '0xb0602F26Fb2b7F8bca2d9aDB3FC3EF8619502097',
    earnContractAddress: '0xb0602F26Fb2b7F8bca2d9aDB3FC3EF8619502097',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-inj-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['INJ', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xa2B726B1145A4773F68593CF171187d8EBe4d495',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xa2B726B1145A4773F68593CF171187d8EBe4d495',
    network: 'bsc',
  },

  {
    id: 'cakev2-twt-bnb',
    logo: 'bnb-pairs/TWT-BNB.svg',
    name: 'TWT-BNB LP',
    token: 'TWT-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x3DcB1787a95D2ea0Eb7d00887704EeBF0D79bb13',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2TWT-BNB',
    earnedTokenAddress: '0x9151B2E9ad4B4222d67b8467BBcf1f37597b9E7B',
    earnContractAddress: '0x9151B2E9ad4B4222d67b8467BBcf1f37597b9E7B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-twt-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['TWT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x4b0f1812e5df2a09796481ff14017e6005508003',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4b0f1812e5df2a09796481ff14017e6005508003',
    network: 'bsc',
  },

  {
    id: 'cakev2-xvs-bnb',
    logo: 'bnb-pairs/XVS-BNB.svg',
    name: 'XVS-BNB LP',
    token: 'XVS-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x7EB5D86FD78f3852a3e0e064f2842d45a3dB6EA2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2XVS-BNB',
    earnedTokenAddress: '0xa2f05EA4Af928BA34d66E6f69343a6703744Caba',
    earnContractAddress: '0xa2f05EA4Af928BA34d66E6f69343a6703744Caba',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-xvs-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['XVS', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
    network: 'bsc',
  },
  {
    id: 'cakev2-unfi-bnb-eol',
    logo: 'bnb-pairs/UNFI-BNB.svg',
    name: 'UNFI-BNB LP',
    token: 'UNFI-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x44EA47F2765fd5D26b7eF0222736AD6FD6f61950',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2UNFI-BNB',
    earnedTokenAddress: '0x85A95Ab3580d5Cb5DC6cC12DE79939508a2BFc5C',
    earnContractAddress: '0x85A95Ab3580d5Cb5DC6cC12DE79939508a2BFc5C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-unfi-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['UNFI', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/remove/BNB/0x728c5bac3c3e370e372fc4671f9ef6916b814d8b',
    network: 'bsc',
  },

  {
    id: 'cakev2-reef-bnb',
    logo: 'bnb-pairs/REEF-BNB.svg',
    name: 'REEF-BNB LP',
    token: 'REEF-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xd63b5CecB1f40d626307B92706Df357709D05827',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2REEF-BNB',
    earnedTokenAddress: '0x8ec78a292e5E1Bce2ed917eD8b02cf9E93a78CdE',
    earnContractAddress: '0x8ec78a292e5E1Bce2ed917eD8b02cf9E93a78CdE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-reef-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['REEF', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e',
    network: 'bsc',
  },
  {
    id: 'cakev2-yfi-bnb-eol',
    logo: 'bnb-pairs/YFI-BNB.svg',
    name: 'YFI-BNB LP',
    token: 'YFI-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xCE383277847f8217392eeA98C5a8B4a7D27811b0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2YFI-BNB',
    earnedTokenAddress: '0xF5e6b5adA220F8f62310d2d2D7013103b8e91651',
    earnContractAddress: '0xF5e6b5adA220F8f62310d2d2D7013103b8e91651',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-yfi-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['YFI', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/remove/BNB/0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e',
    network: 'bsc',
  },

  {
    id: 'cakev2-fil-bnb-eol',
    logo: 'bnb-pairs/FIL-BNB.svg',
    name: 'FIL-BNB LP',
    token: 'FIL-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xD9bCcbbbDFd9d67BEb5d2273102CE0762421D1e3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2FIL-BNB',
    earnedTokenAddress: '0x7AA10F621b2A3c7a8421f1369494F6fE263F3D31',
    earnContractAddress: '0x7AA10F621b2A3c7a8421f1369494F6fE263F3D31',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-fil-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['FIL', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/remove/BNB/0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
    network: 'bsc',
  },

  {
    id: 'cakev2-band-bnb',
    logo: 'bnb-pairs/BAND-BNB.svg',
    name: 'BAND-BNB LP',
    token: 'BAND-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x168B273278F3A8d302De5E879aA30690B7E6c28f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BAND-BNB',
    earnedTokenAddress: '0x9e6E0D9c43dDaC58710BeE30B01a5117f8Ba69bC',
    earnContractAddress: '0x9e6E0D9c43dDaC58710BeE30B01a5117f8Ba69bC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-band-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BAND', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18',
    network: 'bsc',
  },

  {
    id: 'cakev2-atom-bnb',
    logo: 'bnb-pairs/ATOM-BNB.svg',
    name: 'ATOM-BNB LP',
    token: 'ATOM-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x468b2DC8DC75990eE3E9dc0648965Ad6294E7914',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ATOM-BNB',
    earnedTokenAddress: '0x8815d484A3bf89b7e996f846ddAb6DAE1C4BEA3A',
    earnContractAddress: '0x8815d484A3bf89b7e996f846ddAb6DAE1C4BEA3A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-atom-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ATOM', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x0eb3a705fc54725037cc9e008bdede697f62f335',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0eb3a705fc54725037cc9e008bdede697f62f335',
    network: 'bsc',
  },
  {
    id: 'cakev2-cake-bnb',
    logo: 'bnb-pairs/CAKE-BNB.svg',
    name: 'CAKE-BNB LP',
    token: 'CAKE-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2CAKE-BNB',
    earnedTokenAddress: '0xb26642B6690E4c4c9A6dAd6115ac149c700C7dfE',
    earnContractAddress: '0xb26642B6690E4c4c9A6dAd6115ac149c700C7dfE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-cake-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['CAKE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/BNB',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
    network: 'bsc',
  },
  {
    id: 'cakev2-hget-bnb',
    logo: 'bnb-pairs/HGET-BNB.svg',
    name: 'HGET-BNB LP',
    token: 'HGET-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xF74ee1e10e097dc326a2ad004F9Cc95CB71088d3',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2HGET-BNB',
    earnedTokenAddress: '0xdA7439450510FB56052681eb1A3FBb9C9880f80d',
    earnContractAddress: '0xdA7439450510FB56052681eb1A3FBb9C9880f80d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-hget-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['HGET', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xc7d8d35eba58a0935ff2d5a33df105dd9f071731',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xc7d8d35eba58a0935ff2d5a33df105dd9f071731',
    network: 'bsc',
  },
  {
    id: 'cakev2-cgg-bnb',
    logo: 'bnb-pairs/CGG-BNB.svg',
    name: 'CGG-BNB LP',
    token: 'CGG-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x0604471c532F9fEBAD3E37190B667f44BD0894b3',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2CGG-BNB',
    earnedTokenAddress: '0x7697B2F43E73bC3292c82d1087BB7c6aEC931fED',
    earnContractAddress: '0x7697B2F43E73bC3292c82d1087BB7c6aEC931fED',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-cgg-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['CGG', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x1613957159e9b0ac6c80e824f7eea748a32a0ae2',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x1613957159e9b0ac6c80e824f7eea748a32a0ae2',
    network: 'bsc',
  },

  {
    id: 'cakev2-xed-bnb',
    logo: 'bnb-pairs/XED-BNB.svg',
    name: 'XED-BNB LP',
    token: 'XED-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xa7A0b605343dF36B748FF4B5f7578b3F2D0651CE',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2XED-BNB',
    earnedTokenAddress: '0xDAa93B916ca2A3608B912F8514c38678d7f9E568',
    earnContractAddress: '0xDAa93B916ca2A3608B912F8514c38678d7f9E568',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-xed-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['XED', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x5621b5a3f4a8008c4ccdd1b942b121c8b1944f1f',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x5621b5a3f4a8008c4ccdd1b942b121c8b1944f1f',
    network: 'bsc',
  },

  {
    id: 'cakev2-link-bnb',
    logo: 'bnb-pairs/LINK-BNB.svg',
    name: 'LINK-BNB LP',
    token: 'LINK-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x824eb9faDFb377394430d2744fa7C42916DE3eCe',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2LINK-BNB',
    earnedTokenAddress: '0xaB5EA0Fee3AEdE95D9747f41109f0b9F3b8f07E7',
    earnContractAddress: '0xaB5EA0Fee3AEdE95D9747f41109f0b9F3b8f07E7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-link-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['LINK', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    network: 'bsc',
  },

  {
    id: 'cakev2-usdt-bnb',
    logo: 'bnb-pairs/USDT-BNB.svg',
    name: 'USDT-BNB LP',
    token: 'USDT-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2USDT-BNB',
    earnedTokenAddress: '0x8d98855595E7971abE44bAdf1574fd126B286de4',
    earnContractAddress: '0x8d98855595E7971abE44bAdf1574fd126B286de4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-usdt-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['USDT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x55d398326f99059ff775485246999027b3197955',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955',
    network: 'bsc',
  },

  {
    id: 'cakev2-busd-bnb',
    logo: 'bnb-pairs/BUSD-BNB.svg',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2BNB-BUSD',
    earnedTokenAddress: '0xAd61143796D90FD5A61d89D63a546C7dB0a70475',
    earnContractAddress: '0xAd61143796D90FD5A61d89D63a546C7dB0a70475',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-busd-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BUSD', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'cakev2-eth-bnb',
    logo: 'bnb-pairs/ETH-BNB.svg',
    name: 'ETH-BNB LP',
    token: 'ETH-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2ETH-BNB',
    earnedTokenAddress: '0x0eb78598851D08218d54fCe965ee2bf29C288fac',
    earnContractAddress: '0x0eb78598851D08218d54fCe965ee2bf29C288fac',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-eth-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ETH', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    network: 'bsc',
  },

  {
    id: 'cakev2-btcb-bnb',
    logo: 'bnb-pairs/BTCB-BNB.svg',
    name: 'BTCB-BNB LP',
    token: 'BTCB-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2BNB-BTCB',
    earnedTokenAddress: '0xafE4f29578FbfE7Be32B836CBEb81daB6574cC70',
    earnContractAddress: '0xafE4f29578FbfE7Be32B836CBEb81daB6574cC70',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-btcb-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BTCB', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    network: 'bsc',
  },

  {
    id: 'cakev2-hakka-busd',
    logo: 'busd-pairs/HAKKA-BUSD.svg',
    name: 'HAKKA-BUSD LP',
    token: 'HAKKA-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x2C624C9Ecf16Cb81aB85cC2C0B0c5e12A09AFDa6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2HAKKA-BUSD',
    earnedTokenAddress: '0xF79BF908d0e6d8E7054375CD80dD33424B1980bf',
    earnContractAddress: '0xF79BF908d0e6d8E7054375CD80dD33424B1980bf',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-hakka-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['HAKKA', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x1d1eb8e8293222e1a29d2c0e4ce6c0acfd89aaac',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x1d1eb8e8293222e1a29d2c0e4ce6c0acfd89aaac',
    network: 'bsc',
  },

  {
    id: 'cakev2-hoo-busd',
    logo: 'busd-pairs/HOO-BUSD.svg',
    name: 'HOO-BUSD LP',
    token: 'HOO-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x3e42C1f7239231E3752B507764445dd8e6A570d5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2HOO-BUSD',
    earnedTokenAddress: '0x36eE7e0AA45eb94bb0B0FC9B9f5bdc66819761A5',
    earnContractAddress: '0x36eE7e0AA45eb94bb0B0FC9B9f5bdc66819761A5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-hoo-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['HOO', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xE1d1F66215998786110Ba0102ef558b22224C016',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xE1d1F66215998786110Ba0102ef558b22224C016',
    network: 'bsc',
  },

  {
    id: 'cakev2-txl-busd',
    logo: 'busd-pairs/TXL-BUSD.svg',
    name: 'TXL-BUSD LP',
    token: 'TXL-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x1434BB50196A0C7eA825940b1DFd8aAd25d79817',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2TXL-BUSD',
    earnedTokenAddress: '0x9Bc299505Ce14FBa3bA3F1357CF3AA48ea01A2b9',
    earnContractAddress: '0x9Bc299505Ce14FBa3bA3F1357CF3AA48ea01A2b9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-txl-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['TXL', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x1ffd0b47127fdd4097e54521c9e2c7f0d66aafc5',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x1ffd0b47127fdd4097e54521c9e2c7f0d66aafc5&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
    network: 'bsc',
  },

  {
    id: 'cakev2-ust-busd',
    logo: 'busd-pairs/UST-BUSD.svg',
    name: 'UST-BUSD LP',
    token: 'UST-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x05faf555522Fa3F93959F86B41A3808666093210',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2UST-BUSD',
    earnedTokenAddress: '0x76c8b991F99a77B270D5f6529855f3612891e70f',
    earnContractAddress: '0x76c8b991F99a77B270D5f6529855f3612891e70f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ust-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['UST', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x23396cF899Ca06c4472205fC903bDB4de249D6fC/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x23396cF899Ca06c4472205fC903bDB4de249D6fC&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'cakev2-nuls-busd',
    logo: 'busd-pairs/NULS-BUSD.svg',
    name: 'NULS-BUSD LP',
    token: 'NULS-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x853784B7BDe87d858555715c0123374242db7943',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2NULS-BUSD',
    earnedTokenAddress: '0x11590b2f4148E65aC34C5b95bE52a4eaf161593E',
    earnContractAddress: '0x11590b2f4148E65aC34C5b95bE52a4eaf161593E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-nuls-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['NULS', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'cakev2-mamzn-ust-eol',
    logo: 'ust-pairs/MAMZN-UST.svg',
    name: 'MAMZN-UST LP',
    token: 'MAMZN-UST LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xC05654C66756eBB82c518598c5f1ea1a0199a563',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2mAMZN-UST',
    earnedTokenAddress: '0x9B9CB3bCd038B114e6094Ac00Db649a12741E3af',
    earnContractAddress: '0x9B9CB3bCd038B114e6094Ac00Db649a12741E3af',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-mamzn-ust',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['MAMZN', 'UST'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/remove/0x23396cf899ca06c4472205fc903bdb4de249d6fc/0x3947B992DC0147D2D89dF0392213781b04B25075',
    network: 'bsc',
  },

  {
    id: 'cakev2-mgoogl-ust-eol',
    logo: 'ust-pairs/MGOOGL-UST.svg',
    name: 'MGOOGL-UST LP',
    token: 'MGOOGL-UST LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xA3BfBbAd526C6B856B1Fdf73F99BCD894761fbf3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2mGOOGL-UST',
    earnedTokenAddress: '0xB623301eF34B30123581975542500f402DCf8d79',
    earnContractAddress: '0xB623301eF34B30123581975542500f402DCf8d79',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-mgoogl-ust',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['MGOOGL', 'UST'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/remove/0x23396cf899ca06c4472205fc903bdb4de249d6fc/0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f',
    network: 'bsc',
  },

  {
    id: 'cakev2-mnflx-ust-eol',
    logo: 'ust-pairs/MNFLX-UST.svg',
    name: 'MNFLX-UST LP',
    token: 'MNFLX-UST LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x91417426C3FEaA3Ca795921eB9FdD9715ad92537',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2mNFLX-UST',
    earnedTokenAddress: '0xE2409314Df4708230999F4bE4dE4Ac3cF3d0B08c',
    earnContractAddress: '0xE2409314Df4708230999F4bE4dE4Ac3cF3d0B08c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-mnflx-ust',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['MNFLX', 'UST'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/remove/0x23396cf899ca06c4472205fc903bdb4de249d6fc/0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc',
    network: 'bsc',
  },

  {
    id: 'cakev2-mtsla-ust-eol',
    logo: 'ust-pairs/MTSLA-UST.svg',
    name: 'MTSLA-UST LP',
    token: 'MTSLA-UST LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xEc6b56a736859AE8ea4bEdA16279Ecd8c60dA7EA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2mTSLA-UST',
    earnedTokenAddress: '0x30E31EA485D5be9E1A63268181e6EF731b9b853f',
    earnContractAddress: '0x30E31EA485D5be9E1A63268181e6EF731b9b853f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-mtsla-ust',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['MTSLA', 'UST'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/remove/0x23396cf899ca06c4472205fc903bdb4de249d6fc/0xF215A127A196e3988C09d052e16BcFD365Cd7AA3',
    network: 'bsc',
  },

  {
    id: 'cakev2-mir-ust',
    logo: 'ust-pairs/MIR-UST.svg',
    name: 'MIR-UST LP',
    token: 'MIR-UST LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x89666d026696660e93Bf6edf57B71A68615768B7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2MIR-UST',
    earnedTokenAddress: '0x268e83D800c22ED8F00C4a8eFb9c3d44Ad61CB2e',
    earnContractAddress: '0x268e83D800c22ED8F00C4a8eFb9c3d44Ad61CB2e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-mir-ust',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['MIR', 'UST'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x23396cF899Ca06c4472205fC903bDB4de249D6fC/0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
    network: 'bsc',
  },
  {
    id: 'cakev2-tbtc-btcb',
    logo: 'uncategorized/TBTC-BTCB.svg',
    name: 'tBTC-BTCB LP',
    token: 'tBTC-BTCB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x8046fa66753928F35f7Db23ae0188ee6743C2FBA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2tBTC-BTCB',
    earnedTokenAddress: '0xf03B5870bb8EFdcabAd45f2283D64A7fbCdb2B0E',
    earnContractAddress: '0xf03B5870bb8EFdcabAd45f2283D64A7fbCdb2B0E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-tbtc-btcb',
    oraclePrice: 0,
    status: 'deposits-paused',
    platform: 'PancakeSwap',
    assets: ['tBTC', 'BTCB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c&outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9',
    network: 'bsc',
  },
  {
    id: 'cakev2-bbadger-btcb',
    logo: 'uncategorized/BADGER-BTCB.svg',
    name: 'bBADGER-BTCB LP',
    token: 'bBADGER-BTCB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x5A58609dA96469E9dEf3fE344bC39B00d18eb9A5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2bBADGER-BTCB',
    earnedTokenAddress: '0xC4fAB5E28C9f71D59B9d08BCA51FBe283bf4542F',
    earnContractAddress: '0xC4fAB5E28C9f71D59B9d08BCA51FBe283bf4542F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bbadger-btcb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['bBADGER', 'BTCB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/0x1f7216fdb338247512ec99715587bb97bbf96eae',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x1f7216fdb338247512ec99715587bb97bbf96eae&outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9',
    network: 'bsc',
  },

  {
    id: 'cakev2-bdigg-btcb',
    logo: 'uncategorized/BDIGG-BTCB.svg',
    name: 'bDIGG-BTCB LP',
    token: 'bDIGG-BTCB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x81d776C90c89B8d51E9497D58338933127e2fA80',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2bDIGG-BTCB',
    earnedTokenAddress: '0xE32e76e79E94f4C9091F9a4120A72888d0DFf3C6',
    earnContractAddress: '0xE32e76e79E94f4C9091F9a4120A72888d0DFf3C6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bdigg-btcb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['bDIGG', 'BTCB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_SMALL',
      'ALGO_STABLE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x1f7216fdb338247512ec99715587bb97bbf96eae&outputCurrency=0x5986d5c77c65e5801a5caa4fae80089f870a71da',
    network: 'bsc',
  },
  {
    id: 'cakev2-ramp-busd',
    logo: 'busd-pairs/RAMP-BUSD.svg',
    name: 'RAMP-BUSD LP',
    token: 'RAMP-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xE834bf723f5bDff34a5D1129F3c31Ea4787Bc76a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2RAMP-BUSD',
    earnedTokenAddress: '0x37b67318d7746faD405AC139847b96718D37b8C4',
    earnContractAddress: '0x37b67318d7746faD405AC139847b96718D37b8C4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ramp-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['RAMP', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x8519ea49c997f50ceffa444d240fb655e89248aa',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x8519ea49c997f50ceffa444d240fb655e89248aa&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },
  {
    id: 'cakev2-dexe-busd',
    logo: 'busd-pairs/DEXE-BUSD.svg',
    name: 'DEXE-BUSD LP',
    token: 'DEXE-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x3578B1f9BCE98D2F4D293b422d8850fdf48B1f21',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2DEXE-BUSD',
    earnedTokenAddress: '0x257d62DB67418735f7239c6F27f4d7b426067205',
    earnContractAddress: '0x257d62DB67418735f7239c6F27f4d7b426067205',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-dexe-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['DEXE', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x039cb485212f996a9dbb85a9a75d898f94d38da6',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x039cb485212f996a9dbb85a9a75d898f94d38da6&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },
  {
    id: 'cakev2-tpt-busd',
    logo: 'busd-pairs/TPT-BUSD.svg',
    name: 'TPT-BUSD LP',
    token: 'TPT-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x6D0c831254221ba121fB53fb44Df289A6558867d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2TPT-BUSD',
    earnedTokenAddress: '0xd6D3FEACC85E5708e6344548a3415A5f484889c5',
    earnContractAddress: '0xd6D3FEACC85E5708e6344548a3415A5f484889c5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-tpt-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['TPT', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0xeca41281c24451168a37211f0bc2b8645af45092',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xeca41281c24451168a37211f0bc2b8645af45092&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },
  {
    id: 'cakev2-xmark-busd',
    logo: 'busd-pairs/XMARK-BUSD.svg',
    name: 'XMARK-BUSD LP',
    token: 'XMARK-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xAa40f1AC20AAFcFEE8595Da606D78C503C7e70A3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2XMARK-BUSD',
    earnedTokenAddress: '0xEEB6F2857746a50554a58E00D55b4E54657aba83',
    earnContractAddress: '0xEEB6F2857746a50554a58E00D55b4E54657aba83',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-xmark-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['XMARK', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'ALGO_STABLE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x26a5dfab467d4f58fb266648cae769503cec9580',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x26a5dfab467d4f58fb266648cae769503cec9580&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },
  {
    id: 'cakev2-iotx-busd',
    logo: 'busd-pairs/IOTX-BUSD.svg',
    name: 'IOTX-BUSD LP',
    token: 'IOTX-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xc13aA76AAc067c86aE38028019F414D731b3D86A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2IOTX-BUSD',
    earnedTokenAddress: '0x07fFC2258c99e6667235fEAa90De35A0a50CFBFd',
    earnContractAddress: '0x07fFC2258c99e6667235fEAa90De35A0a50CFBFd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-iotx-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['IOTX', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x9678e42cebeb63f23197d726b29b1cb20d0064e5',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x9678e42cebeb63f23197d726b29b1cb20d0064e5&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'cakev2-lina-busd',
    logo: 'busd-pairs/LINA-BUSD.svg',
    name: 'LINA-BUSD LP',
    token: 'LINA-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xC5768c5371568Cf1114cddD52CAeD163A42626Ed',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2LINA-BUSD',
    earnedTokenAddress: '0x40d29c06EFB7Da61008fB65CB113786f076058a4',
    earnContractAddress: '0x40d29c06EFB7Da61008fB65CB113786f076058a4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-lina-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['LINA', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x762539b45a1dcce3d36d080f74d1aed37844b878',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x762539b45a1dcce3d36d080f74d1aed37844b878&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'cakev2-usdc-busd',
    logo: 'busd-pairs/USDC-BUSD.svg',
    name: 'USDC-BUSD LP',
    token: 'USDC-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2USDC-BUSD',
    earnedTokenAddress: '0x9260c62866f36638964551A8f480C3aAAa4693fd',
    earnContractAddress: '0x9260c62866f36638964551A8f480C3aAAa4693fd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-usdc-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['USDC', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },
  {
    id: 'cakev2-dai-busd',
    logo: 'busd-pairs/DAI-BUSD.svg',
    name: 'DAI-BUSD LP',
    token: 'DAI-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2DAI-BUSD',
    earnedTokenAddress: '0x6eb020663898c72F8E25958F5f7bcDC732279201',
    earnContractAddress: '0x6eb020663898c72F8E25958F5f7bcDC732279201',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-dai-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['DAI', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },
  {
    id: 'cakev2-comp-eth',
    logo: 'uncategorized/COMP-ETH.svg',
    name: 'COMP-ETH LP',
    token: 'COMP-ETH LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x37908620dEf1491Dd591b5a2d16022A33cDDA415',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2COMP-ETH',
    earnedTokenAddress: '0x0a9c850D95796e32e6eBe4966420e435ed86D3d9',
    earnContractAddress: '0x0a9c850D95796e32e6eBe4966420e435ed86D3d9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-comp-eth',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['COMP', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8&outputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    network: 'bsc',
  },

  {
    id: 'cakev2-sushi-eth',
    logo: 'uncategorized/SUSHI-ETH.svg',
    name: 'SUSHI-ETH LP',
    token: 'SUSHI-ETH LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x16aFc4F2Ad82986bbE2a4525601F8199AB9c832D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2SUSHI-ETH',
    earnedTokenAddress: '0x517b331B00dF201B660FfE74874Ed491D8047879',
    earnContractAddress: '0x517b331B00dF201B660FfE74874Ed491D8047879',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-sushi-eth',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['SUSHI', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x2170ed0880ac9a755fd29b2688956bd959f933f8/0x947950bcc74888a40ffa2593c5798f11fc9124c4',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=0x947950bcc74888a40ffa2593c5798f11fc9124c4',
    network: 'bsc',
  },

  {
    id: 'cakev2-dego-bnb',
    logo: 'bnb-pairs/DEGO-BNB.svg',
    name: 'DEGO-BNB LP',
    token: 'DEGO-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xF1Ec67fA1881796BFf63Db3E1A301cE9cb787Fad',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2DEGO-BNB',
    earnedTokenAddress: '0x98a8Fd9cA5B556dc387820CAC39f0584E4f87E51',
    earnContractAddress: '0x98a8Fd9cA5B556dc387820CAC39f0584E4f87E51',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-dego-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['DEGO', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC',
    network: 'bsc',
  },

  {
    id: 'cakev2-gum-bnb',
    logo: 'bnb-pairs/GUM-BNB.svg',
    name: 'GUM-BNB LP',
    token: 'GUM-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x28Ea5894D4DBbE90bB58eE3BAB2869387d711c87',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2GUM-BNB',
    earnedTokenAddress: '0x04dc8B92192183a1e9186Df0CC27024Dfc83a7f1',
    earnContractAddress: '0x04dc8B92192183a1e9186Df0CC27024Dfc83a7f1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-gum-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['GUM', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
    network: 'bsc',
  },

  {
    id: 'cakev2-pbtc-bnb',
    logo: 'bnb-pairs/PBTC-BNB.svg',
    name: 'pBTC-BNB LP',
    token: 'pBTC-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x0362ba706DFE8ED12Ec1470aB171d8Dcb1C72B8D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2PBTC-BNB',
    earnedTokenAddress: '0x2D8653881D8216AF7E5de31D5321FC21f45845FE',
    earnContractAddress: '0x2D8653881D8216AF7E5de31D5321FC21f45845FE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-pbtc-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['pBTC', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
    network: 'bsc',
  },
  {
    id: 'cakev2-belt-bnb',
    logo: 'bnb-pairs/BELT-BNB.svg',
    name: 'BELT-BNB LP',
    token: 'BELT-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xF3Bc6FC080ffCC30d93dF48BFA2aA14b869554bb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BELT-BNB',
    earnedTokenAddress: '0x3F0f1a8E2DCe5dA86Cad9b5914D8018c021E08b1',
    earnContractAddress: '0x3F0f1a8E2DCe5dA86Cad9b5914D8018c021E08b1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-belt-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BELT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/ETH/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
    network: 'bsc',
  },
  {
    id: 'cakev2-bel-bnb',
    logo: 'bnb-pairs/BEL-BNB.svg',
    name: 'BEL-BNB LP',
    token: 'BEL-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x69DEE989c30b5fFe40867f5FC14F00E4bCE7B681',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BEL-BNB',
    earnedTokenAddress: '0xE5FF1e597AF772BE9c6d95B9Be2193FC6b917539',
    earnContractAddress: '0xE5FF1e597AF772BE9c6d95B9Be2193FC6b917539',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bel-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BEL', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x8443f091997f06a61670b735ed92734f5628692f',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8443f091997f06a61670b735ed92734f5628692f',
    network: 'bsc',
  },
  {
    id: 'cakev2-bmxx-bnb',
    logo: 'bnb-pairs/BMXX-BNB.svg',
    name: 'BMXX-BNB LP',
    token: 'BMXX-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xc20A92a1424b29b78DFaF92FD35D4cf8A06419B4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BMXX-BNB',
    earnedTokenAddress: '0xC83cB476c772622196a56A3B51F625ac48Ff9746',
    earnContractAddress: '0xC83cB476c772622196a56A3B51F625ac48Ff9746',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bmxx-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BMXX', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x4131b87f74415190425ccd873048c708f8005823',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4131b87f74415190425ccd873048c708f8005823',
    network: 'bsc',
  },

  {
    id: 'cakev2-bor-bnb',
    logo: 'bnb-pairs/BOR-BNB.svg',
    name: 'BOR-BNB LP',
    token: 'BOR-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xe094c686aD6cDda57b9564457F541FBF099B948A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BOR-BNB',
    earnedTokenAddress: '0x8C8bd55Be4610131b99B9c136834d46a379b8A27',
    earnContractAddress: '0x8C8bd55Be4610131b99B9c136834d46a379b8A27',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bor-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BOR', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x92d7756c60dcfd4c689290e8a9f4d263b3b32241',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x92d7756c60dcfd4c689290e8a9f4d263b3b32241',
    network: 'bsc',
  },

  {
    id: 'cakev2-bopen-bnb',
    logo: 'bnb-pairs/BOPEN-BNB.svg',
    name: 'BOPEN-BNB LP',
    token: 'BOPEN-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xc7A9c2af263ebB86139Cca9349e49b17129Ba033',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BOPEN-BNB',
    earnedTokenAddress: '0x2d4bEd7FC6c5004906b380D23188cD457fD97415',
    earnContractAddress: '0x2d4bEd7FC6c5004906b380D23188cD457fD97415',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bopen-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BOPEN', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xf35262a9d427f96d2437379ef090db986eae5d42',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xf35262a9d427f96d2437379ef090db986eae5d42',
    network: 'bsc',
  },
  {
    id: 'cakev2-swgb-bnb',
    logo: 'bnb-pairs/SWGB-BNB.svg',
    name: 'SWGB-BNB LP',
    token: 'SWGB-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x856f9AD94cA8680B899214Bb1EB3d235a3C33Afe',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2SWGB-BNB',
    earnedTokenAddress: '0x891ac86d46c97b7cf330ba4f5a905930Ac331858',
    earnContractAddress: '0x891ac86d46c97b7cf330ba4f5a905930Ac331858',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-swgb-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['SWGB', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb',
    network: 'bsc',
  },

  {
    id: 'cakev2-lit-bnb',
    logo: 'bnb-pairs/LIT-BNB.svg',
    name: 'LIT-BNB LP',
    token: 'LIT-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x1F37d4226d23d09044B8005c127C0517BD7e94fD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2LIT-BNB',
    earnedTokenAddress: '0x2257EFfa0Be8A43cDED6E339991844Dd6277f8e6',
    earnContractAddress: '0x2257EFfa0Be8A43cDED6E339991844Dd6277f8e6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-lit-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['LIT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
    network: 'bsc',
  },

  {
    id: 'cakev2-hard-bnb-eol',
    logo: 'bnb-pairs/HARD-BNB.svg',
    name: 'HARD-BNB LP',
    token: 'HARD-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x73566ca86248bD12F0979793e4671e99a40299A7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2HARD-BNB',
    earnedTokenAddress: '0x0ED3d249E81fa29D3Bf807222e195102E5AF9241',
    earnContractAddress: '0x0ED3d249E81fa29D3Bf807222e195102E5AF9241',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-hard-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['HARD', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/remove/BNB/0xf79037f6f6be66832de4e7516be52826bc3cbcc4',
    network: 'bsc',
  },
  {
    id: 'cakev2-ditto-bnb',
    logo: 'bnb-pairs/DITTO-BNB.svg',
    name: 'DITTO-BNB LP',
    token: 'DITTO-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x8645148dE4E339964bA480AE3478653b5bc6E211',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2DITTO-BNB',
    earnedTokenAddress: '0x19D62B080Df4dfb9ca2c8Aa56721d06860EcD533',
    earnContractAddress: '0x19D62B080Df4dfb9ca2c8Aa56721d06860EcD533',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ditto-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['DITTO', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x233d91a0713155003fc4dce0afa871b508b3b715',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x233d91a0713155003fc4dce0afa871b508b3b715',
    network: 'bsc',
  },
  {
    id: 'cakev2-sfp-bnb',
    logo: 'bnb-pairs/SFP-BNB.svg',
    name: 'SFP-BNB LP',
    token: 'SFP-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x942b294e59a8c47a0F7F20DF105B082710F7C305',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2SFP-BNB',
    earnedTokenAddress: '0xb5b88135d363A99bF4664C4a1A51dF4ec3cC154A',
    earnContractAddress: '0xb5b88135d363A99bF4664C4a1A51dF4ec3cC154A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-sfp-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['SFP', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb',
    network: 'bsc',
  },
  {
    id: 'cakev2-egld-bnb-eol',
    logo: 'bnb-pairs/EGLD-BNB.svg',
    name: 'EGLD-BNB LP',
    token: 'EGLD-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xcD68856b6E72E99b5eEaAE7d41Bb4A3b484c700D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakev2EGLD-BNB',
    earnedTokenAddress: '0x99e974465886676C871969aC57607DA4BF29e24a',
    earnContractAddress: '0x99e974465886676C871969aC57607DA4BF29e24a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-egld-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['EGLD', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/remove/BNB/0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe',
    network: 'bsc',
  },
  {
    id: 'cakev2-wsote-bnb',
    logo: 'bnb-pairs/WSOTE-BNB.svg',
    name: 'WSOTE-BNB LP',
    token: 'WSOTE-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x7653D2c31440f04d2c6520D482dC5DbD7650f70a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakev2WSOTE-BNB',
    earnedTokenAddress: '0x51a159496328F5cCec192748F135eC57E326A10B',
    earnContractAddress: '0x51a159496328F5cCec192748F135eC57E326A10B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-wsote-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['WSOTE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x541e619858737031a1244a5d0cd47e5ef480342c',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x541e619858737031a1244a5d0cd47e5ef480342c',
    network: 'bsc',
  },

  {
    id: 'cakev2-front-bnb',
    logo: 'bnb-pairs/FRONT-BNB.svg',
    name: 'FRONT-BNB LP',
    token: 'FRONT-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xC6b668548aA4A56792e8002A920d3159728121D5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakev2FRONT-BNB',
    earnedTokenAddress: '0x5C2C814107E6c0C664951bd6CF209A26eB10a98A',
    earnContractAddress: '0x5C2C814107E6c0C664951bd6CF209A26eB10a98A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-front-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['FRONT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x928e55dab735aa8260af3cedada18b5f70c72f1b',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x928e55dab735aa8260af3cedada18b5f70c72f1b',
    network: 'bsc',
  },

  {
    id: 'cakev2-btcst-bnb',
    logo: 'bnb-pairs/BTCST-BNB.svg',
    name: 'BTCST-BNB LP',
    token: 'BTCST-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xB2678C414ebC63c9CC6d1a0fC45f43E249B50fdE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakev2BTCST-BNB',
    earnedTokenAddress: '0x7ffb0415df0f77a6536aE228622e077D0D5D3de8',
    earnContractAddress: '0x7ffb0415df0f77a6536aE228622e077D0D5D3de8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-btcst-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BTCST', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x78650b139471520656b9e7aa7a5e9276814a38e9',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x78650b139471520656b9e7aa7a5e9276814a38e9',
    network: 'bsc',
  },
  {
    id: 'cakev2-ltc-bnb',
    logo: 'bnb-pairs/LTC-BNB.svg',
    name: 'LTC-BNB LP',
    token: 'LTC-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x71b01eBdDD797c8E9E0b003ea2f4FD207fBF46cC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakev2LTC-BNB',
    earnedTokenAddress: '0x5B240BB418bEcFffb6d66CdcA9dc55eCE79B3CC3',
    earnContractAddress: '0x5B240BB418bEcFffb6d66CdcA9dc55eCE79B3CC3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ltc-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['LTC', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    network: 'bsc',
  },

  {
    id: 'cakev2-ada-bnb',
    logo: 'bnb-pairs/ADA-BNB.svg',
    name: 'ADA-BNB LP',
    token: 'ADA-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x28415ff2C35b65B9E5c7de82126b4015ab9d031F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakev2ADA-BNB',
    earnedTokenAddress: '0xAb09FBFcCcc033248fC8790aaF9F2450a856e177',
    earnContractAddress: '0xAb09FBFcCcc033248fC8790aaF9F2450a856e177',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ada-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ADA', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    network: 'bsc',
  },

  {
    id: 'cakev2-dot-bnb',
    logo: 'bnb-pairs/DOT-BNB.svg',
    name: 'DOT-BNB LP',
    token: 'DOT-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakev2DOT-BNB',
    earnedTokenAddress: '0x18B12Ec521f31EaFF5BE813c3E136C75B3B1191E',
    earnContractAddress: '0x18B12Ec521f31EaFF5BE813c3E136C75B3B1191E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-dot-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['DOT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
    network: 'bsc',
  },
  {
    id: 'cakev2-zil-bnb',
    logo: 'bnb-pairs/ZIL-BNB.svg',
    name: 'ZIL-BNB LP',
    token: 'ZIL-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x6A97867a4b7Eb7646ffB1F359ad582e9903aa1C2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ZIL-BNB',
    earnedTokenAddress: '0x96DbE2a8B2a98E4aF2ecf444f95c5A8d853d69a3',
    earnContractAddress: '0x96DbE2a8B2a98E4aF2ecf444f95c5A8d853d69a3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-zil-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ZIL', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xb86abcb37c3a4b64f74f59301aff131a1becc787',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb86abcb37c3a4b64f74f59301aff131a1becc787',
    network: 'bsc',
  },

  {
    id: 'cakev2-lto-bnb',
    logo: 'bnb-pairs/LTO-BNB.svg',
    name: 'LTO-BNB LP',
    token: 'LTO-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xa5Bb44c6F5fD9B836E5a654c8AbbCCc96A15deE5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2LTO-BNB',
    earnedTokenAddress: '0xFc7423790DcEcf83a93f9D370eA589518c91cC24',
    earnContractAddress: '0xFc7423790DcEcf83a93f9D370eA589518c91cC24',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-lto-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['LTO', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd',
    network: 'bsc',
  },

  {
    id: 'cakev2-pcws-bnb',
    logo: 'bnb-pairs/pCWS-BNB.svg',
    name: 'pCWS-BNB LP',
    token: 'pCWS-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x6615CE60D71513aA4849269dD63821D324A23F8C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2pCWS-BNB',
    earnedTokenAddress: '0x15cd0fd4c1A67EADDe83654be450C0EC61769169',
    earnContractAddress: '0x15cd0fd4c1A67EADDe83654be450C0EC61769169',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-pcws-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['pCWS', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xbcf39f0edda668c58371e519af37ca705f2bfcbd',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xbcf39f0edda668c58371e519af37ca705f2bfcbd',
    network: 'bsc',
  },

  {
    id: 'cakev2-trade-bnb',
    logo: 'bnb-pairs/TRADE-BNB.svg',
    name: 'TRADE-BNB LP',
    token: 'TRADE-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x8F6baf368E7A4f6e2C9c995f22702d5e654A0237',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2TRADE-BNB',
    earnedTokenAddress: '0x770fFB3872dfE4B872491e7517f58f9dcC55aE07',
    earnContractAddress: '0x770fFB3872dfE4B872491e7517f58f9dcC55aE07',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-trade-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['TRADE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x7af173f350d916358af3e218bdf2178494beb748',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x7af173f350d916358af3e218bdf2178494beb748',
    network: 'bsc',
  },

  {
    id: 'cakev2-dusk-bnb',
    logo: 'bnb-pairs/DUSK-BNB.svg',
    name: 'DUSK-BNB LP',
    token: 'DUSK-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x678EDb8B268e73dB57b7694c163e1dc296b6e219',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2DUSK-BNB',
    earnedTokenAddress: '0x6346eF9D3d9fF022E18152e3E13E263A660bFDC5',
    earnContractAddress: '0x6346eF9D3d9fF022E18152e3E13E263A660bFDC5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-dusk-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['DUSK', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c',
    network: 'bsc',
  },

  {
    id: 'cakev2-cos-bnb',
    logo: 'bnb-pairs/COS-BNB.svg',
    name: 'COS-BNB LP',
    token: 'COS-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xe98585bBb2dc81854fF100A3d9D7B0F53E0dafEd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2COS-BNB',
    earnedTokenAddress: '0xA648630bD3C5a5FAC65Cd60BD61964f16bc00e88',
    earnContractAddress: '0xA648630bD3C5a5FAC65Cd60BD61964f16bc00e88',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-cos-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['COS', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0x96Dd399F9c3AFda1F194182F71600F1B65946501',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x96Dd399F9c3AFda1F194182F71600F1B65946501',
    network: 'bsc',
  },

  {
    id: 'cakev2-watch-bnb',
    logo: 'bnb-pairs/WATCH-BNB.svg',
    name: 'WATCH-BNB LP',
    token: 'WATCH-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x13321AcfF4A27f3d2bcA64b8bEaC6e5FdAAAf12C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2WATCH-BNB',
    earnedTokenAddress: '0x4925Ebaa523b2C2F66F980C7b8bb68207622bc3F',
    earnContractAddress: '0x4925Ebaa523b2C2F66F980C7b8bb68207622bc3F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-watch-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['WATCH', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
    network: 'bsc',
  },

  {
    id: 'cakev2-bry-bnb',
    logo: 'bnb-pairs/BRY-BNB.svg',
    name: 'BRY-BNB LP',
    token: 'BRY-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x21dD71aB78EDE3033c976948f769D506E4F489eE', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakev2BRY-BNB',
    earnedTokenAddress: '0xca2E81FAb1740C533A860935787ea5dB4bF65423',
    earnContractAddress: '0xca2E81FAb1740C533A860935787ea5dB4bF65423',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bry-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BRY', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
    network: 'bsc',
  },

  {
    id: 'cakev2-zee-bnb',
    logo: 'bnb-pairs/ZEE-BNB.svg',
    name: 'ZEE-BNB LP',
    token: 'ZEE-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x8e799cB0737525CeB8A6C6Ad07f748535fF6377B', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ZEE-BNB',
    earnedTokenAddress: '0xfeBBCb71C2ff834960289314B629941300c621B2',
    earnContractAddress: '0xfeBBCb71C2ff834960289314B629941300c621B2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-zee-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ZEE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x44754455564474a89358b2c2265883df993b12f0',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x44754455564474a89358b2c2265883df993b12f0',
    network: 'bsc',
  },

  {
    id: 'cakev2-dodo-bnb',
    logo: 'bnb-pairs/DODO-BNB.svg',
    name: 'DODO-BNB LP',
    token: 'DODO-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xA9986Fcbdb23c2E8B11AB40102990a08f8E58f06', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2DODO-BNB',
    earnedTokenAddress: '0x5F92E5845661b31bB899d4121b5a20d6b87Fa2Ca',
    earnContractAddress: '0x5F92E5845661b31bB899d4121b5a20d6b87Fa2Ca',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-dodo-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['DODO', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2',
    network: 'bsc',
  },

  {
    id: 'cakev2-swingby-bnb',
    logo: 'bnb-pairs/SWINGBY-BNB.svg',
    name: 'SWINGBY-BNB LP',
    token: 'SWINGBY-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x4Fd6D315bEf387fAD2322fbc64368fC443F0886D', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2SWINGBY-BNB',
    earnedTokenAddress: '0x0Bc697E03fc1B5aF446E7ceD159195BC2f9E1b20',
    earnContractAddress: '0x0Bc697E03fc1B5aF446E7ceD159195BC2f9E1b20',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-swingby-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['SWINGBY', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x71de20e0c4616e7fcbfdd3f875d568492cbe4739',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x71de20e0c4616e7fcbfdd3f875d568492cbe4739',
    network: 'bsc',
  },

  {
    id: 'cakev2-apys-bnb',
    logo: 'bnb-pairs/APYS-BNB.svg',
    name: 'APYS-BNB LP',
    token: 'APYS-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x510b29a93ebf098f3fC24A16541aAA0114D07056',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2APYS-BNB',
    earnedTokenAddress: '0x526c4f570e535B221C86557b8483A0F441b69259',
    earnContractAddress: '0x526c4f570e535B221C86557b8483A0F441b69259',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-apys-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['APYS', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
    network: 'bsc',
  },

  {
    id: 'cakev2-easy-bnb-eol',
    logo: 'bnb-pairs/EASY-BNB.svg',
    name: 'EASY-BNB LP',
    token: 'EASY-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x20c6De8983Fb2D641c55004646aEF40b4EA66E18',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2EASY-BNB',
    earnedTokenAddress: '0xB1254637809D455Fbc33b901894CbA735Fc5B4a1',
    earnContractAddress: '0xB1254637809D455Fbc33b901894CbA735Fc5B4a1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-easy-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['EASY', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/remove/BNB/0x7C17c8bED8d14bAccE824D020f994F4880D6Ab3B',
    network: 'bsc',
  },

  {
    id: 'cakev2-oddz-bnb',
    logo: 'bnb-pairs/ODDZ-BNB.svg',
    name: 'ODDZ-BNB LP',
    token: 'ODDZ-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x3c2c77353E2F6AC1578807b6b2336Bf3a3CbB014',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ODDZ-BNB',
    earnedTokenAddress: '0x19dA11FC81cDe70F55e673C8eec41129DAA94A20',
    earnContractAddress: '0x19dA11FC81cDe70F55e673C8eec41129DAA94A20',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-oddz-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ODDZ', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xCD40F2670CF58720b694968698A5514e924F742d',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xCD40F2670CF58720b694968698A5514e924F742d',
    network: 'bsc',
  },

  {
    id: 'cakev2-bondly-bnb',
    logo: 'bnb-pairs/BONDLY-BNB.svg',
    name: 'BONDLY-BNB LP',
    token: 'BONDLY-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xb8b4383B49d451BBeA63BC4421466E1086da6f18',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2BONDLY-BNB',
    earnedTokenAddress: '0xC452538b578226933fc5F23d6436D486964736d3',
    earnContractAddress: '0xC452538b578226933fc5F23d6436D486964736d3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bondly-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BONDLY', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x96058f8C3e16576D9BD68766f3836d9A33158f89',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x96058f8C3e16576D9BD68766f3836d9A33158f89',
    network: 'bsc',
  },

  {
    id: 'cakev2-itam-bnb',
    logo: 'bnb-pairs/ITAM-BNB.svg',
    name: 'ITAM-BNB LP',
    token: 'ITAM-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xd02DA76c813b9cd4516eD50442923E625f90228f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ITAM-BNB',
    earnedTokenAddress: '0x34753f36d69d00e2112Eb99B3F7f0FE76cC35090',
    earnContractAddress: '0x34753f36d69d00e2112Eb99B3F7f0FE76cC35090',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-itam-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ITAM', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x04C747b40Be4D535fC83D09939fb0f626F32800B',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x04C747b40Be4D535fC83D09939fb0f626F32800B',
    network: 'bsc',
  },

  {
    id: 'cakev2-arpa-bnb',
    logo: 'bnb-pairs/ARPA-BNB.svg',
    name: 'ARPA-BNB LP',
    token: 'ARPA-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x9730c791743300E9f984C9264395ce705A55Da7c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ARPA-BNB',
    earnedTokenAddress: '0x2b25fc2e80C3f4A7983306fFC72d95a553f59C9d',
    earnContractAddress: '0x2b25fc2e80C3f4A7983306fFC72d95a553f59C9d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-arpa-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ARPA', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
    network: 'bsc',
  },

  {
    id: 'cakev2-dft-bnb',
    logo: 'bnb-pairs/DFT-BNB.svg',
    name: 'DFT-BNB LP',
    token: 'DFT-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x24d3B0eD4C444A4f6882d527cBF67aDc8c026582',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2DFT-BNB',
    earnedTokenAddress: '0xa5529E47C3086D93bB59aB99ca2B20d0Ee2EFcAe',
    earnContractAddress: '0xa5529E47C3086D93bB59aB99ca2B20d0Ee2EFcAe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-dft-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['DFT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x42712dF5009c20fee340B245b510c0395896cF6e',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x42712df5009c20fee340b245b510c0395896cf6e',
    network: 'bsc',
  },

  {
    id: 'cakev2-swth-bnb',
    logo: 'bnb-pairs/SWTH-BNB.svg',
    name: 'SWTH-BNB LP',
    token: 'SWTH-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x123D475E13aA54A43a7421d94CAa4459dA021c77',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2SWTH-BNB',
    earnedTokenAddress: '0xE7703725e3B294Ef0691577461Ec957AD0e1fF81',
    earnContractAddress: '0xE7703725e3B294Ef0691577461Ec957AD0e1fF81',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-swth-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['SWTH', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
    network: 'bsc',
  },

  {
    id: 'cakev2-lien-bnb',
    logo: 'bnb-pairs/LIEN-BNB.svg',
    name: 'LIEN-BNB LP',
    token: 'LIEN-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xa4963B38b271c0D714593063497Fc786Fa4029Ce',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2LIEN-BNB',
    earnedTokenAddress: '0x19069ED39D3E0ad756eE9DF2200B42A83D1266e4',
    earnContractAddress: '0x19069ED39D3E0ad756eE9DF2200B42A83D1266e4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-lien-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['LIEN', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x5d684adaf3fcfe9cfb5cede3abf02f0cdd1012e3',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x5d684adaf3fcfe9cfb5cede3abf02f0cdd1012e3',
    network: 'bsc',
  },

  {
    id: 'cakev2-broobee-bnb',
    logo: 'bnb-pairs/bROOBEE-BNB.svg',
    name: 'bROOBEE-BNB LP',
    token: 'bROOBEE-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x950FD020F8E4B8C57285EC7020b7a204348dadFa',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2bROOBEE-BNB',
    earnedTokenAddress: '0x9292dE516411Fe2eEcB9441F9FFcf2bfdDFb0956',
    earnContractAddress: '0x9292dE516411Fe2eEcB9441F9FFcf2bfdDFb0956',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-broobee-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['bROOBEE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
    network: 'bsc',
  },

  {
    id: 'cakev2-tko-bnb',
    logo: 'bnb-pairs/TKO-BNB.svg',
    name: 'TKO-BNB LP',
    token: 'TKO-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xFFd4B200d3C77A0B691B5562D804b3bd54294e6e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2TKO-BNB',
    earnedTokenAddress: '0xEEdDe64Af1dF1b1d023e326FD914AD5491B5c0eD',
    earnContractAddress: '0xEEdDe64Af1dF1b1d023e326FD914AD5491B5c0eD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-tko-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['TKO', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x9f589e3eabe42ebC94A44727b3f3531C0c877809',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x9f589e3eabe42ebC94A44727b3f3531C0c877809',
    network: 'bsc',
  },

  {
    id: 'cakev2-tlm-bnb',
    logo: 'bnb-pairs/TLM-BNB.svg',
    name: 'TLM-BNB LP',
    token: 'TLM-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xE6b421a4408c82381b226Ab5B6F8C4b639044359',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2TLM-BNB',
    earnedTokenAddress: '0x185647c55633A5706aAA3278132537565c925078',
    earnContractAddress: '0x185647c55633A5706aAA3278132537565c925078',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-tlm-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['TLM', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x2222227E22102Fe3322098e4CBfE18cFebD57c95',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2222227E22102Fe3322098e4CBfE18cFebD57c95',
    network: 'bsc',
  },

  {
    id: 'cakev2-perl-bnb',
    logo: 'bnb-pairs/PERL-BNB.svg',
    name: 'PERL-BNB LP',
    token: 'PERL-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x61010e6CbA3b56ba47E9dFd56Da682daCFe76131',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2PERL-BNB',
    earnedTokenAddress: '0x2dc8683F752305659ff7F97A7CB4291B1c0Df37b',
    earnContractAddress: '0x2dc8683F752305659ff7F97A7CB4291B1c0Df37b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-perl-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['PERL', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x0F9E4D49f25de22c2202aF916B681FBB3790497B',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0F9E4D49f25de22c2202aF916B681FBB3790497B',
    network: 'bsc',
  },

  {
    id: 'cakev2-alpa-bnb',
    logo: 'bnb-pairs/ALPA-BNB.svg',
    name: 'ALPA-BNB LP',
    token: 'ALPA-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x4cC442220BE1cE560C1f2573f8CA8f460B3E4172',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ALPA-BNB',
    earnedTokenAddress: '0x29714444254C94061215cB36C610dEED43925F71',
    earnContractAddress: '0x29714444254C94061215cB36C610dEED43925F71',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-alpa-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ALPA', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03',
    network: 'bsc',
  },

  {
    id: 'cakev2-hzn-bnb',
    logo: 'bnb-pairs/HZN-BNB.svg',
    name: 'HZN-BNB LP',
    token: 'HZN-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xDc9a574b9B341D4a98cE29005b614e1E27430E74',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2HZN-BNB',
    earnedTokenAddress: '0xeae012C26290f668E29b276247E1A48f60379792',
    earnContractAddress: '0xeae012C26290f668E29b276247E1A48f60379792',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-hzn-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['HZN', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
    network: 'bsc',
  },

  {
    id: 'cakev2-suter-bnb',
    logo: 'bnb-pairs/SUTER-BNB.svg',
    name: 'SUTER-BNB LP',
    token: 'SUTER-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x2d5DB889392Bc3c8B023A8631ca230A033eEA1B8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2SUTER-BNB',
    earnedTokenAddress: '0x10Db0024045cDECc16b8D6581458afa4B47c0c05',
    earnContractAddress: '0x10Db0024045cDECc16b8D6581458afa4B47c0c05',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-suter-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['SUTER', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x4cfbbdfbd5bf0814472ff35c72717bd095ada055',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4cfbbdfbd5bf0814472ff35c72717bd095ada055',
    network: 'bsc',
  },

  {
    id: 'cakev2-ten-bnb',
    logo: 'bnb-pairs/TEN-BNB.svg',
    name: 'TEN-BNB LP',
    token: 'TEN-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x1B415C3ec8095AfBF9d78882b3a6263c4ad141B5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakev2TEN-BNB',
    earnedTokenAddress: '0xbE1F363921e4e83E308e6741B5470E38ed3cf6be',
    earnContractAddress: '0xbE1F363921e4e83E308e6741B5470E38ed3cf6be',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ten-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['TEN', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xdff8cb622790b7f92686c722b02cab55592f152c',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xdff8cb622790b7f92686c722b02cab55592f152c',
    network: 'bsc',
  },

  {
    id: 'cakev2-balbt-bnb',
    logo: 'bnb-pairs/BALBT-BNB.svg',
    name: 'bALBT-BNB LP',
    token: 'bALBT-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x24EB18bA412701f278B172ef96697c4622b19da6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakev2BALBT-BNB',
    earnedTokenAddress: '0x32D27f7dF713DB608DfBd593041B1303E25DA2d2',
    earnContractAddress: '0x32D27f7dF713DB608DfBd593041B1303E25DA2d2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-balbt-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['bALBT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x72faa679e1008ad8382959ff48e392042a8b06f7',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x72faa679e1008ad8382959ff48e392042a8b06f7',
    network: 'bsc',
  },

  {
    id: 'cakev2-bscx-bnb',
    logo: 'bnb-pairs/BSCX-BNB.svg',
    name: 'BSCX-BNB LP',
    token: 'BSCX-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x47C42b0A056A9C6e9C65b9Ef79020Af518e767A5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakev2BSCX-BNB',
    earnedTokenAddress: '0x3f8C3120f57b9552e33097B83dFDdAB1539bAd47',
    earnContractAddress: '0x3f8C3120f57b9552e33097B83dFDdAB1539bAd47',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-bscx-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BSCX', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x5ac52ee5b2a633895292ff6d8a89bb9190451587',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x5ac52ee5b2a633895292ff6d8a89bb9190451587',
    network: 'bsc',
  },
  {
    id: 'cakev2-ctk-bnb',
    logo: 'bnb-pairs/CTK-BNB.svg',
    name: 'CTK-BNB LP',
    token: 'CTK-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x460b4193Ec4C1a17372Aa5FDcd44c520ba658646',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakev2CTK-BNB',
    earnedTokenAddress: '0xecdc77241C58ff04fB0Bc259EA4B6d67edD43920',
    earnContractAddress: '0xecdc77241C58ff04fB0Bc259EA4B6d67edD43920',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ctk-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['CTK', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929',
    network: 'bsc',
  },

  {
    id: 'bunny-bunny-eol',
    logo: 'single-assets/BUNNY.png',
    name: 'BUNNY',
    token: 'BUNNY',
    tokenDescription: 'Pancakebunny',
    tokenAddress: '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBUNNY',
    earnedTokenAddress: '0x7f56672fCB5D1d1760511803A0a54c4d1e911dFD',
    earnContractAddress: '0x7f56672fCB5D1d1760511803A0a54c4d1e911dFD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BUNNY',
    oraclePrice: 0,
    status: 'refund',
    platform: 'Pancakebunny',
    refundContractAddress: '0xeD073c1BeC09Bb1963469910340BD8d0B2CC0413',
    assets: ['BUNNY'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl: 'https://app.1inch.io/#/56/swap/BNB/BUNNY',
    network: 'bsc',
  },

  {
    id: 'auto-eth-v2',
    logo: 'single-assets/ETH.png',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoETHv2',
    earnedTokenAddress: '0x0F47eaae1EC5fD11067455210c494a7828024524',
    earnContractAddress: '0x0F47eaae1EC5fD11067455210c494a7828024524',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['ETH'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'belt-venus-blp',
    logo: 'uncategorized/BELT-VENUSBLP.png',
    name: 'BUSD/USDT/USDC/DAI',
    token: 'VENUS BLP',
    tokenDescription: 'Belt',
    tokenAddress: '0x86aFa7ff694Ab8C985b79733745662760e454169',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeltVenusBLP',
    earnedTokenAddress: '0xb35Dc0b5eFd7c75590a9da55BE46d968c5804e24',
    earnContractAddress: '0xb35Dc0b5eFd7c75590a9da55BE46d968c5804e24',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'belt-venus-blp',
    oraclePrice: 0,
    status: 'deposits-paused',
    platform: 'Belt',
    assets: ['USDT', 'BUSD', 'USDC', 'DAI', 'VENUS BLP'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'ALGO_STABLE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Vamp',
    vamp: 'Autofarm',
    callFee: 0.5,
    addLiquidityUrl: 'https://belt.fi/',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
    network: 'bsc',
  },
  {
    id: 'mdex-bsc-mdx-bnb',
    logo: 'bnb-pairs/MDX-BNB.png',
    name: 'MDX-BNB LP',
    token: 'MDX-BNB LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0xAf9Aa53146C5752BF6068A84B970E9fBB22a87bc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexMDX-BNB',
    earnedTokenAddress: '0x31e2d52A04FEb223109506E4c6f195C0cF16b98A',
    earnContractAddress: '0x31e2d52A04FEb223109506E4c6f195C0cF16b98A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-bsc-mdx-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Mdex',
    assets: ['MDX', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl: 'https://bsc.mdex.com/#/add/BNB/0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    buyTokenUrl:
      'https://bsc.mdex.com/#/swap?outputCurrency=0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
    network: 'bsc',
  },
  {
    id: 'auto-btc-v2',
    logo: 'single-assets/BTCB.svg',
    name: 'BTCB',
    token: 'BTCB',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBTCBv2',
    earnedTokenAddress: '0x0845813938515FE56730c124Ab8CA2DF58B01463',
    earnContractAddress: '0x0845813938515FE56730c124Ab8CA2DF58B01463',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BTCB',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['BTCB'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'cake-broobee-bnb-eol',
    logo: 'bnb-pairs/bROOBEE-BNB.svg',
    name: 'bROOBEE-BNB LP',
    token: 'bROOBEE-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x9e6f9f3382f9edC683203b528222c554c92382C2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakebROOBEE-BNB',
    earnedTokenAddress: '0xa5F6387fB58AC5268F130bFc2d0A5e53343fB798',
    earnContractAddress: '0xa5F6387fB58AC5268F130bFc2d0A5e53343fB798',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-broobee-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['bROOBEE', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
    network: 'bsc',
  },

  {
    id: 'banana-ada-eth',
    logo: 'eth-pairs/ADA-ETH.svg',
    name: 'ADA-ETH LP',
    token: 'ADA-ETH LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x61FE209E404166a53Cc627d0ae30A65606315dA7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeADA-ETH',
    earnedTokenAddress: '0xd6D138fb65c2a68E728D70EfbAA54c794B73B6A0',
    earnContractAddress: '0xd6D138fb65c2a68E728D70EfbAA54c794B73B6A0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-ada-eth',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['ADA', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    network: 'bsc',
  },

  {
    id: 'banana-iota-bnb',
    logo: 'bnb-pairs/IOTA-BNB.svg',
    name: 'IOTA-BNB LP',
    token: 'IOTA-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x0D70924695B6Ae496F0A74A36bf79d47307dD519',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeIOTA-BNB',
    earnedTokenAddress: '0x5705Ca9E59485E77240a32AAd50a64bB72996e02',
    earnContractAddress: '0x5705Ca9E59485E77240a32AAd50a64bB72996e02',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-iota-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['IOTA', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0xd944f1D1e9d5f9Bb90b62f9D45e447D989580782',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&outputCurrency=0xd944f1D1e9d5f9Bb90b62f9D45e447D989580782',
    network: 'bsc',
  },

  {
    id: 'banana-bat-bnb',
    logo: 'bnb-pairs/BAT-BNB.svg',
    name: 'BAT-BNB LP',
    token: 'BAT-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x6e425B4fc4Efd070Dc0deF1654a17946C7e6b3C4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBAT-BNB',
    earnedTokenAddress: '0xec10AaC4A445d28Eebd50F908cfc69816C8E9d7d',
    earnContractAddress: '0xec10AaC4A445d28Eebd50F908cfc69816C8E9d7d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-bat-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BAT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x101d82428437127bF1608F699CD651e6Abf9766E',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&outputCurrency=0x101d82428437127bF1608F699CD651e6Abf9766E',
    network: 'bsc',
  },

  {
    id: 'ellipsis-renbtc',
    logo: 'uncategorized/renBTC-BTC.png',
    name: 'renBTC/BTCB',
    token: 'renBTC-BTCB EPS LP',
    tokenDescription: 'Ellipsis',
    tokenAddress: '0x2a435Ecb3fcC0E316492Dc1cdd62d0F189be5640',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooEllipsisRenBTC',
    earnedTokenAddress: '0x24AE9e5424575690aCab61a384B6A76d69F4f89c',
    earnContractAddress: '0x24AE9e5424575690aCab61a384B6A76d69F4f89c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ellipsis-renbtc',
    oraclePrice: 0,
    status: 'active',
    platform: 'Ellipsis',
    assets: ['renBTC', 'BTCB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl: 'https://ellipsis.finance/ren/deposit',
    network: 'bsc',
  },
  {
    id: 'ellipsis-fusdt-3eps',
    logo: 'uncategorized/EPS-FUSDT.png',
    name: 'fUSDT/BUSD/USDT/USDC',
    token: 'EPS fUSDT',
    tokenDescription: 'Ellipsis',
    tokenAddress: '0x373410A99B64B089DFE16F1088526D399252dacE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooEllipsisFUSDT',
    earnedTokenAddress: '0x8D3B7a0b20d490cbDACC2c555c05b7132B856e4b',
    earnContractAddress: '0x8D3B7a0b20d490cbDACC2c555c05b7132B856e4b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ellipsis-fusdt-3eps',
    oraclePrice: 0,
    status: 'active',
    platform: 'Ellipsis',
    assets: ['fUSDT', 'USDT', 'BUSD', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    callFee: 0.5,
    addLiquidityUrl: 'https://ellipsis.finance/fusdt/deposit',
    network: 'bsc',
  },

  {
    id: 'cake-apys-bnb-eol',
    logo: 'bnb-pairs/APYS-BNB.svg',
    name: 'APYS-BNB LP',
    token: 'APYS-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xE5783Cc9dFb3E7e474B81B07369a008e80F1cEdb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeAPYS-BNB',
    earnedTokenAddress: '0xc3991d99a73eD1Fd9d28cd4623DD72732745963b',
    earnContractAddress: '0xc3991d99a73eD1Fd9d28cd4623DD72732745963b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-apys-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['APYS', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
    network: 'bsc',
  },

  {
    id: 'auto-eth-beth',
    logo: 'eth-pairs/BETH-ETH.svg',
    name: 'BETH-ETH LP',
    token: 'BETH-ETH Mdex LP',
    tokenDescription: 'Mdex (Autofarm)',
    tokenAddress: '0x3fe3DeE31cC404Aa4C8de6A6a3cbBcb60358FcB0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoMdexBETH-ETH',
    earnedTokenAddress: '0xad777a366D5aD4A728A03C2CC61a3c3Ea8935BBB',
    earnContractAddress: '0xad777a366D5aD4A728A03C2CC61a3c3Ea8935BBB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-eth-beth',
    oraclePrice: 0,
    status: 'active',
    platform: 'Autofarm',
    assets: ['BETH', 'ETH'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Vamp',
    vamp: 'Autofarm',
    callFee: 0.5,
    depositFee: '<0.1%',
    addLiquidityUrl:
      'https://bsc.mdex.com/#/add/0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    buyTokenUrl:
      'https://bsc.mdex.com/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=0x250632378e573c6be1ac2f97fcdf00515d0aa91b',
    network: 'bsc',
  },

  {
    id: 'auto-eth-usdt',
    logo: 'usdt-pairs/ETH-USDT.svg',
    name: 'ETH-USDT LP',
    token: 'ETH-USDT Mdex LP',
    tokenDescription: 'Mdex (Autofarm)',
    tokenAddress: '0x0FB881c078434b1C0E4d0B64d8c64d12078b7Ce2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoMdexETH-USDT',
    earnedTokenAddress: '0x68c39886eA459b4a59758F1e94c3d20C93d47133',
    earnContractAddress: '0x68c39886eA459b4a59758F1e94c3d20C93d47133',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-eth-usdt',
    oraclePrice: 0,
    status: 'active',
    platform: 'Autofarm',
    assets: ['ETH', 'USDT'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Vamp',
    vamp: 'Autofarm',
    callFee: 0.5,
    depositFee: '<0.1%',
    addLiquidityUrl:
      'https://bsc.mdex.com/#/add/0x55d398326f99059fF775485246999027B3197955/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    buyTokenUrl:
      'https://bsc.mdex.com/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=0x55d398326f99059ff775485246999027b3197955',
    network: 'bsc',
  },

  {
    id: 'auto-btcb-usdt',
    logo: 'usdt-pairs/BTCB-USDT.svg',
    name: 'BTCB-USDT LP',
    token: 'BTCB-USDT Mdex LP',
    tokenDescription: 'Mdex (Autofarm)',
    tokenAddress: '0xda28Eb7ABa389C1Ea226A420bCE04Cb565Aafb85',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoMdexBTCB-USDT',
    earnedTokenAddress: '0xE5e79043eC57b12F2d15d4a230ED9C7d732Ed93A',
    earnContractAddress: '0xE5e79043eC57b12F2d15d4a230ED9C7d732Ed93A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-btcb-usdt',
    oraclePrice: 0,
    status: 'active',
    platform: 'Autofarm',
    assets: ['BTCB', 'USDT'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Vamp',
    vamp: 'Autofarm',
    callFee: 0.5,
    depositFee: '<0.1%',
    addLiquidityUrl:
      'https://bsc.mdex.com/#/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x55d398326f99059fF775485246999027B3197955',
    buyTokenUrl:
      'https://bsc.mdex.com/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    network: 'bsc',
  },

  {
    id: 'auto-btcb-eth',
    logo: 'eth-pairs/BTCB-ETH.svg',
    name: 'BTCB-ETH LP',
    token: 'BTCB-ETH Mdex LP',
    tokenDescription: 'Mdex (Autofarm)',
    tokenAddress: '0x577d005912C49B1679B4c21E334FdB650E92C077',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoMdexBTCB-ETH',
    earnedTokenAddress: '0x31e77776b924Cd5f0E624771C9B3d2bD6B9c919E',
    earnContractAddress: '0x31e77776b924Cd5f0E624771C9B3d2bD6B9c919E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-btcb-eth',
    oraclePrice: 0,
    status: 'active',
    platform: 'Autofarm',
    assets: ['BTCB', 'ETH'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Vamp',
    vamp: 'Autofarm',
    callFee: 0.5,
    depositFee: '<0.1%',
    addLiquidityUrl:
      'https://bsc.mdex.com/#/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    buyTokenUrl:
      'https://bsc.mdex.com/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    network: 'bsc',
  },

  {
    id: 'auto-usdt-busd',
    logo: 'busd-pairs/USDT-BUSD.png',
    name: 'USDT-BUSD LP',
    token: 'USDT-BUSD Mdex LP',
    tokenDescription: 'Mdex (Autofarm)',
    tokenAddress: '0x62c1dEC1fF328DCdC157Ae0068Bb21aF3967aCd9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoMdexUSDT-BUSD',
    earnedTokenAddress: '0x17720F863DA01Bc9e266e4eE872E3c98fA1FeAA8',
    earnContractAddress: '0x17720F863DA01Bc9e266e4eE872E3c98fA1FeAA8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-busd-usdt',
    oraclePrice: 0,
    status: 'active',
    platform: 'Autofarm',
    assets: ['USDT', 'BUSD'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Vamp',
    vamp: 'Autofarm',
    callFee: 0.5,
    depositFee: '<0.1%',
    addLiquidityUrl:
      'https://bsc.mdex.com/#/add/0x55d398326f99059fF775485246999027B3197955/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://bsc.mdex.com/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
    network: 'bsc',
  },

  {
    id: 'mash-mash-busd',
    logo: 'degens/mash-busd.png',
    name: 'MASH-BUSD LP',
    token: 'MASH-BUSD LP',
    tokenDescription: 'Marshmallow',
    tokenAddress: '0x87C182EDB12f74d561519AB586205fE6CD75363a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMashMASH-BUSD',
    earnedTokenAddress: '0x8D6130b05c59bf8dCCde1921D40979750f5685E7',
    earnContractAddress: '0x8D6130b05c59bf8dCCde1921D40979750f5685E7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mash-mash-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['MASH', 'BUSD'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.marshmallowdefi.com/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x787732f27D18495494cea3792ed7946BbCFF8db2',
    buyTokenUrl:
      'https://exchange.marshmallowdefi.com/#/swap?outputCurrency=0x787732f27D18495494cea3792ed7946BbCFF8db2',
    network: 'bsc',
  },
  {
    id: 'mash-mash-bnb',
    logo: 'degens/mash-bnb.png',
    name: 'MASH-BNB LP',
    token: 'MASH-BNB LP',
    tokenDescription: 'Marshmallow',
    tokenAddress: '0x7621886AC71e985DBea4f3f563BBB5a7865876A8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMashMASH-BNB',
    earnedTokenAddress: '0x45e13311ee99f040bF9a1207eb864Aae5AEa9119',
    earnContractAddress: '0x45e13311ee99f040bF9a1207eb864Aae5AEa9119',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mash-mash-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['MASH', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.marshmallowdefi.com/#/add/ETH/0x787732f27D18495494cea3792ed7946BbCFF8db2',
    buyTokenUrl:
      'https://exchange.marshmallowdefi.com/#/swap?outputCurrency=0x787732f27D18495494cea3792ed7946BbCFF8db2',
    network: 'bsc',
  },
  {
    id: 'mash-mash',
    logo: 'degens/mash.png',
    name: 'MASH',
    token: 'MASH',
    tokenDescription: 'Marshmallow',
    tokenAddress: '0x787732f27D18495494cea3792ed7946BbCFF8db2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMashMASH',
    earnedTokenAddress: '0xE76381E4fd8C4492C2e61000D123AE2Ca3BDa41C',
    earnContractAddress: '0xE76381E4fd8C4492C2e61000D123AE2Ca3BDa41C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'MASH',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['MASH'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.marshmallowdefi.com/#/swap?outputCurrency=0x787732f27D18495494cea3792ed7946BbCFF8db2',
    network: 'bsc',
  },

  {
    id: 'mdex-bsc-mdx-busd',
    logo: 'busd-pairs/MDX-BUSD.png',
    name: 'MDX-BUSD LP',
    token: 'MDX-BUSD LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x223740a259E461aBeE12D84A9FFF5Da69Ff071dD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexMDX-BUSD',
    earnedTokenAddress: '0x38507dAEe5F1E6c397d1767B5202931f12feef74',
    earnContractAddress: '0x38507dAEe5F1E6c397d1767B5202931f12feef74',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-bsc-mdx-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Mdex',
    assets: ['MDX', 'BUSD'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://bsc.mdex.com/#/add/0x9C65AB58d8d978DB963e63f2bfB7121627e3a739/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://bsc.mdex.com/#/swap?outputCurrency=0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
    network: 'bsc',
  },

  {
    id: 'palm-palm',
    logo: 'degens/PALM.png',
    name: 'PALM',
    token: 'PALM',
    tokenDescription: 'YieldBay',
    tokenAddress: '0x9768E5b2d8e761905BC81Dfc554f9437A46CdCC6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPalmPALM',
    earnedTokenAddress: '0xFAE7E8b7AB3bd583dEa936E829F1132909A6C6cD',
    earnContractAddress: '0xFAE7E8b7AB3bd583dEa936E829F1132909A6C6cD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'PALM',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['PALM'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'PLATFORM_NEW',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.yieldbay.finance/#/swap?outputCurrency=0x9768e5b2d8e761905bc81dfc554f9437a46cdcc6',
    network: 'bsc',
  },

  {
    id: 'banana-bifi-bnb',
    logo: 'degens/BIFI-BNB-banana.svg',
    name: 'BIFI-BNB LP',
    token: 'BIFI-BNB BLP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xDDd3f9d5Bd347c55D18752c0C2075698EC657750',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBIFI-BNB',
    earnedTokenAddress: '0x6ebA4A31c46937B42E66f44a0A1165a08495a38F',
    earnContractAddress: '0x6ebA4A31c46937B42E66f44a0A1165a08495a38F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-bifi-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BIFI', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    network: 'bsc',
  },

  {
    id: 'cake-hoo-busd-eol',
    logo: 'busd-pairs/HOO-BUSD.svg',
    name: 'HOO-BUSD LP',
    token: 'HOO-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x3C9E55EdBD937ae0aD8C084a1A8302110612A371',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeHOO-BUSD',
    earnedTokenAddress: '0x9C21d39c1e790A966c81EBf5Db4FE5e8C564d1bF',
    earnContractAddress: '0x9C21d39c1e790A966c81EBf5Db4FE5e8C564d1bF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-hoo-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['HOO', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xE1d1F66215998786110Ba0102ef558b22224C016',
    network: 'bsc',
  },
  {
    id: 'cake-itam-bnb-eol',
    logo: 'bnb-pairs/ITAM-BNB.svg',
    name: 'ITAM-BNB LP',
    token: 'ITAM-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xCdC53345192D0e31eEAD03D7E9e008Ee659FAEbE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeITAM-BNB',
    earnedTokenAddress: '0x4dD62Fc76fD57175151777e845dfe9E981b87aE7',
    earnContractAddress: '0x4dD62Fc76fD57175151777e845dfe9E981b87aE7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-itam-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['ITAM', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x04C747b40Be4D535fC83D09939fb0f626F32800B',
    network: 'bsc',
  },

  {
    id: 'cake-arpa-bnb-eol',
    logo: 'bnb-pairs/ARPA-BNB.svg',
    name: 'ARPA-BNB LP',
    token: 'ARPA-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xFB29fCa952b478dDCb8A43f57176161e498225b1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeARPA-BNB',
    earnedTokenAddress: '0x6E1D34Bac5d49E9b488D8eadFCB632953263F2eA',
    earnContractAddress: '0x6E1D34Bac5d49E9b488D8eadFCB632953263F2eA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-arpa-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['ARPA', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
    network: 'bsc',
  },

  {
    id: 'mdex-xvs-eol',
    logo: 'single-assets/XVS.png',
    name: 'XVS',
    token: 'XVS',
    tokenDescription: 'Mdex',
    tokenAddress: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexXVS',
    earnedTokenAddress: '0x93aBCe226ac5082aD8702681354C48E739eEFDd5',
    earnContractAddress: '0x93aBCe226ac5082aD8702681354C48E739eEFDd5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'XVS',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Mdex',
    assets: ['XVS'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },
  {
    id: 'mdex-uni-eol',
    logo: 'single-assets/UNI.png',
    name: 'UNI',
    token: 'UNI',
    tokenDescription: 'Mdex',
    tokenAddress: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexUNI',
    earnedTokenAddress: '0xC57869A0bDeF07bf4E9Ffdd598Ffc235248987d4',
    earnContractAddress: '0xC57869A0bDeF07bf4E9Ffdd598Ffc235248987d4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'UNI',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Mdex',
    assets: ['UNI'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'auto-sxp-v2',
    logo: 'single-assets/SXP.svg',
    name: 'SXP',
    token: 'SXP',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoSXPv2',
    earnedTokenAddress: '0xbB7Ed513e421ad454191790199E4731757545793',
    earnContractAddress: '0xbB7Ed513e421ad454191790199E4731757545793',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'SXP',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['SXP'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },
  {
    id: 'auto-ada-v2',
    logo: 'single-assets/ADA.svg',
    name: 'ADA',
    token: 'ADA',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoADAv2',
    earnedTokenAddress: '0x8Ba14664DFae93bb175296CD43eB52a23Ba576f5',
    earnContractAddress: '0x8Ba14664DFae93bb175296CD43eB52a23Ba576f5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ADA',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['ADA'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },
  {
    id: 'auto-wbnb-v2',
    logo: 'single-assets/WBNB.svg',
    name: 'WBNB',
    token: 'WBNB',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoWbnbFixed',
    earnedTokenAddress: '0x5B06aA1ebd2e15bC6001076355E5B4C39Cbc83F3',
    earnContractAddress: '0x5B06aA1ebd2e15bC6001076355E5B4C39Cbc83F3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBNB',
    oraclePrice: 0,
    status: 'deposits-paused',
    platform: 'Autofarm',
    assets: ['BNB'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    network: 'bsc',
  },
  {
    id: 'auto-busd-v2',
    logo: 'single-assets/BUSD.svg',
    name: 'BUSD',
    token: 'BUSD',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBUSDv2',
    earnedTokenAddress: '0x4533B96E7737D2158a0DFf9a3a2A97f4419ef646',
    earnContractAddress: '0x4533B96E7737D2158a0DFf9a3a2A97f4419ef646',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BUSD',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['BUSD'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },
  {
    id: 'auto-usdt-v2',
    logo: 'single-assets/USDT.svg',
    name: 'USDT',
    token: 'USDT',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoUSDTv2',
    earnedTokenAddress: '0xb0C71ae9a71bbf03BE6F33A29a71C485472f5a1F',
    earnContractAddress: '0xb0C71ae9a71bbf03BE6F33A29a71C485472f5a1F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['USDT'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },
  {
    id: 'auto-usdc-v2',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoUSDCv2',
    earnedTokenAddress: '0x5d931BBcD7010935103AC1FDb8B43625A7CB67A4',
    earnContractAddress: '0x5d931BBcD7010935103AC1FDb8B43625A7CB67A4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['USDC'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'mdex-inj-eol',
    logo: 'single-assets/INJ.png',
    name: 'INJ',
    token: 'INJ',
    tokenDescription: 'Mdex',
    tokenAddress: '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexINJ',
    earnedTokenAddress: '0x5212d0e82CBAD96541454E5227f273Abff986F78',
    earnContractAddress: '0x5212d0e82CBAD96541454E5227f273Abff986F78',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'INJ',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Mdex',
    assets: ['INJ'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'blizzard-xblzd-bnb',
    logo: 'degens/BLZD-BNB.png',
    name: 'xBLZD-BNB LP',
    token: 'xBLZD-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xD9002B7E7d63A71F04a16840DA028e1cd534889D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzardxBLZD-BNBV2',
    earnedTokenAddress: '0x415b51780f4B056C5b1b3CB5fab9366e00859175',
    earnContractAddress: '0x415b51780f4B056C5b1b3CB5fab9366e00859175',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'blizzard-xblzd-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['xBLZD', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 1,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
    network: 'bsc',
  },

  {
    id: 'blizzard-xblzd-busd',
    logo: 'degens/BLZD-BUSD.png',
    name: 'xBLZD-BUSD LP',
    token: 'xBLZD-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xeDCd99aD43C6911D37780158370ec1913Ee9e7D3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzardxBLZD-BUSDV2',
    earnedTokenAddress: '0x17436A6D0ACb7f31D62C7484E5C77eC9A6122837',
    earnContractAddress: '0x17436A6D0ACb7f31D62C7484E5C77eC9A6122837',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'blizzard-xblzd-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['xBLZD', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 1,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
    network: 'bsc',
  },

  {
    id: 'blizzard-xblzd-bnb-old-eol',
    logo: 'degens/BLZD-BNB.png',
    name: 'xBLZD-BNB LP',
    token: 'xBLZD-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xfC207DB720851f52545229E406068b205E02B952',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzardxBLZD-BNB',
    earnedTokenAddress: '0xF7E3273930D8ffdb967BD5B4afe67aa195DF7347',
    earnContractAddress: '0xF7E3273930D8ffdb967BD5B4afe67aa195DF7347',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'blizzard-xblzd-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['xBLZD', 'BNB'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
    network: 'bsc',
  },

  {
    id: 'blizzard-xblzd-busd-old-eol',
    logo: 'degens/BLZD-BUSD.png',
    name: 'xBLZD-BUSD LP',
    token: 'xBLZD-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x33e75f29D52a8BcfBC54a79ED6eF9482b2c035a2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzardxBLZD-BUSD',
    earnedTokenAddress: '0xE4012400f60ffae2584F9D9Ee61Ca65B31A24c7F',
    earnContractAddress: '0xE4012400f60ffae2584F9D9Ee61Ca65B31A24c7F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'blizzard-xblzd-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['xBLZD', 'BUSD'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
    network: 'bsc',
  },

  {
    id: 'blizzard-xblzd',
    logo: 'degens/BLZD.png',
    name: 'xBLZD',
    token: 'xBLZD',
    tokenDescription: 'Blizzard',
    tokenAddress: '0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzardxBLZD',
    earnedTokenAddress: '0xf69bA3131029173Ca97aa43400B10689f5C23f59',
    earnContractAddress: '0xf69bA3131029173Ca97aa43400B10689f5C23f59',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'xBLZD',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['xBLZD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
    network: 'bsc',
  },

  {
    id: 'blizzard-blzd-bnb-eol',
    logo: 'degens/BLZD-BNB.png',
    name: 'BLZD-BNB LP',
    token: 'BLZD-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x27A5a5c1fF96447F2a0c4baDcF26E7c65C040E3C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzardBLZD-BNB',
    earnedTokenAddress: '0x57e6f78a7e644b8532d8c4f3A354c921709Aa897',
    earnContractAddress: '0x57e6f78a7e644b8532d8c4f3A354c921709Aa897',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'blizzard-blzd-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BLZD', 'BNB'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x57067A6BD75c0E95a6A5f158455926e43E79BeB0',
    network: 'bsc',
  },

  {
    id: 'blizzard-blzd-busd-eol',
    logo: 'degens/BLZD-BUSD.png',
    name: 'BLZD-BUSD LP',
    token: 'BLZD-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xE9C53B5Ab0C9cDBd72A03151a628863C28c55A6A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzardBLZD-BUSD',
    earnedTokenAddress: '0x1EdfEc6849C54Ee9187539516C6a67ADAcBf9704',
    earnContractAddress: '0x1EdfEc6849C54Ee9187539516C6a67ADAcBf9704',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'blizzard-blzd-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BLZD', 'BUSD'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x57067A6BD75c0E95a6A5f158455926e43E79BeB0',
    network: 'bsc',
  },

  {
    id: 'blizzard-blzd',
    logo: 'degens/BLZD.png',
    name: 'BLZD',
    token: 'BLZD',
    tokenDescription: 'Blizzard',
    tokenAddress: '0x57067A6BD75c0E95a6A5f158455926e43E79BeB0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzardBLZD',
    earnedTokenAddress: '0x580b899D8d856DFe3da7895466F779F1A509D2Fd',
    earnContractAddress: '0x580b899D8d856DFe3da7895466F779F1A509D2Fd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BLZD',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BLZD'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x57067A6BD75c0E95a6A5f158455926e43E79BeB0',
    network: 'bsc',
  },

  {
    id: 'auto-cake-bnb-eol',
    logo: 'bnb-pairs/CAKE-BNB.svg',
    name: 'CAKE-BNB LP',
    token: 'CAKE-BNB LP',
    tokenDescription: 'PancakeSwap (Autofarm)',
    tokenAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoCAKE-BNB',
    earnedTokenAddress: '0x5DA77c7DBF6dBa1155CF343db06a7D79aaaad9Ee',
    earnContractAddress: '0x5DA77c7DBF6dBa1155CF343db06a7D79aaaad9Ee',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-cake-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['CAKE', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    network: 'bsc',
  },

  {
    id: 'mdex-bsc-eth-usdt',
    logo: 'uncategorized/ETH-USDT.png',
    name: 'ETH-USDT LP',
    token: 'ETH-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x0FB881c078434b1C0E4d0B64d8c64d12078b7Ce2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexETH-USDT',
    earnedTokenAddress: '0x2C74A61556AfF02Be19B3d7a746ba75A38210fd3',
    earnContractAddress: '0x2C74A61556AfF02Be19B3d7a746ba75A38210fd3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-bsc-eth-usdt',
    oraclePrice: 0,
    status: 'active',
    platform: 'Mdex',
    assets: ['ETH', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://bsc.mdex.com/#/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x55d398326f99059fF775485246999027B3197955',
    network: 'bsc',
  },

  {
    id: 'auto-auto-bnb',
    logo: 'bnb-pairs/AUTO-BNB.png',
    name: 'AUTO-BNB LP',
    token: 'AUTO-BNB LP',
    tokenDescription: 'PancakeSwap (Autofarm)',
    tokenAddress: '0x4d0228EBEB39f6d2f29bA528e2d15Fc9121Ead56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoAUTO-BNB',
    earnedTokenAddress: '0x6Cb6D451e7E7ca5d5F73715D6B5dfc55EfbC1f82',
    earnContractAddress: '0x6Cb6D451e7E7ca5d5F73715D6B5dfc55EfbC1f82',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-auto-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Autofarm',
    assets: ['AUTO', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xa184088a740c695E156F91f5cC086a06bb78b827',
    network: 'bsc',
  },

  {
    id: 'typh-typh-bnb',
    logo: 'bnb-pairs/TYPH-BNB.png',
    name: 'TYPH-BNB LP',
    token: 'TYPH-BNB LP',
    tokenDescription: 'PancakeSwap (Typhoon)',
    tokenAddress: '0xc9299FE3656d2d1BE9F5f05D046c33b88BB762cA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTyphTYPH-BNB',
    earnedTokenAddress: '0xFC5C3411d4415E4fDff8f842F1Ae109350e61249',
    earnContractAddress: '0xFC5C3411d4415E4fDff8f842F1Ae109350e61249',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'typh-typh-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['TYPH', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x4090e535f2e251f5f88518998b18b54d26b3b07c',
    network: 'bsc',
  },

  {
    id: 'biti-biti-bnb',
    logo: 'bnb-pairs/BITI-BNB.png',
    name: 'BITI-BNB LP',
    token: 'BITI-BNB LP',
    tokenDescription: 'PancakeSwap (Biti)',
    tokenAddress: '0xBE105c43Fe09E0BF1697f079ed09E1A2d956e602',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBitiBITI-BNB',
    earnedTokenAddress: '0x554a710F4b5A354C991da0F19CC4D14E94493da1',
    earnContractAddress: '0x554a710F4b5A354C991da0F19CC4D14E94493da1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'biti-biti-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BITI', 'BNB'],
    risks: [],
    callFee: 0.25,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xA25Dab5B75aC0E0738E58E49734295baD43d73F1',
    network: 'bsc',
  },

  {
    id: 'palm-palm-busd',
    logo: 'degens/palm-busd.png',
    name: 'PALM-BUSD LP',
    token: 'PALM-BUSD LP',
    tokenDescription: 'YieldBay',
    tokenAddress: '0x02600c49c53147ad6F090Ee3805D5705074c289d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPalmPALM-BUSD',
    earnedTokenAddress: '0x017443Dfc7A70A9d4D5cbCb966E95222955A6a7d',
    earnContractAddress: '0x017443Dfc7A70A9d4D5cbCb966E95222955A6a7d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'palm-palm-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'YieldBay',
    assets: ['PALM', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_NEW',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.yieldbay.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x9768E5b2d8e761905BC81Dfc554f9437A46CdCC6',
    buyTokenUrl:
      'https://exchange.yieldbay.finance/#/swap?outputCurrency=0x9768e5b2d8e761905bc81dfc554f9437a46cdcc6',
    network: 'bsc',
  },
  {
    id: 'palm-palm-bnb',
    logo: 'degens/palm-bnb.png',
    name: 'PALM-BNB LP',
    token: 'PALM-BNB LP',
    tokenDescription: 'YieldBay',
    tokenAddress: '0x6Fc4c985559233D0b69410316911E5C6e096cCB6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPalmPALM-BNB',
    earnedTokenAddress: '0xA85D1879E78C1875479635fda2daD25C9743dc39',
    earnContractAddress: '0xA85D1879E78C1875479635fda2daD25C9743dc39',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'palm-palm-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'YieldBay',
    assets: ['PALM', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_NEW',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.yieldbay.finance/#/add/ETH/0x9768E5b2d8e761905BC81Dfc554f9437A46CdCC6',
    buyTokenUrl:
      'https://exchange.yieldbay.finance/#/swap?outputCurrency=0x9768e5b2d8e761905bc81dfc554f9437a46cdcc6',
    network: 'bsc',
  },

  {
    id: 'swamp-cake-eol',
    logo: 'single-assets/SMART.png',
    name: 'CAKE',
    token: 'CAKE',
    tokenDescription: 'PancakeSwap (Swamp)',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSwampyCAKE',
    earnedTokenAddress: '0x4d1A2b3119895d887b87509693338b86730bCE06',
    earnContractAddress: '0x4d1A2b3119895d887b87509693338b86730bCE06',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Swamp',
    assets: ['CAKE'],
    risks: [],
    callFee: 0.5,
    depositFee: '<0.1%',
    withdrawalFee: '0.0%',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    network: 'bsc',
  },

  {
    id: 'swamp-swamp',
    logo: 'single-assets/SWAMP.svg',
    name: 'SWAMP',
    token: 'SWAMP',
    tokenDescription: 'Swamp',
    tokenAddress: '0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSwampySWAMP',
    earnedTokenAddress: '0x06C9e0b65ff4e02940c5b0f50b27D799622b2b39',
    earnContractAddress: '0x06C9e0b65ff4e02940c5b0f50b27D799622b2b39',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'SWAMP',
    oraclePrice: 0,
    status: 'active',
    platform: 'Swamp',
    assets: ['SWAMP'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    depositFee: '<0.1%',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
    network: 'bsc',
  },

  {
    id: 'auto-cake-eol',
    logo: 'single-assets/SMART.png',
    name: 'CAKE',
    token: 'CAKE',
    tokenDescription: 'PancakeSwap (Autofarm)',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoCakeFixed',
    earnedTokenAddress: '0xe0B473c0dD6D7Fea5B395c3Ce7ffd4FEF0ab4373',
    earnContractAddress: '0xe0B473c0dD6D7Fea5B395c3Ce7ffd4FEF0ab4373',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['CAKE'],
    risks: [],
    callFee: 0.5,
    depositFee: '<0.1%',
    withdrawalFee: '0.0%',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    network: 'bsc',
  },

  {
    id: 'swamp-bifi-bnb',
    logo: 'bnb-pairs/BIFI-BNB.svg',
    name: 'BIFI-BNB LP',
    token: 'BIFI-BNB LP',
    tokenDescription: 'PancakeSwap (Swamp)',
    tokenAddress: '0xd132D2C24F29EE8ABb64a66559d1b7aa627Bd7fD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSwampyBIFI-BNB',
    earnedTokenAddress: '0x56Ba15eCA5FB30b13FCaD7392c82811a5Ce98e4d',
    earnContractAddress: '0x56Ba15eCA5FB30b13FCaD7392c82811a5Ce98e4d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bifi-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Swamp',
    assets: ['BIFI', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    network: 'bsc',
  },

  {
    id: 'swamp-cake-bnb-eol',
    logo: 'bnb-pairs/CAKE-BNB.svg',
    name: 'CAKE-BNB LP',
    token: 'CAKE-BNB LP',
    tokenDescription: 'PancakeSwap (Swamp)',
    tokenAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSwampyCAKE-BNB',
    earnedTokenAddress: '0xe75E9Cc79c2d1dAa00f30991FBB413C8d4a86537',
    earnContractAddress: '0xe75E9Cc79c2d1dAa00f30991FBB413C8d4a86537',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-cake-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Swamp',
    assets: ['CAKE', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    network: 'bsc',
  },

  {
    id: 'bingo-bgo-busd',
    logo: 'degens/BGO.png',
    name: 'BGO-BUSD LP',
    token: 'BGO-BUSD LP',
    tokenDescription: 'PancakeSwap (Bingo)',
    tokenAddress: '0x9cBEcBA2a5789759f7EdE9D3Af0F76e34f070d06',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBingoBGO-BUSD',
    earnedTokenAddress: '0xa61B7B10B2694E1c283b41be3E55A31a29187290',
    earnContractAddress: '0xa61B7B10B2694E1c283b41be3E55A31a29187290',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bingo-bgo-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BGO', 'BUSD'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x579a6277a6c2c63a5b25006f63bce5dc8d9c25e7',
    network: 'bsc',
  },
  {
    id: 'bingo-sbgo-busd',
    logo: 'degens/SBGO.png',
    name: 'sBGO-BUSD LP',
    token: 'sBGO-BUSD LP',
    tokenDescription: 'PancakeSwap (Bingo)',
    tokenAddress: '0xAc774129e8a09a6a4baF30f6120b006B667C70D3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBingoSBGO-BUSD',
    earnedTokenAddress: '0x7d65a619e696cE8e7020C6Af3Aa9f75542BD6a11',
    earnContractAddress: '0x7d65a619e696cE8e7020C6Af3Aa9f75542BD6a11',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bingo-sbgo-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['sBGO', 'BUSD'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x53f39324fbb209693332b87aa94d5519a1a49ab0',
    network: 'bsc',
  },

  {
    id: 'ellipsis-3eps',
    logo: 'uncategorized/EPS3.png',
    name: 'BUSD/USDT/USDC',
    token: 'EPS 3Pool',
    tokenDescription: 'Ellipsis',
    tokenAddress: '0xaF4dE8E872131AE328Ce21D909C74705d3Aaf452',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooEllipsis3Pool',
    earnedTokenAddress: '0xE563c046147b4dF98bfCD3d00Dc54511F0c3b752',
    earnContractAddress: '0xE563c046147b4dF98bfCD3d00Dc54511F0c3b752',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ellipsis-3eps',
    oraclePrice: 0,
    status: 'active',
    platform: 'Ellipsis',
    assets: ['USDT', 'BUSD', 'USDC', '3EPS'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    callFee: 0.5,
    addLiquidityUrl: 'https://ellipsis.finance/3pool/deposit',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
    network: 'bsc',
  },

  {
    id: 'tndr-tndr-busd',
    logo: 'degens/tndr-busd.png',
    name: 'TNDR-BUSD LP',
    token: 'TNDR-BUSD LP',
    tokenDescription: 'ThunderSwap',
    tokenAddress: '0x3d63B28ea380d6948b4A8d65E30Cb4ECe478abbA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTndrTNDR-BUSD',
    earnedTokenAddress: '0x94Df3D9d2B7594AAa9D633e88bcF8195BA07819A',
    earnContractAddress: '0x94Df3D9d2B7594AAa9D633e88bcF8195BA07819A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tndr-tndr-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['TNDR', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_NEW',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.thunderswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x7Cc46141AB1057b1928de5Ad5Ee78Bb37eFC4868',
    buyTokenUrl:
      'https://exchange.thunderswap.finance/#/swap?outputCurrency=0x7Cc46141AB1057b1928de5Ad5Ee78Bb37eFC4868',
    network: 'bsc',
  },
  {
    id: 'tndr-tndr-bnb',
    logo: 'degens/tndr-bnb.png',
    name: 'TNDR-BNB LP',
    token: 'TNDR-BNB LP',
    tokenDescription: 'ThunderSwap',
    tokenAddress: '0xE3652C5F32B0EbCa4A7752269837d110fbdCfC06',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooTndrTNDR-BNB',
    earnedTokenAddress: '0x8BdBc15aE5EbcaA00a27221D5A45469733F147F6',
    earnContractAddress: '0x8BdBc15aE5EbcaA00a27221D5A45469733F147F6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tndr-tndr-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['TNDR', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_NEW',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.thunderswap.finance/#/add/ETH/0x7Cc46141AB1057b1928de5Ad5Ee78Bb37eFC4868',
    buyTokenUrl:
      'https://exchange.thunderswap.finance/#/swap?outputCurrency=0x7Cc46141AB1057b1928de5Ad5Ee78Bb37eFC4868',
    network: 'bsc',
  },

  {
    id: 'swamp-swamp-bnb',
    logo: 'bnb-pairs/SWAMP-BNB.svg',
    name: 'SWAMP-BNB LP',
    token: 'SWAMP-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xa823C74dE2343E922ab24520f29D08390B4e894D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSwampySWAMP-BNB',
    earnedTokenAddress: '0xb500cd497dD53e8ee889c2553B9Ea82326f21bA9',
    earnContractAddress: '0xb500cd497dD53e8ee889c2553B9Ea82326f21bA9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'swamp-swamp-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Swamp',
    assets: ['SWAMP', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d/BNB',
    network: 'bsc',
  },

  {
    id: 'swamp-swamp-busd',
    logo: 'busd-pairs/SWAMP-BUSD.svg',
    name: 'SWAMP-BUSD LP',
    token: 'SWAMP-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x9B06C33807a9Dee35DC676C401E6B8D93aB2a2F4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSwampySWAMP-BUSD',
    earnedTokenAddress: '0x4a759386cacb60A714275F97AbA9b555d91001c9',
    earnContractAddress: '0x4a759386cacb60A714275F97AbA9b555d91001c9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'swamp-swamp-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Swamp',
    assets: ['SWAMP', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d/0xe9e7cea3dedca5984780bafc599bd69add087d56',
    network: 'bsc',
  },

  {
    id: 'cake-gum-bnb',
    logo: 'bnb-pairs/GUM-BNB.svg',
    name: 'GUM-BNB LP',
    token: 'GUM-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x155645cDF8e4B28d5B7790b65d9f79efc222740C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeGUM-BNB',
    earnedTokenAddress: '0xd689d9C73c01C68b8c0fD0954Cf13E59aC953B66',
    earnContractAddress: '0xd689d9C73c01C68b8c0fD0954Cf13E59aC953B66',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-gum-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['GUM', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
    network: 'bsc',
  },

  {
    id: 'swirl-swirl-bnb',
    logo: 'bnb-pairs/SWIRL-BNB.png',
    name: 'SWIRL-BNB LP',
    token: 'SWIRL-BNB LP',
    tokenDescription: 'PancakeSwap (Swirl)',
    tokenAddress: '0x135AeDCFb35b0b5dCf61Db7891a21253452Eb970',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSwirlSWIRL-BNB',
    earnedTokenAddress: '0x2BE1cB6bc19E4592Beab28DcCfB993E4ad7A43aF',
    earnContractAddress: '0x2BE1cB6bc19E4592Beab28DcCfB993E4ad7A43aF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'swirl-swirl-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['SWIRL', 'BNB'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x52d86850bc8207b520340B7E39cDaF22561b9E56',
    network: 'bsc',
  },

  {
    id: 'cake-dft-bnb',
    logo: 'bnb-pairs/DFT-BNB.svg',
    name: 'DFT-BNB LP',
    token: 'DFT-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x8FbCbD7e30b1733965a8980bf7Ae2ca1c0C456cc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDFT-BNB',
    earnedTokenAddress: '0x1f945e77280653549aA1D36f24620A06Bc64e712',
    earnContractAddress: '0x1f945e77280653549aA1D36f24620A06Bc64e712',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-dft-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['DFT', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x42712dF5009c20fee340B245b510c0395896cF6e',
    network: 'bsc',
  },

  {
    id: 'cake-swth-bnb',
    logo: 'bnb-pairs/SWTH-BNB.svg',
    name: 'SWTH-BNB LP',
    token: 'SWTH-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x8c5cFfad6cddb96Ee33DA685D0d50a37e030E115',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSWTH-BNB',
    earnedTokenAddress: '0x2A3166a20a88B04282C17de6cCE1eEd032314f96',
    earnContractAddress: '0x2A3166a20a88B04282C17de6cCE1eEd032314f96',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-swth-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['SWTH', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
    network: 'bsc',
  },
  {
    id: 'zefi-zefi-bnb',
    logo: 'degens/ZEFI-BNB.svg',
    name: 'ZEFI-BNB LP',
    token: 'ZEFI-BNB LP',
    tokenDescription: 'PancakeSwap (ZCore)',
    tokenAddress: '0x0E6027880A04677BAE5E4225000078eE92904bf6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooZefiZEFI-BNB',
    earnedTokenAddress: '0xc9f7337E2c638C48dd7dcF506535e8fEb0c3c786',
    earnContractAddress: '0xc9f7337E2c638C48dd7dcF506535e8fEb0c3c786',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zefi-zefi-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['ZEFI', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    network: 'bsc',
  },
  {
    id: 'zefi-zefi-busd',
    logo: 'degens/ZEFI-BUSD.svg',
    name: 'ZEFI-BUSD LP',
    token: 'ZEFI-BUSD LP',
    tokenDescription: 'PancakeSwap (ZCore)',
    tokenAddress: '0x24B87c29e907D6329ED8fD6d7B2ecb074089093E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooZefiZEFI-BUSD',
    earnedTokenAddress: '0x8152cd7b2EA135022a5E0baF625CB655302F2E7b',
    earnContractAddress: '0x8152cd7b2EA135022a5E0baF625CB655302F2E7b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'zefi-zefi-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['ZEFI', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    network: 'bsc',
  },
  {
    id: 'hps-hps-bnb',
    logo: 'single-assets/HPS.png',
    name: 'HPS-BNB LP',
    token: 'HPS-BNB LP',
    tokenDescription: 'JulSwap (BillionHappiness)',
    tokenAddress: '0xcaC3b7DE7D5c44E8E1048241C7dE29a61b5C3E7d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBillionHPS-BNB',
    earnedTokenAddress: '0x17cB1Ddb71ca34958e54f8152f0913C25bC50DF6',
    earnContractAddress: '0x17cB1Ddb71ca34958e54f8152f0913C25bC50DF6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'hps-hps-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['HPS', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl: 'https://julswap.com/#/add/BNB/0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D',
    buyTokenUrl:
      'https://julswap.com/#/swap?inputCurrency=BNB&outputCurrency=0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D',
    network: 'bsc',
  },

  {
    id: 'ellipsis-eps-bnb',
    logo: 'uncategorized/EPS.png',
    name: 'EPS-BNB LP',
    token: 'EPS-BNB LP',
    tokenDescription: 'Ellipsis',
    tokenAddress: '0xf9045866e7b372DeF1EFf3712CE55FAc1A98dAF0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooEllipsisEPS-BNB',
    earnedTokenAddress: '0xD467af8C117AcaF7B2725a6d35Dc5A471A683055',
    earnContractAddress: '0xD467af8C117AcaF7B2725a6d35Dc5A471A683055',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ellipsis-eps-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Ellipsis',
    assets: ['EPS', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://pancake.ellipsis.finance/#/add/BNB/0xA7f552078dcC247C2684336020c03648500C6d9F',
    buyTokenUrl:
      'https://pancake.ellipsis.finance/#/swap?outputCurrency=0xA7f552078dcC247C2684336020c03648500C6d9F',
    network: 'bsc',
  },

  {
    id: 'alpaca-salpaca',
    logo: 'single-assets/sALPACA.svg',
    name: 'sALPACA',
    token: 'sALPACA',
    tokenDescription: 'Alpaca',
    tokenAddress: '0x6F695Bd5FFD25149176629f8491A5099426Ce7a7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAlpacaSALPACA',
    earnedTokenAddress: '0xCd1C6adca69071F61EfE5aaa0BB63cA3419D5088',
    earnContractAddress: '0xCd1C6adca69071F61EfE5aaa0BB63cA3419D5088',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ALPACA',
    oraclePrice: 0,
    status: 'active',
    platform: 'Alpaca',
    assets: ['sALPACA'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x8f0528ce5ef7b51152a59745befdd91d97091d2f&outputCurrency=0x6F695Bd5FFD25149176629f8491A5099426Ce7a7',
    network: 'bsc',
  },

  {
    id: 'naut-naut-bnb',
    logo: 'degens/NAUT.png',
    name: 'NAUT-BNB LP',
    token: 'NAUT-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x288EA5437c7aaD045a393cee2F41E548df24d1C8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooNautNAUT-BNB',
    earnedTokenAddress: '0x693402b80b073b872589433e6fa1b08665E12025',
    earnContractAddress: '0x693402b80b073b872589433e6fa1b08665E12025',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'naut-naut-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'ApeSwap',
    assets: ['NAUT', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://dex.apeswap.finance/#/remove/ETH/0x05B339B0A346bF01f851ddE47a5d485c34FE220c',
    network: 'bsc',
  },

  {
    id: 'cake-zil-bnb',
    logo: 'bnb-pairs/ZIL-BNB.svg',
    name: 'ZIL-BNB LP',
    token: 'ZIL-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xc746337b5F800a0e19eD4eB3bda03FF1401B8167',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeZIL-BNB',
    earnedTokenAddress: '0xdFD00D26f698d036EA26900614793829D119C0Ca',
    earnContractAddress: '0xdFD00D26f698d036EA26900614793829D119C0Ca',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-zil-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['ZIL', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xb86abcb37c3a4b64f74f59301aff131a1becc787',
    network: 'bsc',
  },

  {
    id: 'cake-lto-bnb',
    logo: 'bnb-pairs/LTO-BNB.svg',
    name: 'LTO-BNB LP',
    token: 'LTO-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x85644FcD00C401E1A0a0a10d2aE6Bbe04A73e4ab',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeLTO-BNB',
    earnedTokenAddress: '0x37A36B817f372A9F1bDb2Ee59bA1D2c599B2AE77',
    earnContractAddress: '0x37A36B817f372A9F1bDb2Ee59bA1D2c599B2AE77',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-lto-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['LTO', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd',
    network: 'bsc',
  },

  {
    id: 'cake-pcws-bnb',
    logo: 'bnb-pairs/pCWS-BNB.svg',
    name: 'pCWS-BNB LP',
    token: 'pCWS-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x889E81d25bFfbA437b2a5d3e0E4FC58a0E2749c5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakepCWS-BNB',
    earnedTokenAddress: '0x80736F3dd7B2c17ea08C452D3140a7daa461f187',
    earnContractAddress: '0x80736F3dd7B2c17ea08C452D3140a7daa461f187',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-pcws-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['pCWS', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xbcf39f0edda668c58371e519af37ca705f2bfcbd',
    network: 'bsc',
  },

  {
    id: 'cake-trade-bnb',
    logo: 'bnb-pairs/TRADE-BNB.svg',
    name: 'TRADE-BNB LP',
    token: 'TRADE-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x2562f94E90dE6D9eb4fB6B3b8Eab56b15Aa4FC72',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeTRADE-BNB',
    earnedTokenAddress: '0x3F3601229F6fE7D9f3FA8554f0C3Ca4CaE8043B4',
    earnContractAddress: '0x3F3601229F6fE7D9f3FA8554f0C3Ca4CaE8043B4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-trade-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['TRADE', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x7af173f350d916358af3e218bdf2178494beb748',
    network: 'bsc',
  },

  {
    id: 'alpaca-alpaca-bnb',
    logo: 'bnb-pairs/ALPACA-BNB.png',
    name: 'ALPACA-BNB LP',
    token: 'ALPACA-BNB LP',
    tokenDescription: 'Alpaca',
    tokenAddress: '0xF3CE6Aac24980E6B657926dfC79502Ae414d3083',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAlpacaALPACA-BNB',
    earnedTokenAddress: '0xB4CF4b0d79AF3A5627D5595483e0df9620B7d236',
    earnContractAddress: '0xB4CF4b0d79AF3A5627D5595483e0df9620B7d236',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'alpaca-alpaca-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Alpaca',
    assets: ['ALPACA', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
    network: 'bsc',
  },

  {
    id: 'space-space-bnb',
    logo: 'degens/SPACE-BNB.png',
    name: 'SPACE-BNB LP',
    token: 'SPACE-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x78D9a0c50F6484291b4122f61888Bb9EE71879Ff',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSPACE-BNB',
    earnedTokenAddress: '0xbC943C4C00E837e65Cf76BcD8Df8C365FB44Dd6f',
    earnContractAddress: '0xbC943C4C00E837e65Cf76BcD8Df8C365FB44Dd6f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'space-space-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['SPACE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_NEW',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0abd3E3502c15ec252f90F64341cbA74a24fba06',
    network: 'bsc',
  },
  {
    id: 'space-space-busd',
    logo: 'degens/SPACE-BUSD.png',
    name: 'SPACE-BUSD LP',
    token: 'SPACE-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x6B936c5C1FD7De08E03684b0588A87DBD8ce6b63',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSPACE-BUSD',
    earnedTokenAddress: '0xd71A3edcdAe126a30960df40CB5247d87980Bf08',
    earnContractAddress: '0xd71A3edcdAe126a30960df40CB5247d87980Bf08',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'space-space-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['SPACE', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_NEW',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x0abd3E3502c15ec252f90F64341cbA74a24fba06&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
    network: 'bsc',
  },

  {
    id: 'cake-bdigg-btcb',
    logo: 'uncategorized/BDIGG-BTCB.svg',
    name: 'bDIGG-BTCB LP',
    token: 'bDIGG-BTCB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xE1E33459505bB3763843a426F7Fd9933418184ae',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBDIGG-BNB',
    earnedTokenAddress: '0x2c1a0950aC426f920f260C9647BCD3AFD0482f4e',
    earnContractAddress: '0x2c1a0950aC426f920f260C9647BCD3AFD0482f4e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bdigg-btcb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['bDIGG', 'BTCB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    network: 'bsc',
  },

  {
    id: 'pumpy-pmp-bnb',
    logo: 'single-assets/PMP.svg',
    name: 'PMP-BNB LP',
    token: 'PMP-BNB LP',
    tokenDescription: 'PancakeSwap (Pumpy)',
    tokenAddress: '0x18CCA70DDa1699148cB37941d75ccF4847BEa188',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPumpyPMP-BNB',
    earnedTokenAddress: '0xE929d65cBf543cC3cA6b18Fe7872fccC6abBf480',
    earnContractAddress: '0xE929d65cBf543cC3cA6b18Fe7872fccC6abBf480',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pumpy-pmp-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Pumpy',
    assets: ['PMP', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'PLATFORM_NEW',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x8d4FBB3AC63bf33851dCE80D63613Df1A515BC00',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8d4FBB3AC63bf33851dCE80D63613Df1A515BC00',
    network: 'bsc',
  },

  {
    id: 'cake-bifi-bnb',
    logo: 'bnb-pairs/BIFI-BNB.svg',
    name: 'BIFI-BNB LP',
    token: 'BIFI-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xd132D2C24F29EE8ABb64a66559d1b7aa627Bd7fD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBIFI-BNB',
    earnedTokenAddress: '0x3B5332A476AbCdb80Cde6645e9e5563435e97772',
    earnContractAddress: '0x3B5332A476AbCdb80Cde6645e9e5563435e97772',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bifi-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['BIFI', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    network: 'bsc',
  },

  {
    id: 'jul-twt-bnb',
    logo: 'bnb-pairs/JTWT-BNB.svg',
    name: 'TWT-BNB LP',
    token: 'TWT-BNB SLP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0x14aAC5bA46418d625db4f506988E3Ee5a0963887',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulTWT-BNB',
    earnedTokenAddress: '0x7091E04422d1D9Be48C211cf2F818a7E88EFd736',
    earnContractAddress: '0x7091E04422d1D9Be48C211cf2F818a7E88EFd736',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jul-twt-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'JulSwap',
    assets: ['TWT', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://julswap.com/#/remove/BNB/0x4B0F1812e5Df2A09796481Ff14017e6005508003',
    network: 'bsc',
  },

  {
    id: 'venus-eth',
    logo: 'single-assets/ETH.png',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'Venus',
    tokenAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusETH',
    earnedTokenAddress: '0xA9936272065e6DDAc9D2453C9a2712B581e9aE1B',
    earnContractAddress: '0xA9936272065e6DDAc9D2453C9a2712B581e9aE1B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['ETH'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    network: 'bsc',
  },

  {
    id: 'com-com-bnb',
    logo: 'bnb-pairs/COM-BNB.png',
    name: 'COM-BNB LP',
    token: 'COM-BNB LP',
    tokenDescription: 'Complus',
    tokenAddress: '0x7FEAb71e859843D56358069B1374E266F7c0a75f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComplusCOM-BNB',
    earnedTokenAddress: '0x0DA3EB14c8d07a87A366D029d7f2341Ac0eca775',
    earnContractAddress: '0x0DA3EB14c8d07a87A366D029d7f2341Ac0eca775',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'com-com-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Complus',
    assets: ['COM', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://bscdex.complus.exchange/#/add/BNB/0x7fa892544D49598460B821De4D99E8c28b1Decaa',
    buyTokenUrl:
      'https://bscdex.complus.exchange/#/swap?outputCurrency=0x7fa892544d49598460b821de4d99e8c28b1decaa&outputCurrency=BNB',
    network: 'bsc',
  },

  {
    id: 'cake-dusk-bnb',
    logo: 'bnb-pairs/DUSK-BNB.svg',
    name: 'DUSK-BNB LP',
    token: 'DUSK-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xB7918560273FD56e50E9c215CC0DFE8D764C36C5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDUSK-BNB',
    earnedTokenAddress: '0x4BeE93b29e1b3fd90bb3e6EdE9539Fe92E9836Cd',
    earnContractAddress: '0x4BeE93b29e1b3fd90bb3e6EdE9539Fe92E9836Cd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-dusk-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['DUSK', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c',
    network: 'bsc',
  },

  {
    id: 'cake-cos-bnb',
    logo: 'bnb-pairs/COS-BNB.svg',
    name: 'COS-BNB LP',
    token: 'COS-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x7b1e440240B220244761C9D9A3B07fbA1995BD84',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeCOS-BNB',
    earnedTokenAddress: '0x61B51dc9AEb46403628930ef5F527168941E639b',
    earnContractAddress: '0x61B51dc9AEb46403628930ef5F527168941E639b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-cos-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['COS', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0x96Dd399F9c3AFda1F194182F71600F1B65946501',
    network: 'bsc',
  },

  {
    id: 'squirrel-nuts-bnb-eol',
    logo: 'single-assets/NUTS.png',
    name: 'NUTS-BNB LP',
    token: 'NUTS-BNB LP',
    tokenDescription: 'PancakeSwap (Squirrel)',
    tokenAddress: '0x034C9E6B08c09a6144e0D0E52161338fB105e656',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSquirrelNUTS-BNB',
    earnedTokenAddress: '0xB1C9A27394dBa451Bfc4a5dce8Fc829f8a3E9278',
    earnContractAddress: '0xB1C9A27394dBa451Bfc4a5dce8Fc829f8a3E9278',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'squirrel-nuts-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['NUTS', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556',
    network: 'bsc',
  },

  {
    id: 'squirrel-nuts',
    logo: 'single-assets/NUTS.png',
    name: 'NUTS',
    token: 'NUTS',
    tokenDescription: 'Squirrel',
    tokenAddress: '0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSquirrelNUTS',
    earnedTokenAddress: '0x2D40beB0122aBcAD0E6b49934d47adac6Dddd97B',
    earnContractAddress: '0x2D40beB0122aBcAD0E6b49934d47adac6Dddd97B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'NUTS',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['NUTS'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556',
    network: 'bsc',
  },

  {
    id: 'bakery-bat-bake',
    logo: 'uncategorized/BAT-BAKE.svg',
    name: 'BAT-BAKE LP',
    token: 'BAT-BAKE LP',
    tokenDescription: 'Bakery',
    tokenAddress: '0x675Ec26cF1c99A5cD227658f5d0e2fAcbbf3Dcf7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBakeryBAT-BAKE',
    earnedTokenAddress: '0x92E586d7dB14483C103c2e0FE6A596F8b55DA752',
    earnContractAddress: '0x92E586d7dB14483C103c2e0FE6A596F8b55DA752',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bakery-bat-bake',
    oraclePrice: 0,
    status: 'active',
    platform: 'Bakery',
    assets: ['BAT', 'BAKE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://www.bakeryswap.org/#/add/0x101d82428437127bf1608f699cd651e6abf9766e/0xe02df9e3e622debdd69fb838bb799e3f168902c5',
    buyTokenUrl:
      'https://www.bakeryswap.org/#/swap?inputCurrency=0x101d82428437127bf1608f699cd651e6abf9766e&outputCurrency=0xe02df9e3e622debdd69fb838bb799e3f168902c5',
    network: 'bsc',
  },

  {
    id: 'memefarm-ape-bnb',
    logo: 'degens/ape-bnb.png',
    name: 'APE-BNB LP',
    token: 'APE-BNB LP',
    tokenDescription: 'PancakeSwap (MemeFarm)',
    tokenAddress: '0x73E83B4Cc6c03e60f86D816C5ED3050136b7ab78',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMemefarmAPE-BNB',
    earnedTokenAddress: '0xbA67606DFE07387D20d9A324eF91732954688D77',
    earnContractAddress: '0xbA67606DFE07387D20d9A324eF91732954688D77',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'memefarm-ape-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['APE', 'BNB'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xa9eA4b786ee5b7A733c035564Bfd9341A4c9FC1e',
    network: 'bsc',
  },
  {
    id: 'memefarm-mfrm-bnb',
    logo: 'degens/mfrm-bnb.png',
    name: 'MFRM-BNB LP',
    token: 'MFRM-BNB LP',
    tokenDescription: 'PancakeSwap (MemeFarm)',
    tokenAddress: '0x9E6463BB3b6ff6A768290A4eFC452dC92F2CfA2A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMemefarmMFRM-BNB',
    earnedTokenAddress: '0x94d75413A29287Dde5766E5595dc61e668965170',
    earnContractAddress: '0x94d75413A29287Dde5766E5595dc61e668965170',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'memefarm-mfrm-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['MFRM', 'BNB'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x206340f3361404910F45cA0893980EF3f9b418ea',
    network: 'bsc',
  },

  {
    id: 'slime-slime-busd',
    logo: 'degens/slime-busd.png',
    name: 'SLME-BUSD LP',
    token: 'SLME-BUSD LP',
    tokenDescription: 'SlimeFinance',
    tokenAddress: '0xfbd0B87f4132E5A14aA85C21476738C0C13Fd06c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSlimeSLIME-BUSD',
    earnedTokenAddress: '0xd7987DB14509FACb9CC44c0b82CC86c188f5cEEF',
    earnContractAddress: '0xd7987DB14509FACb9CC44c0b82CC86c188f5cEEF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'slime-slime-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['SLME', 'BUSD'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://slime.finance/swap-slimeeees#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x4fCfA6cC8914ab455B5b33Df916d90BFe70b6AB1',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x4fCfA6cC8914ab455B5b33Df916d90BFe70b6AB1&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
    network: 'bsc',
  },
  {
    id: 'slime-slime-bnb',
    logo: 'degens/slime-bnb.png',
    name: 'SLME-BNB LP',
    token: 'SLME-BNB LP',
    tokenDescription: 'SlimeFinance',
    tokenAddress: '0xcb645714520080EF4E65De3254d61356262F0818',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSlimeSLIME-BNB',
    earnedTokenAddress: '0x62d7eC40A1a3f0AB5D1fCC0D8bd3dCb015aFe6a3',
    earnContractAddress: '0x62d7eC40A1a3f0AB5D1fCC0D8bd3dCb015aFe6a3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'slime-slime-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['SLME', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://slime.finance/swap-slimeeees#/add/BNB/0x4fCfA6cC8914ab455B5b33Df916d90BFe70b6AB1',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4fCfA6cC8914ab455B5b33Df916d90BFe70b6AB1',
    network: 'bsc',
  },

  {
    id: 'cakev2-alice-bnb',
    logo: 'bnb-pairs/ALICE-BNB.svg',
    name: 'ALICE-BNB LP',
    token: 'ALICE-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xcAD7019D6d84a3294b0494aEF02e73BD0f2572Eb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2ALICE-BNB',
    earnedTokenAddress: '0x0c65883778f16770F4d43f5524708e18BC62881b',
    earnContractAddress: '0x0c65883778f16770F4d43f5524708e18BC62881b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-alice-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['ALICE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xAC51066d7bEC65Dc4589368da368b212745d63E8',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xAC51066d7bEC65Dc4589368da368b212745d63E8',
    network: 'bsc',
  },
  {
    id: 'cake-alice-bnb',
    logo: 'bnb-pairs/ALICE-BNB.svg',
    name: 'ALICE-BNB LP',
    token: 'ALICE-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xe022baa3E5E87658f789c9132B10d7425Fd3a389',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeALICE-BNB',
    earnedTokenAddress: '0x0C098a54FeE7EC15a59587E69cD340BBd9d22077',
    earnContractAddress: '0x0C098a54FeE7EC15a59587E69cD340BBd9d22077',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-alice-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['ALICE', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xAC51066d7bEC65Dc4589368da368b212745d63E8',
    network: 'bsc',
  },

  {
    id: 'auto-beth-eth',
    logo: 'uncategorized/BETH-ETH.svg',
    name: 'BETH-ETH LP',
    token: 'BETH-ETH LP',
    tokenDescription: 'PancakeSwap (Autofarm)',
    tokenAddress: '0x99d865Ed50D2C32c1493896810FA386c1Ce81D91',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBETH-ETH',
    earnedTokenAddress: '0xF3C1EB01E40c47fd32D0397e56569809aae0e9c7',
    earnContractAddress: '0xF3C1EB01E40c47fd32D0397e56569809aae0e9c7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-beth-eth',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['BETH', 'ETH'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
    network: 'bsc',
  },

  {
    id: 'belt-belt-bnb',
    logo: 'bnb-pairs/BELT-BNB-BELT.png',
    name: 'BELT-BNB LP',
    token: 'BELT-BNB LP',
    tokenDescription: 'PancakeSwap (Belt)',
    tokenAddress: '0x83B92D283cd279fF2e057BD86a95BdEfffED6faa',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeltBeltBNB',
    earnedTokenAddress: '0xfc01F7a17eEb5DABc97312E13954444b00217621',
    earnContractAddress: '0xfc01F7a17eEb5DABc97312E13954444b00217621',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-belt-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Belt',
    assets: ['BELT', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
    network: 'bsc',
  },

  {
    id: 'banana-banana',
    logo: 'degens/BANANA.svg',
    name: 'BANANA',
    token: 'BANANA',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBANANA',
    earnedTokenAddress: '0xD307e7CC6a302046b7D91D83aa4B8324cFB7a786',
    earnContractAddress: '0xD307e7CC6a302046b7D91D83aa4B8324cFB7a786',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BANANA',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BANANA'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    network: 'bsc',
  },

  {
    id: 'cake-belt-bnb',
    logo: 'bnb-pairs/BELT-BNB.svg',
    name: 'BELT-BNB LP',
    token: 'BELT-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x83B92D283cd279fF2e057BD86a95BdEfffED6faa',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBELT-BNB',
    earnedTokenAddress: '0xee43C2D11Bc6DF95dcECd9be05ae648863D6f9dC',
    earnContractAddress: '0xee43C2D11Bc6DF95dcECd9be05ae648863D6f9dC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-belt-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['BELT', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
    network: 'bsc',
  },

  {
    id: 'cake-ramp-busd',
    logo: 'busd-pairs/RAMP-BUSD.svg',
    name: 'RAMP-BUSD LP',
    token: 'RAMP-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xbF36959939982D0D34B37Fb3f3425da9676C13a3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeRAMP-BUSD',
    earnedTokenAddress: '0x32FDaa004C1d9cFCf9B8044D6015DD7bb3a7Ec5a',
    earnContractAddress: '0x32FDaa004C1d9cFCf9B8044D6015DD7bb3a7Ec5a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-ramp-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['RAMP', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x8519ea49c997f50ceffa444d240fb655e89248aa',
    network: 'bsc',
  },

  {
    id: 'jul-btcb-bnb',
    logo: 'bnb-pairs/JBTCB-BNB.svg',
    name: 'BTCB-BNB LP',
    token: 'BTCB-BNB SLP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0x3F3d9E0803B19DD9002C8184Ae3c1C9d09720682',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulBTCB-BNB',
    earnedTokenAddress: '0xFa7767Cf402a6D198cC136FED32550ABA70C3e02',
    earnContractAddress: '0xFa7767Cf402a6D198cC136FED32550ABA70C3e02',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jul-btcb-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'JulSwap',
    assets: ['BTCB', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl: 'https://julswap.com/#/add/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/BNB',
    buyTokenUrl:
      'https://julswap.com/#/swap?inputCurrency=BNB&outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    network: 'bsc',
  },

  {
    id: 'auto-comp-eth',
    logo: 'uncategorized/COMP-ETH.svg',
    name: 'COMP-ETH LP',
    token: 'COMP-ETH LP',
    tokenDescription: 'PancakeSwap (Autofarm)',
    tokenAddress: '0x0392957571F28037607C14832D16f8B653eDd472',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoCOMP-ETH',
    earnedTokenAddress: '0x907b3848593df20243eCb5D954b06a028092b0D6',
    earnContractAddress: '0x907b3848593df20243eCb5D954b06a028092b0D6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-comp-eth',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['COMP', 'ETH'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    network: 'bsc',
  },

  {
    id: 'auto-ust-busd',
    logo: 'busd-pairs/UST-BUSD.svg',
    name: 'UST-BUSD LP',
    token: 'UST-BUSD LP',
    tokenDescription: 'PancakeSwap (Autofarm)',
    tokenAddress: '0xD1F12370b2ba1C79838337648F820a87eDF5e1e6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoUST-BUSD',
    earnedTokenAddress: '0xCa0294b89ee367ADaEDC8cdA684648dC1c5D8FEe',
    earnContractAddress: '0xCa0294b89ee367ADaEDC8cdA684648dC1c5D8FEe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-ust-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['UST', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0x23396cF899Ca06c4472205fC903bDB4de249D6fC/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'auto-busd-bnb',
    logo: 'bnb-pairs/BUSD-BNB.svg',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB LP',
    tokenDescription: 'PancakeSwap (Autofarm)',
    tokenAddress: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBUSD-BNB',
    earnedTokenAddress: '0xb01e3C7789858beD3b142c8f2499F21Ab3ea3f0f',
    earnContractAddress: '0xb01e3C7789858beD3b142c8f2499F21Ab3ea3f0f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-busd-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['BUSD', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'auto-btc-bnb',
    logo: 'bnb-pairs/BTCB-BNB.svg',
    name: 'BTC-BNB LP',
    token: 'BTC-BNB LP',
    tokenDescription: 'PancakeSwap (Autofarm)',
    tokenAddress: '0x7561EEe90e24F3b348E1087A005F78B4c8453524',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBTC-BNB',
    earnedTokenAddress: '0xcf2D6e0030e24F01e4750cAE376E2126e728E0c2',
    earnContractAddress: '0xcf2D6e0030e24F01e4750cAE376E2126e728E0c2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-btc-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['BTCB', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/BNB',
    network: 'bsc',
  },

  {
    id: 'venus-btcb',
    logo: 'single-assets/BTCB.svg',
    name: 'BTCB',
    token: 'BTCB',
    tokenDescription: 'Venus',
    tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusBTCB',
    earnedTokenAddress: '0x7255Ae13BA4484eD33fB48c4b833000dC12888B4',
    earnContractAddress: '0x7255Ae13BA4484eD33fB48c4b833000dC12888B4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BTCB',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['BTCB'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    network: 'bsc',
  },

  {
    id: 'cake-dexe-busd',
    logo: 'busd-pairs/DEXE-BUSD.svg',
    name: 'DEXE-BUSD LP',
    token: 'DEXE-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x69AB367BC1BEA1D2c0fB4dBaEc6b7197951Da56C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDEXE-BUSD',
    earnedTokenAddress: '0xfEDB3855761B9e356671Fb1eC95593e99b153EfD',
    earnContractAddress: '0xfEDB3855761B9e356671Fb1eC95593e99b153EfD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-dexe-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['DEXE', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x039cb485212f996a9dbb85a9a75d898f94d38da6',
    network: 'bsc',
  },

  {
    id: 'jul-bifi-bnb',
    logo: 'bnb-pairs/BIFI-BNB.png',
    name: 'BIFI-BNB LP',
    token: 'BIFI-BNB SLP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0xd3665568474cC090aB6B243333c2A1A3f13a9C7F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulBIFI-BNB',
    earnedTokenAddress: '0x6a3fF116a10349BB40B22FD7687871d5D560d715',
    earnContractAddress: '0x6a3fF116a10349BB40B22FD7687871d5D560d715',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jul-bifi-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'JulSwap',
    assets: ['BIFI', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl: 'https://julswap.com/#/add/BNB/0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    buyTokenUrl:
      'https://julswap.com/#/swap?inputCurrency=BNB&outputCurrency=0xca3f508b8e4dd382ee878a314789373d80a5190a',
    network: 'bsc',
  },

  {
    id: 'jul-juld-bnb',
    logo: 'bnb-pairs/JULD-BNB.svg',
    name: 'JulD-BNB LP',
    token: 'JulD-BNB SLP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0x0c8f9a56544CC988f1873A038de2f3e4C6fE6f27',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulJulD-BNB',
    earnedTokenAddress: '0xcB8c4416cD10836ff7A35F4b2AfD5BE0257Cc9DB',
    earnContractAddress: '0xcB8c4416cD10836ff7A35F4b2AfD5BE0257Cc9DB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jul-juld-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'JulSwap',
    assets: ['JulD', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    callFee: 0.5,
    addLiquidityUrl: 'https://julswap.com/#/add/0x5a41f637c3f7553dba6ddc2d3ca92641096577ea/BNB',
    buyTokenUrl:
      'https://julswap.com/#/swap?inputCurrency=BNB&outputCurrency=0x5a41f637c3f7553dba6ddc2d3ca92641096577ea',
    network: 'bsc',
  },

  {
    id: 'venus-ada',
    logo: 'single-assets/ADA.svg',
    name: 'ADA',
    token: 'ADA',
    tokenDescription: 'Venus',
    tokenAddress: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusADA',
    earnedTokenAddress: '0x53c0CE2EBdA0c2A39327bE0B58a3c28A8Dcde420',
    earnContractAddress: '0x53c0CE2EBdA0c2A39327bE0B58a3c28A8Dcde420',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ADA',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['ADA'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    network: 'bsc',
  },

  {
    id: 'cake-bel-bnb',
    logo: 'bnb-pairs/BEL-BNB.svg',
    name: 'BEL-BNB LP',
    token: 'BEL-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xAB97952a2806D5c92b7046c7aB13a72A87e0097b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBEL-BNB',
    earnedTokenAddress: '0x7C001B962029C0a59b148293838dc780d7E5d24C',
    earnContractAddress: '0x7C001B962029C0a59b148293838dc780d7E5d24C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bel-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['BEL', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x8443f091997f06a61670b735ed92734f5628692f',
    network: 'bsc',
  },

  {
    id: 'cake-tpt-busd',
    logo: 'busd-pairs/TPT-BUSD.svg',
    name: 'TPT-BUSD LP',
    token: 'TPT-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x4db28767D1527bA545CA5bbdA1C96a94ED6ff242',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeTPT-BUSD',
    earnedTokenAddress: '0x6b4971b8dAfc3c426EdE7E629394f31B0BdF3c16',
    earnContractAddress: '0x6b4971b8dAfc3c426EdE7E629394f31B0BdF3c16',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-tpt-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['TPT', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0xeca41281c24451168a37211f0bc2b8645af45092',
    network: 'bsc',
  },

  {
    id: 'cake-watch-bnb',
    logo: 'bnb-pairs/WATCH-BNB.svg',
    name: 'WATCH-BNB LP',
    token: 'WATCH-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xdC6C130299E53ACD2CC2D291fa10552CA2198a6b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeWATCH-BNB',
    earnedTokenAddress: '0x31b4d6D89531BB32307737C441a8cD7d82224B91',
    earnContractAddress: '0x31b4d6D89531BB32307737C441a8cD7d82224B91',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-watch-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['WATCH', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
    network: 'bsc',
  },

  {
    id: 'cake-xmark-busd',
    logo: 'busd-pairs/XMARK-BUSD.svg',
    name: 'XMARK-BUSD LP',
    token: 'XMARK-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x292Ca56ED5b3330A19037f835Af4A9c0098EA6Fa',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeXMARK-BUSD',
    earnedTokenAddress: '0x9De53755e913913dEcA7360a19C0634F2214FB6d',
    earnContractAddress: '0x9De53755e913913dEcA7360a19C0634F2214FB6d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-xmark-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['XMARK', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x26a5dfab467d4f58fb266648cae769503cec9580',
    network: 'bsc',
  },

  {
    id: 'soup-soup-bnb',
    logo: 'degens/SOUP-BNB.svg',
    name: 'SOUP-BNB LP',
    token: 'SOUP-BNB LP',
    tokenDescription: 'PancakeSwap (Soup)',
    tokenAddress: '0x284A5D8712C351Ca28417d131003120808dcE48B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSoupSoup-BNB',
    earnedTokenAddress: '0xDB89f01094c6e882d06aBC724aEBc7d15036fe14',
    earnContractAddress: '0xDB89f01094c6e882d06aBC724aEBc7d15036fe14',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'soup-soup-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['SOUP', 'BNB'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x94F559aE621F1c810F31a6a620Ad7376776fe09E',
    network: 'bsc',
  },
  {
    id: 'soup-soups-bnb',
    logo: 'degens/SOUPS-BNB.svg',
    name: 'SOUPS-BNB LP',
    token: 'SOUPS-BNB LP',
    tokenDescription: 'PancakeSwap (Soup)',
    tokenAddress: '0x6304Ae062c6bDf3D24Ac86374C7019A025443247',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSoupSoups-BNB',
    earnedTokenAddress: '0xe698edcC279f68c2c37f5c122a26faBd5f6D98A3',
    earnContractAddress: '0xe698edcC279f68c2c37f5c122a26faBd5f6D98A3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'soup-soups-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['SOUPS', 'BNB'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x69f27e70e820197a6e495219d9ac34c8c6da7eee',
    network: 'bsc',
  },

  {
    id: 'banana-banana-bnb',
    logo: 'degens/banana-bnb.svg',
    name: 'BANANA-BNB LP',
    token: 'BANANA-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBANANA-BNB',
    earnedTokenAddress: '0x15396D3BD9338A14AE90613Fc2b85c5F7b5621CF',
    earnContractAddress: '0x15396D3BD9338A14AE90613Fc2b85c5F7b5621CF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-banana-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BANANA', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    network: 'bsc',
  },

  {
    id: 'banana-banana-busd',
    logo: 'degens/banana-busd.svg',
    name: 'BANANA-BUSD LP',
    token: 'BANANA-BUSD LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x7Bd46f6Da97312AC2DBD1749f82E202764C0B914',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBANANA-BUSD',
    earnedTokenAddress: '0x651b496bCe2C184282a2B9AeF3f8BaDBAC36Dd7f',
    earnContractAddress: '0x651b496bCe2C184282a2B9AeF3f8BaDBAC36Dd7f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-banana-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BANANA', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
    network: 'bsc',
  },

  {
    id: 'cake-bmxx-bnb',
    logo: 'bnb-pairs/BMXX-BNB.svg',
    name: 'BMXX-BNB LP',
    token: 'BMXX-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x4D5aA94Ce6BbB1BC4eb73207a5a5d4D052cFcD67',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBMXX-BNB',
    earnedTokenAddress: '0xE7DCEFacf1c9c523c1ccCc3Fc1Ae3EB654a4e805',
    earnContractAddress: '0xE7DCEFacf1c9c523c1ccCc3Fc1Ae3EB654a4e805',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bmxx-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['BMXX', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x4131b87f74415190425ccd873048c708f8005823',
    network: 'bsc',
  },
  {
    id: 'cake-bopen-bnb',
    logo: 'bnb-pairs/BOPEN-BNB.svg',
    name: 'BOPEN-BNB LP',
    token: 'BOPEN-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x9d8b7e4a9D53654D82F12c83448D8f92732bC761',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBOPEN-BNB',
    earnedTokenAddress: '0x847c5748A280d800690F7D3A62574603b57Cd0b7',
    earnContractAddress: '0x847c5748A280d800690F7D3A62574603b57Cd0b7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bopen-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['BOPEN', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xf35262a9d427f96d2437379ef090db986eae5d42',
    network: 'bsc',
  },

  {
    id: '1inch-1inch-bnb-eol',
    logo: 'single-assets/INCH.png',
    name: '1INCH-BNB LP',
    token: '1INCH-BNB LP',
    tokenDescription: '1Inch',
    tokenAddress: '0xdaF66c0B7e8E2FC76B15B07AD25eE58E04a66796',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'moo1INCH1INCH-BNB',
    earnedTokenAddress: '0x2139E4CaDA8438a41F4009ff07D09Db29A62De04',
    earnContractAddress: '0x2139E4CaDA8438a41F4009ff07D09Db29A62De04',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: '1inch-1inch-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: '1Inch',
    assets: ['1INCH', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://1inch.exchange/#/dao/pools?network=56&token0=0x0000000000000000000000000000000000000000&token1=0x111111111117dc0aa78b770fa6a738034120c302',
    buyTokenUrl:
      'https://1inch.exchange/#/r/0xF4cb25a1FF50E319c267b3E51CBeC2699FB2A43B/BNB/1INCH/?network=56',
    network: 'bsc',
  },

  {
    id: 'cake-iotx-busd',
    logo: 'busd-pairs/IOTX-BUSD.svg',
    name: 'IOTX-BUSD LP',
    token: 'IOTX-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x36B8B28D37f93372188F2aa2507b68A5CD8b2664',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeIOTX-BUSD',
    earnedTokenAddress: '0x8a27f380626CAc25DF8D3Ea50Ed1fd7Db882e653',
    earnContractAddress: '0x8a27f380626CAc25DF8D3Ea50Ed1fd7Db882e653',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-iotx-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['IOTX', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x9678e42cebeb63f23197d726b29b1cb20d0064e5',
    network: 'bsc',
  },

  {
    id: 'cake-lina-busd',
    logo: 'busd-pairs/LINA-BUSD.svg',
    name: 'LINA-BUSD LP',
    token: 'LINA-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xeB325a8ea1c5abf40c7ceAf645596c1F943d0948',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeLINA-BUSD',
    earnedTokenAddress: '0x310DF4c1DD93337A165AeF8e3d45A770CaaDe3b6',
    earnContractAddress: '0x310DF4c1DD93337A165AeF8e3d45A770CaaDe3b6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-lina-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['LINA', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x762539b45a1dcce3d36d080f74d1aed37844b878',
    network: 'bsc',
  },

  {
    id: 'cake-dai-busd',
    logo: 'busd-pairs/DAI-BUSD.svg',
    name: 'DAI-BUSD LP',
    token: 'DAI-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x3aB77e40340AB084c3e23Be8e5A6f7afed9D41DC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDAI-BUSD',
    earnedTokenAddress: '0xE372825C7b436244Cd5fA79cAa16e4Cc3091D4B0',
    earnContractAddress: '0xE372825C7b436244Cd5fA79cAa16e4Cc3091D4B0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-dai-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['DAI', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    network: 'bsc',
  },

  {
    id: 'sponge-steam-bnb',
    logo: 'bnb-pairs/STEAM-BNB.svg',
    name: 'STEAM-BNB LP',
    token: 'STEAM-BNB LP',
    tokenDescription: 'Sponge',
    tokenAddress: '0x5e797B329e6B82b62442477D976a56864AE4396e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpongeSTEAM-BNB',
    earnedTokenAddress: '0x04D0bE0169694ca083996899a14752c82A953D22',
    earnContractAddress: '0x04D0bE0169694ca083996899a14752c82A953D22',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sponge-steam-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['STEAM', 'BNB'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x072e3d0d4f14dd82a16b0a33678c6104e9009ed9',
    network: 'bsc',
  },

  {
    id: 'salt-salt-busd',
    logo: 'degens/salt-busd.png',
    name: 'SALT-BUSD LP',
    token: 'SALT-BUSD LP',
    tokenDescription: 'SaltSwap',
    tokenAddress: '0x6596f770786915556C47E301cC8290aa14288d99',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSaltSalt-BUSD',
    earnedTokenAddress: '0x62ad4253FB38265Da05eb389ac547F1B2c09a689',
    earnContractAddress: '0x62ad4253FB38265Da05eb389ac547F1B2c09a689',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'salt-salt-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'SaltSwap',
    assets: ['SALT', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.saltswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x2849b1ae7e04a3d9bc288673a92477cf63f28af4',
    buyTokenUrl:
      'https://exchange.saltswap.finance/#/swap?inputCurrency=0xcc2E12a9b5b75360c6FBf23B584c275D52cDdb0&outputCurrency=0x2849b1ae7e04a3d9bc288673a92477cf63f28af4',
    network: 'bsc',
  },
  {
    id: 'salt-salt-bnb',
    logo: 'degens/salt-bnb.png',
    name: 'SALT-BNB LP',
    token: 'SALT-BNB LP',
    tokenDescription: 'SaltSwap',
    tokenAddress: '0x6284B49544c8900B4612f8450DCE8D484E5c2631',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSaltSalt-BNB',
    earnedTokenAddress: '0x57e07d6958fd38AA2f46699744e322d3fB3db60f',
    earnContractAddress: '0x57e07d6958fd38AA2f46699744e322d3fB3db60f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'salt-salt-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'SaltSwap',
    assets: ['SALT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.saltswap.finance/#/add/BNB/0x2849b1ae7e04a3d9bc288673a92477cf63f28af4',
    buyTokenUrl:
      'https://exchange.saltswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0x2849b1ae7e04a3d9bc288673a92477cf63f28af4',
    network: 'bsc',
  },

  {
    id: 'cake-sushi-eth',
    logo: 'uncategorized/SUSHI-ETH.svg',
    name: 'SUSHI-ETH LP',
    token: 'SUSHI-ETH LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x17580340F3dAEDAE871a8C21D15911742ec79e0F', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSUSHI-ETH',
    earnedTokenAddress: '0x07622B8cF9130934643522E7c9D621707EF1d34F',
    earnContractAddress: '0x07622B8cF9130934643522E7c9D621707EF1d34F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-sushi-eth',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['SUSHI', 'ETH'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0x2170ed0880ac9a755fd29b2688956bd959f933f8/0x947950bcc74888a40ffa2593c5798f11fc9124c4',
    network: 'bsc',
  },

  {
    id: 'crow-crow-busd',
    logo: 'crow/crow.png',
    name: 'CROW-BUSD LP',
    token: 'CROW-BUSD LP',
    tokenDescription: 'PancakeSwap (Crow)',
    tokenAddress: '0xEC3B97802FB4883FdB7bf70f0924Bf5EE0520cDE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCrowCROW-BUSD',
    earnedTokenAddress: '0x39C803716F67D6d4b190E633a961bF3503b8444E',
    earnContractAddress: '0x39C803716F67D6d4b190E633a961bF3503b8444E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'crow-crow-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['CROW', 'BUSD'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xcc2E12a9b5b75360c6FBf23B584c275D52cDdb0&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'crow-crow-bnb',
    logo: 'crow/crow.png',
    name: 'CROW-BNB LP',
    token: 'CROW-BNB LP',
    tokenDescription: 'PancakeSwap (Crow)',
    tokenAddress: '0x74d2a66650bbd8027Fd1F4A3454a16D75A05cc14',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCrowCROW-BNB',
    earnedTokenAddress: '0xbD701BC24485Ebfd3680285fB152509637Ab8ad4',
    earnContractAddress: '0xbD701BC24485Ebfd3680285fB152509637Ab8ad4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'crow-crow-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['CROW', 'BNB'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0xcc2E12a9b5b75360c6FBf23B584c275D52cDdb0E',
    network: 'bsc',
  },

  {
    id: 'ramen-ramen-bnb',
    logo: 'degens/ramen-bnb.svg',
    name: 'RAMEN-BNB LP',
    token: 'RAMEN-BNB LP',
    tokenDescription: 'RamenSwap',
    tokenAddress: '0x82f504D655ec6dbA8d5eBbd0036a908A719A295f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooRamenRamen-BNB',
    earnedTokenAddress: '0x42b41189e9B371B7952b77aE791FE25E78D22681',
    earnContractAddress: '0x42b41189e9B371B7952b77aE791FE25E78D22681',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ramen-ramen-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'RamenSwap',
    assets: ['RAMEN', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.ramenswap.finance/#/add/ETH/0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B',
    buyTokenUrl:
      'https://exchange.ramenswap.finance/#/swap?outputCurrency=0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B',
    network: 'bsc',
  },

  {
    id: 'ramen-ramen-busd',
    logo: 'degens/ramen-busd.svg',
    name: 'RAMEN-BUSD LP',
    token: 'RAMEN-BUSD LP',
    tokenDescription: 'RamenSwap',
    tokenAddress: '0x04F81B56B6947Cd0fD35fbea570fc09d1b946C56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooRamenRamen-BUSD',
    earnedTokenAddress: '0x605d96De6068f08489392a35E9DBE90201574bbC',
    earnContractAddress: '0x605d96De6068f08489392a35E9DBE90201574bbC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ramen-ramen-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'RamenSwap',
    assets: ['RAMEN', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.ramenswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B',
    buyTokenUrl:
      'https://exchange.ramenswap.finance/#/swap?inputCurrency=0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'cafe-brew-busd',
    logo: 'degens/brew-busd.svg',
    name: 'BREW-BUSD LP',
    token: 'BREW-BUSD LP',
    tokenDescription: 'CafeSwap',
    tokenAddress: '0x1FD9Af4999De0d61c2a6CBD3d4892b675a082999',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCafeBREW-BUSD',
    earnedTokenAddress: '0x10120B5550214ab9451B9B00e4f78e82C02D6482',
    earnContractAddress: '0x10120B5550214ab9451B9B00e4f78e82C02D6482',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cafe-brew-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BREW', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.cafeswap.finance/#/remove/0x790Be81C3cA0e53974bE2688cDb954732C9862e1/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'cafe-brew-bnb',
    logo: 'degens/brew-bnb.svg',
    name: 'BREW-BNB LP',
    token: 'BREW-BNB LP',
    tokenDescription: 'CafeSwap',
    tokenAddress: '0x723203E821f1fF2d0e396d5DD2EA390f3C9d42cF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCafeBREW-BNB',
    earnedTokenAddress: '0xEE24B014422a53dA2190a64C0477a300590677f2',
    earnContractAddress: '0xEE24B014422a53dA2190a64C0477a300590677f2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cafe-brew-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BREW', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.cafeswap.finance/#/remove/0x790Be81C3cA0e53974bE2688cDb954732C9862e1/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    network: 'bsc',
  },

  {
    id: 'cafe-bifi-bnb',
    logo: 'degens/BIFI-BNB.svg',
    name: 'BIFI-BNB LP',
    token: 'BIFI-BNB LP',
    tokenDescription: 'CafeSwap',
    tokenAddress: '0xd132D2C24F29EE8ABb64a66559d1b7aa627Bd7fD', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCafeBIFI-BNB',
    earnedTokenAddress: '0x8afc0f9BdC5DcA9f0408Df03A03520bFa98A15aF',
    earnContractAddress: '0x8afc0f9BdC5DcA9f0408Df03A03520bFa98A15aF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cafe-bifi-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BIFI', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://exchange.cafeswap.finance/#/remove/0xCa3F508B8e4Dd382eE878A314789373D80A5190A/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    network: 'bsc',
  },

  {
    id: 'cake-bry-bnb',
    logo: 'bnb-pairs/BRY-BNB.svg',
    name: 'BRY-BNB LP',
    token: 'BRY-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x5E3CD27F36932Bc0314aC4e2510585798C34a2fC', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBRYBNB',
    earnedTokenAddress: '0x578C066b5c7289f8Ba7c4DB43c110F19D7c51E03',
    earnContractAddress: '0x578C066b5c7289f8Ba7c4DB43c110F19D7c51E03',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bry-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['BRY', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
    network: 'bsc',
  },

  {
    id: 'cake-zee-bnb',
    logo: 'bnb-pairs/ZEE-BNB.svg',
    name: 'ZEE-BNB LP',
    token: 'ZEE-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xB5Ab3996808c7e489DCDc0f1Af2AB212ae0059aF', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeZEEBNB',
    earnedTokenAddress: '0x29C4C22eC7dF7e936Cb3e6C4A7E5eAE751C7d86B',
    earnContractAddress: '0x29C4C22eC7dF7e936Cb3e6C4A7E5eAE751C7d86B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-zee-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['ZEE', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x44754455564474a89358b2c2265883df993b12f0',
    network: 'bsc',
  },

  {
    id: 'cake-dodo-bnb',
    logo: 'bnb-pairs/DODO-BNB.svg',
    name: 'DODO-BNB LP',
    token: 'DODO-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x9e642d174B14fAEa31D842Dc83037c42b53236E6', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDODO-BNB',
    earnedTokenAddress: '0x00AeC34489A7ADE91A0507B6b9dBb0a50938B7c0',
    earnContractAddress: '0x00AeC34489A7ADE91A0507B6b9dBb0a50938B7c0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-dodo-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['DODO', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2',
    network: 'bsc',
  },

  {
    id: 'cake-swingby-bnb',
    logo: 'bnb-pairs/SWINGBY-BNB.svg',
    name: 'SWINGBY-BNB LP',
    token: 'SWINGBY-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x4576C456AF93a37a096235e5d83f812AC9aeD027', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSWINGBY-BNB',
    earnedTokenAddress: '0xc1C1eB984218B9570beA53C0Dad14283a6E9E81C',
    earnContractAddress: '0xc1C1eB984218B9570beA53C0Dad14283a6E9E81C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-swingby-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['SWINGBY', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x71de20e0c4616e7fcbfdd3f875d568492cbe4739',
    network: 'bsc',
  },

  {
    id: 'kebab-usdt-busd',
    logo: 'kebab/USDT-BUSD.png',
    name: 'USDT-BUSD LP',
    token: 'USDT-BUSD LP',
    tokenDescription: 'Kebab',
    tokenAddress: '0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebabUSDT-BUSD',
    earnedTokenAddress: '0x9dc7d9c4488223c0Ad50F0AE742D80AE1abEc859',
    earnContractAddress: '0x9dc7d9c4488223c0Ad50F0AE742D80AE1abEc859',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kebab-usdt-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Kebab',
    assets: ['USDT', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.kebabfinance.com/#/add/0x55d398326f99059ff775485246999027b3197955/0xe9e7cea3dedca5984780bafc599bd69add087d56',
    buyTokenUrl:
      'https://swap.kebabfinance.com/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
    network: 'bsc',
  },

  {
    id: 'kebab-eth-btcb',
    logo: 'kebab/ETH-BTCB.png',
    name: 'ETH-BTCB LP',
    token: 'ETH-BTCB LP',
    tokenDescription: 'Kebab',
    tokenAddress: '0x7380E10F5C5f9DFF4857de3cf9c39Bb16F4C6dcf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebabETH-BTCB',
    earnedTokenAddress: '0x70CBc8B3a74A857056a6acE7e994229A24D4ab94',
    earnContractAddress: '0x70CBc8B3a74A857056a6acE7e994229A24D4ab94',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kebab-eth-btcb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Kebab',
    assets: ['ETH', 'BTCB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.kebabfinance.com/#/add/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    buyTokenUrl:
      'https://swap.kebabfinance.com/#/swap?inputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c&outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    network: 'bsc',
  },

  {
    id: 'monster-msc-busd',
    logo: 'single-assets/MSC.png',
    name: 'MSC-BUSD LP',
    token: 'MSC-BUSD LP',
    tokenDescription: 'PancakeSwap (Monster)',
    tokenAddress: '0xbCbF0C95C94798fFEF0e71A087a7E98F29D5C0Ee',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMonsterMSC-BUSD',
    earnedTokenAddress: '0x1d9a453FCe2AC7b92FBe7b33BdC40F6e7aE14F47',
    earnContractAddress: '0x1d9a453FCe2AC7b92FBe7b33BdC40F6e7aE14F47',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'monster-msc-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['MSC', 'BUSD'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x8C784C49097Dcc637b93232e15810D53871992BF&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'monster-msc-bnb',
    logo: 'single-assets/MSC.png',
    name: 'MSC-BNB LP',
    token: 'MSC-BNB LP',
    tokenDescription: 'PancakeSwap (Monster)',
    tokenAddress: '0x981DB69F2F2F96e0f08D6519BEFDa0B927C22190',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMonsterMSC-BNB',
    earnedTokenAddress: '0x0F88Ce20f8af6D4c51b635BFBd8DFa03f2a36ACa',
    earnContractAddress: '0x0F88Ce20f8af6D4c51b635BFBd8DFa03f2a36ACa',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'monster-msc-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['MSC', 'BNB'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8C784C49097Dcc637b93232e15810D53871992BF',
    network: 'bsc',
  },

  {
    id: 'cake-bts-busd',
    logo: 'single-assets/BTS.png',
    name: 'BTS-BUSD LP',
    token: 'BTS-BUSD LP',
    tokenDescription: 'PancakeSwap (Bolt)',
    tokenAddress: '0xE86493D416EEB7800F08787de759d179B61dB921',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoltDollarBTS-BUSD',
    earnedTokenAddress: '0x6b40106ebEDaA719B14cfbA606b89954EaeeaE9D',
    earnContractAddress: '0x6b40106ebEDaA719B14cfbA606b89954EaeeaE9D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bts-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['BTS', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xc2e1acef50ae55661855e8dcb72adb182a3cc259&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },
  {
    id: 'cake-btd-busd',
    logo: 'single-assets/BTD.png',
    name: 'BTD-BUSD LP',
    token: 'BTD-BUSD LP',
    tokenDescription: 'PancakeSwap (Bolt)',
    tokenAddress: '0xebaaA639a55ecBEbAC4D7D43A5A6D7C5c1216A09',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoltDollarBTD-BUSD',
    earnedTokenAddress: '0x0D3837E00C47353e68680C39e6DA6B5b08fE6897',
    earnContractAddress: '0x0D3837E00C47353e68680C39e6DA6B5b08fE6897',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-btd-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['BTD', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'ALGO_STABLE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xD1102332a213E21faF78B69C03572031F3552c33&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'auto-btc-eol',
    logo: 'single-assets/BTCB.svg',
    name: 'BTCB',
    token: 'BTCB',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBtc',
    earnedTokenAddress: '0xFf750EE63F1E428De1D6C8D9d92676b5FC8376A8',
    earnContractAddress: '0xFf750EE63F1E428De1D6C8D9d92676b5FC8376A8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BTCB',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['BTCB'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    network: 'bsc',
  },

  {
    id: 'auto-eth-eol',
    logo: 'single-assets/ETH.png',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoEth',
    earnedTokenAddress: '0x0a406FCBEA86aC57ACcc812E8F1B509acE5E054c',
    earnContractAddress: '0x0a406FCBEA86aC57ACcc812E8F1B509acE5E054c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['ETH'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    network: 'bsc',
  },

  {
    id: 'auto-busd-eol',
    logo: 'single-assets/BUSD.svg',
    name: 'BUSD',
    token: 'BUSD',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBusdFixed',
    earnedTokenAddress: '0x559C88946e1958812dCfD8edd4f89B0C7d811F74',
    earnContractAddress: '0x559C88946e1958812dCfD8edd4f89B0C7d811F74',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BUSD',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['BUSD'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
    network: 'bsc',
  },

  {
    id: 'auto-usdt-eol',
    logo: 'single-assets/USDT.svg',
    name: 'USDT',
    token: 'USDT',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoUsdt',
    earnedTokenAddress: '0x58327471A57EE89Aa4b91753fF9E09f7291236C7',
    earnContractAddress: '0x58327471A57EE89Aa4b91753fF9E09f7291236C7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['USDT'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955',
    network: 'bsc',
  },

  {
    id: 'auto-usdc-eol',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoUsdc',
    earnedTokenAddress: '0x7E45e8E3404a2C142d7E8eAE94EaEB8641607857',
    earnContractAddress: '0x7E45e8E3404a2C142d7E8eAE94EaEB8641607857',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['USDC'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    network: 'bsc',
  },

  {
    id: 'kebab-kebab-btc',
    logo: 'kebab/KEBAB-BTCB.png',
    name: 'KEBAB-BTCB LP',
    token: 'KEBAB-BTCB LP',
    tokenDescription: 'Kebab',
    tokenAddress: '0x45D8D5d9bD425Efc26e3259848BCFEfA16F927F0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebabKEBAB-BTCB',
    earnedTokenAddress: '0x02a77B01BE5BB6a73b70E2b5615694F71a2c4F41',
    earnContractAddress: '0x02a77B01BE5BB6a73b70E2b5615694F71a2c4F41',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kebab-kebab-btc',
    oraclePrice: 0,
    status: 'active',
    platform: 'Kebab',
    assets: ['KEBAB', 'BTCB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.kebabfinance.com/#/add/0x7979f6c54eba05e18ded44c4f986f49a5de551c2/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    buyTokenUrl:
      'https://swap.kebabfinance.com/#/swap?inputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c&outputCurrency=0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
    network: 'bsc',
  },

  {
    id: 'cake-swgb-bnb',
    logo: 'bnb-pairs/SWGB-BNB.svg',
    name: 'SWGB-BNB LP',
    token: 'SWGB-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xC1800c29CF91954357cd0bf3f0accAADa3D0109c', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSWGB-BNB',
    earnedTokenAddress: '0xed324b068e31c6fB7C7921e79E54d95B1Ca9d759',
    earnContractAddress: '0xed324b068e31c6fB7C7921e79E54d95B1Ca9d759',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-swgb-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['SWGB', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb',
    network: 'bsc',
  },

  {
    id: 'cake-lit-bnb',
    logo: 'bnb-pairs/LIT-BNB.svg',
    name: 'LIT-BNB LP',
    token: 'LIT-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x60bB03D1010b99CEAdD0dd209b64bC8bd83da161', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeLIT-BNB',
    earnedTokenAddress: '0x747f66e2Ab1b887a2207e15ADb7f9dAB79D9EAcf',
    earnContractAddress: '0x747f66e2Ab1b887a2207e15ADb7f9dAB79D9EAcf',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-lit-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['LIT', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
    network: 'bsc',
  },

  {
    id: 'kebab-bifi-busd',
    logo: 'busd-pairs/BIFI-BUSD.png',
    name: 'BIFI-BUSD LP',
    token: 'BIFI-BUSD LP',
    tokenDescription: 'Kebab',
    tokenAddress: '0xd3F004E303114423f122c78AFDeD4AcfE97675B1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebabBIFI-BUSD',
    earnedTokenAddress: '0x9c1630eA66310fA03D756FBd92B68a1f08c487b6',
    earnContractAddress: '0x9c1630eA66310fA03D756FBd92B68a1f08c487b6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kebab-bifi-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Kebab',
    assets: ['BIFI', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.kebabfinance.com/#/add/0xCa3F508B8e4Dd382eE878A314789373D80A5190A/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://swap.kebabfinance.com/#/swap?inputCurrency=0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    network: 'bsc',
  },

  {
    id: 'cake-ditto-bnb',
    logo: 'bnb-pairs/DITTO-BNB.svg',
    name: 'DITTO-BNB LP',
    token: 'DITTO-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x470BC451810B312BBb1256f96B0895D95eA659B1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDITTO-BNB',
    earnedTokenAddress: '0x8FF55869e05492c127cEEc6A400D7e3803fC44E1',
    earnContractAddress: '0x8FF55869e05492c127cEEc6A400D7e3803fC44E1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-ditto-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['DITTO', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x233d91a0713155003fc4dce0afa871b508b3b715',
    network: 'bsc',
  },

  {
    id: 'auto-cake-old-eol',
    logo: 'single-assets/SMART.png',
    name: 'CAKE',
    token: 'CAKE',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoCake',
    earnedTokenAddress: '0x895CF1E805cBd86355caC101aE9B252c806Dc59b',
    earnContractAddress: '0x895CF1E805cBd86355caC101aE9B252c806Dc59b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['CAKE'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    network: 'bsc',
  },

  {
    id: 'auto-wbnb-old-eol',
    logo: 'single-assets/WBNB.svg',
    name: 'WBNB',
    token: 'WBNB',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoWbnb',
    earnedTokenAddress: '0x8136C5EE816fD80b80C9679e785F37EBdf2199eE',
    earnContractAddress: '0x8136C5EE816fD80b80C9679e785F37EBdf2199eE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBNB',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['BNB'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'auto-busd-old',
    logo: 'single-assets/BUSD.svg',
    name: 'BUSD',
    token: 'BUSD',
    tokenDescription: 'Venus (Autofarm)',
    tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBusd',
    earnedTokenAddress: '0xBd198f5132631e597518529268000A0199f07492',
    earnContractAddress: '0xBd198f5132631e597518529268000A0199f07492',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BUSD',
    oraclePrice: 0,
    status: 'deposits-paused',
    platform: 'Autofarm',
    assets: ['BUSD'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
    network: 'bsc',
  },

  {
    id: 'sponge-sponge-bnb',
    logo: 'bnb-pairs/SPG-BNB.svg',
    name: 'SPG-BNB LP',
    token: 'SPG-BNB LP',
    tokenDescription: 'Sponge',
    tokenAddress: '0xBB74572F3C00B4d18fAbeADb7d5984D7b3AE05d1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpongeSPG-BNB',
    earnedTokenAddress: '0x1B3564F698988804b5D05a2C10B1d71bE051662F',
    earnContractAddress: '0x1B3564F698988804b5D05a2C10B1d71bE051662F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sponge-sponge-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['SPG', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.sponge.finance/#/add/BNB/0x3aabcf53a1930a42e18d938c019e83ebee50a849',
    buyTokenUrl:
      'https://swap.sponge.finance/#/swap?outputCurrency=0x3aabcf53a1930a42e18d938c019e83ebee50a849',
    network: 'bsc',
  },

  {
    id: 'sponge-soak-bnb',
    logo: 'bnb-pairs/SOAK-BNB.svg',
    name: 'SOAK-BNB LP',
    token: 'SOAK-BNB LP',
    tokenDescription: 'Sponge',
    tokenAddress: '0xc8FCBA816eF9B0c5bc03d9178CfCc7EF785B4f2f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpongeSOAK-BNB',
    earnedTokenAddress: '0xE035Dce0E345Cbd46F3c0dCBf602f96c5F741813',
    earnContractAddress: '0xE035Dce0E345Cbd46F3c0dCBf602f96c5F741813',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sponge-soak-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['SOAK', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.sponge.finance/#/add/BNB/0x849233ff1aea15d80ef658b2871664c9ca994063',
    buyTokenUrl:
      'https://swap.sponge.finance/#/swap?outputCurrency=0x849233ff1aea15d80ef658b2871664c9ca994063',
    network: 'bsc',
  },

  {
    id: 'cake-sfp-bnb',
    logo: 'bnb-pairs/SFP-BNB.svg',
    name: 'SFP-BNB LP',
    token: 'SFP-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xcBe2cF3bd012e9C1ADE2Ee4d41DB3DaC763e78F3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSFP-BNB',
    earnedTokenAddress: '0xCa38d57755f29c9ad17AbB1A6ae059DdAD913A0e',
    earnContractAddress: '0xCa38d57755f29c9ad17AbB1A6ae059DdAD913A0e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-sfp-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['SFP', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb',
    network: 'bsc',
  },

  {
    id: 'nyacash-nyas-usdt',
    logo: 'single-assets/NYAS.png',
    name: 'NYAS-USDT NYAN-LP',
    token: 'NYAS-USDT NYAN-LP',
    tokenDescription: 'Nyanswop',
    tokenAddress: '0xd3179b44548f3309622B4F966503C1143e8840Ca',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooNyacashNYAS-BNB',
    earnedTokenAddress: '0x5ee82A50D1C7a1B599a5d4DA2f54AdB0d7a497a6',
    earnContractAddress: '0x5ee82A50D1C7a1B599a5d4DA2f54AdB0d7a497a6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'nyanswop-nyas-usdt',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['NYAS', 'USDT'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'monster-mss-busd',
    logo: 'single-assets/MSS.png',
    name: 'MSS-BUSD LP',
    token: 'MSS-BUSD LP',
    tokenDescription: 'PancakeSwap (Monster)',
    tokenAddress: '0x73601076A92E0D1bD81737B38B150D6842aa1aA1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMonsterMSS-BUSD',
    earnedTokenAddress: '0x3B6C7bC3BE5C60c875cF415A97e6539cC28ECa32',
    earnContractAddress: '0x3B6C7bC3BE5C60c875cF415A97e6539cC28ECa32',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'monster-mss-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['MSS', 'BUSD'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xacabd3f9b8f76ffd2724604185fa5afa5df25ac6',
    network: 'bsc',
  },

  {
    id: 'jul-juld',
    logo: 'single-assets/JULD.png',
    name: 'JULD',
    token: 'JULD',
    tokenDescription: 'JulSwap',
    tokenAddress: '0x5A41F637C3f7553dBa6dDC2D3cA92641096577ea',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulJULD',
    earnedTokenAddress: '0x90d8AB2731f937f9ec98d0bbe6D4CBD4D95473db',
    earnContractAddress: '0x90d8AB2731f937f9ec98d0bbe6D4CBD4D95473db',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'JULD',
    oraclePrice: 0,
    status: 'eol',
    platform: 'JulSwap',
    assets: ['JulD'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://julswap.com/#/swap?inputCurrency=BNB&outputCurrency=0x5a41f637c3f7553dba6ddc2d3ca92641096577ea',
    network: 'bsc',
  },

  {
    id: 'cake-bdo-bnb',
    logo: 'single-assets/BDO.png',
    name: 'BDO-BNB LP',
    token: 'BDO-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x74690f829fec83ea424ee1F1654041b2491A7bE9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBDO-BNB',
    earnedTokenAddress: '0x48Cb3540CB0BB4C6dDF638209e0631Ec85c1a477',
    earnContractAddress: '0x48Cb3540CB0BB4C6dDF638209e0631Ec85c1a477',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bdo-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['BDO', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
    network: 'bsc',
  },

  {
    id: 'kebab-kebab',
    logo: 'single-assets/KEBAB.png',
    name: 'KEBAB',
    token: 'KEBAB',
    tokenDescription: 'Kebab',
    tokenAddress: '0x7979F6C54ebA05E18Ded44C4F986F49a5De551c2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebab',
    earnedTokenAddress: '0xE2231D4ca4921Cb35680bac714C9d40C8d217494',
    earnContractAddress: '0xE2231D4ca4921Cb35680bac714C9d40C8d217494',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'KEBAB',
    oraclePrice: 0,
    status: 'active',
    platform: 'Kebab',
    assets: ['KEBAB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl:
      'https://swap.kebabfinance.com/#/swap?outputCurrency=0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
    network: 'bsc',
  },

  {
    id: 'kebab-kebab-busd',
    logo: 'busd-pairs/KEBAB-BUSD.png',
    name: 'KEBAB-BUSD LP',
    token: 'KEBAB-BUSD LP',
    tokenDescription: 'Kebab',
    tokenAddress: '0xD51bee2E0A3886289F6D229b6f30c0C2b34fC0Ec',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebabKEBAB-BUSD',
    earnedTokenAddress: '0xF0A7626eCcBee00aF144BB1f77CD187AF85BBf41',
    earnContractAddress: '0xF0A7626eCcBee00aF144BB1f77CD187AF85BBf41',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kebab-kebab-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Kebab',
    assets: ['KEBAB', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.kebabfinance.com/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
    buyTokenUrl:
      'https://swap.kebabfinance.com/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
    network: 'bsc',
  },

  {
    id: 'kebab-kebab-bnb',
    logo: 'bnb-pairs/KEBAB-BNB.png',
    name: 'KEBAB-BNB LP',
    token: 'KEBAB-BNB LP',
    tokenDescription: 'Kebab',
    tokenAddress: '0x25fd42D82d5c238ee7AA277261AA6CA5BDFE5CD4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebabKEBAB-BNB',
    earnedTokenAddress: '0x2Eb92E2882030B8e153370d208462D4dDf7041d2',
    earnContractAddress: '0x2Eb92E2882030B8e153370d208462D4dDf7041d2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kebab-kebab-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Kebab',
    assets: ['KEBAB', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.kebabfinance.com/#/add/BNB/0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
    buyTokenUrl:
      'https://swap.kebabfinance.com/#/swap?outputCurrency=0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
    network: 'bsc',
  },

  {
    id: 'cake-egld-bnb',
    logo: 'bnb-pairs/EGLD-BNB.svg',
    name: 'EGLD-BNB LP',
    token: 'EGLD-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x3Ef4952C7a9AfbE374EA02d1Bf5eD5a0015b7716',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeEGLD-BNB',
    earnedTokenAddress: '0x3d0bd3C73eF66C8b487A49f50410173C44261285',
    earnContractAddress: '0x3d0bd3C73eF66C8b487A49f50410173C44261285',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-egld-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['EGLD', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe',
    network: 'bsc',
  },

  {
    id: 'cake-mamzn-ust',
    logo: 'ust-pairs/MAMZN-UST.svg',
    name: 'MAMZN-UST LP',
    token: 'MAMZN-UST LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xc92Dc34665c8a21f98E1E38474580b61b4f3e1b9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeMAMZN-UST',
    earnedTokenAddress: '0x69009287479805E7372284835627564bad02107D',
    earnContractAddress: '0x69009287479805E7372284835627564bad02107D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-mamzn-ust',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['MAMZN', 'UST'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0x23396cf899ca06c4472205fc903bdb4de249d6fc/0x3947B992DC0147D2D89dF0392213781b04B25075',
    network: 'bsc',
  },

  {
    id: 'cake-mgoogl-ust',
    logo: 'ust-pairs/MGOOGL-UST.svg',
    name: 'MGOOGL-UST LP',
    token: 'MGOOGL-UST LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x852A68181f789AE6d1Da3dF101740a59A071004f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeMGOOGL-UST',
    earnedTokenAddress: '0xfBa61aC24B46c5B7298De02FBa8F577C1c5005e9',
    earnContractAddress: '0xfBa61aC24B46c5B7298De02FBa8F577C1c5005e9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-mgoogl-ust',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['MGOOGL', 'UST'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0x23396cf899ca06c4472205fc903bdb4de249d6fc/0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f',
    network: 'bsc',
  },

  {
    id: 'cake-mnflx-ust',
    logo: 'ust-pairs/MNFLX-UST.svg',
    name: 'MNFLX-UST LP',
    token: 'MNFLX-UST LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xF609ade3846981825776068a8eD7746470029D1f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeMNFLX-UST',
    earnedTokenAddress: '0x7185017145ea364163cCd708E0c78AC0297D6A94',
    earnContractAddress: '0x7185017145ea364163cCd708E0c78AC0297D6A94',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-mnflx-ust',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['MNFLX', 'UST'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0x23396cf899ca06c4472205fc903bdb4de249d6fc/0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc',
    network: 'bsc',
  },

  {
    id: 'cake-mtsla-ust',
    logo: 'ust-pairs/MTSLA-UST.svg',
    name: 'MTSLA-UST LP',
    token: 'MTSLA-UST LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xD5664D2d15cdffD597515f1c0D945c6c1D3Bf85B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeMTSLA-UST',
    earnedTokenAddress: '0x42BeB2855aa620C5c9dD073FA888c43c8a65c34f',
    earnContractAddress: '0x42BeB2855aa620C5c9dD073FA888c43c8a65c34f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-mtsla-ust',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['MTSLA', 'UST'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0x23396cf899ca06c4472205fc903bdb4de249d6fc/0xF215A127A196e3988C09d052e16BcFD365Cd7AA3',
    network: 'bsc',
  },

  {
    id: 'cake-wsote-bnb',
    logo: 'bnb-pairs/WSOTE-BNB.svg',
    name: 'WSOTE-BNB LP',
    token: 'WSOTE-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xFfb9E2d5ce4378F1a89b29bf53F80804CC078102',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeWSOTE-BNB',
    earnedTokenAddress: '0x5B6c3432B724Ce198B93769970f1dF36Ac5552F8',
    earnContractAddress: '0x5B6c3432B724Ce198B93769970f1dF36Ac5552F8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-wsote-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['WSOTE', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x541e619858737031a1244a5d0cd47e5ef480342c',
    network: 'bsc',
  },

  {
    id: 'cake-front-bnb',
    logo: 'bnb-pairs/FRONT-BNB.svg',
    name: 'FRONT-BNB LP',
    token: 'FRONT-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x36b7D2e5C7877392Fb17f9219efaD56F3D794700',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeFRONT-BNB',
    earnedTokenAddress: '0x1Ae7E76e2Eb74070774bbd9EAC75585452f24C23',
    earnContractAddress: '0x1Ae7E76e2Eb74070774bbd9EAC75585452f24C23',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-front-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['FRONT', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x928e55dab735aa8260af3cedada18b5f70c72f1b',
    network: 'bsc',
  },

  {
    id: 'cake-btcst-bnb',
    logo: 'bnb-pairs/BTCST-BNB.svg',
    name: 'BTCST-BNB LP',
    token: 'BTCST-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x91589786D36fEe5B27A5539CfE638a5fc9834665',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBTCST-BNB',
    earnedTokenAddress: '0xfC083Cb104b48924AB44C69537b1450B2e78eBD6',
    earnContractAddress: '0xfC083Cb104b48924AB44C69537b1450B2e78eBD6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-btcst-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['BTCST', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x78650b139471520656b9e7aa7a5e9276814a38e9',
    network: 'bsc',
  },

  {
    id: 'thugs-drugs-btri',
    logo: 'single-assets/DRUGS.png',
    name: 'DRUGS BTRI',
    token: 'DRUGS',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDrugsBTRI',
    earnedTokenAddress: '0xFAE4a35305a1dcED8303029ac05e51386f667ED8',
    earnContractAddress: '0xFAE4a35305a1dcED8303029ac05e51386f667ED8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DRUGS',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['DRUGS'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },
  {
    id: 'cake-ada-bnb',
    logo: 'bnb-pairs/ADA-BNB.svg',
    name: 'ADA-BNB LP',
    token: 'ADA-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xBA51D1AB95756ca4eaB8737eCD450cd8F05384cF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeADA-BNB',
    earnedTokenAddress: '0x1d23ecC0645B07791b7D99349e253ECEbe43f614',
    earnContractAddress: '0x1d23ecC0645B07791b7D99349e253ECEbe43f614',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-ada-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['ADA', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    network: 'bsc',
  },

  {
    id: 'cake-dot-bnb',
    logo: 'bnb-pairs/DOT-BNB.svg',
    name: 'DOT-BNB LP',
    token: 'DOT-BNB LP1',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xbCD62661A6b1DEd703585d3aF7d7649Ef4dcDB5c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDOT-BNB',
    earnedTokenAddress: '0xb8b7CAF72D75Ac8b71bB208b2D98a9dac9d5c90B',
    earnContractAddress: '0xb8b7CAF72D75Ac8b71bB208b2D98a9dac9d5c90B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-dot-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['DOT', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
    network: 'bsc',
  },

  {
    id: 'bakery-bake-busd',
    logo: 'busd-pairs/BAKE-BUSD.png',
    name: 'BAKE-BUSD BLP',
    token: 'BAKE-BUSD BLP',
    tokenDescription: 'Bakery',
    tokenAddress: '0x6E218EA042BeF40a8baF706b00d0f0A7b4fCE50a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBakeryBAKE-BUSD',
    earnedTokenAddress: '0x23293B8A7c65aF396Bda8Ff75E02c62f6db8f202',
    earnContractAddress: '0x23293B8A7c65aF396Bda8Ff75E02c62f6db8f202',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bakery-bake-busd',
    oraclePrice: 0,
    status: 'active',
    platform: 'Bakery',
    assets: ['BAKE', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.bakeryswap.org/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    buyTokenUrl:
      'https://www.bakeryswap.org/#/swap?inputCurrency=0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'jetfuel-vapor-bnb-eol',
    logo: 'bnb-pairs/VAPOR-BNB.png',
    name: 'VAPOR-BNB LP',
    token: 'VAPOR-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x01ffd4B8dE49442ab3EF755cC6bb57deEB6B871a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfVAPOR-BNB',
    earnedTokenAddress: '0x00EcbB8f9853dbC621b39ab205a148A26eAC3B11',
    earnContractAddress: '0x00EcbB8f9853dbC621b39ab205a148A26eAC3B11',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-vapor-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['VAPOR', 'BNB'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x86ac8a05cc4eed2bea32d271de50e2a3d129b6b6',
    network: 'bsc',
  },

  {
    id: 'cake-bdo-bnb-sbdo',
    logo: 'single-assets/SBDO.png',
    name: 'BDO-BNB LP (SBDO)',
    token: 'BDO-BNB LP (SBDO)',
    tokenDescription: 'PancakeSwap (bDollar)',
    tokenAddress: '0x74690f829fec83ea424ee1F1654041b2491A7bE9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBdollarBDO-BNB-SBDO',
    earnedTokenAddress: '0x9355af5ABD030E8BC2Dc63a38F1C956F1e150308',
    earnContractAddress: '0x9355af5ABD030E8BC2Dc63a38F1C956F1e150308',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bdo-bnb-sbdo',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BDO', 'BNB'],
    risks: [],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
    network: 'bsc',
  },

  {
    id: 'cake-bdo-bnb-old',
    logo: 'single-assets/BDO.png',
    name: 'BDO-BNB LP',
    token: 'BDO-BNB LP',
    tokenDescription: 'PancakeSwap (bDollar)',
    tokenAddress: '0x74690f829fec83ea424ee1F1654041b2491A7bE9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBdollarBDO-BNB',
    earnedTokenAddress: '0x2B19461954cCBa9e1b60bD0bC6453FeaC8aa1a17',
    earnContractAddress: '0x2B19461954cCBa9e1b60bD0bC6453FeaC8aa1a17',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bdo-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BDO', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
    network: 'bsc',
  },

  {
    id: 'cake-sbdo-busd',
    logo: 'single-assets/SBDO.png',
    name: 'SBDO-BUSD LP',
    token: 'SBDO-BUSD LP',
    tokenDescription: 'PancakeSwap (bDollar)',
    tokenAddress: '0xA0718093baa3E7AAE054eED71F303A4ebc1C076f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBdollarSBDO-BUSD',
    earnedTokenAddress: '0x9327029cf20B78e445397dAf0c4e9c19a931D62e',
    earnContractAddress: '0x9327029cf20B78e445397dAf0c4e9c19a931D62e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-sbdo-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['SBDO', 'BUSD'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x0d9319565be7f53cefe84ad201be3f40feae2740',
    network: 'bsc',
  },

  {
    id: 'cake-bdo-busd',
    logo: 'single-assets/SBDO.png',
    name: 'BDO-BUSD LP',
    token: 'BDO-BUSD LP',
    tokenDescription: 'PancakeSwap (bDollar)',
    tokenAddress: '0xc5b0d73A7c0E4eaF66baBf7eE16A2096447f7aD6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBdollarBDO-BUSD',
    earnedTokenAddress: '0x0fA31301EaA9633b3e4F7dF22F7543115759cf5a',
    earnContractAddress: '0x0fA31301EaA9633b3e4F7dF22F7543115759cf5a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bdo-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BDO', 'BUSD'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'venus-beth',
    logo: 'single-assets/BETH.png',
    name: 'BETH',
    token: 'BETH',
    tokenDescription: 'Venus',
    tokenAddress: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusBETH',
    earnedTokenAddress: '0xB38D32336538559Ce6680952B0Be4917ed015c39',
    earnContractAddress: '0xB38D32336538559Ce6680952B0Be4917ed015c39',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BETH',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['BETH'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x250632378e573c6be1ac2f97fcdf00515d0aa91b',
    network: 'bsc',
  },

  {
    id: 'venus-dai',
    logo: 'single-assets/DAI.svg',
    name: 'DAI',
    token: 'DAI',
    tokenDescription: 'Venus',
    tokenAddress: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusDAI',
    earnedTokenAddress: '0x813aDbc7b9a9822FA37510F52aD14bDBc45aA306',
    earnContractAddress: '0x813aDbc7b9a9822FA37510F52aD14bDBc45aA306',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DAI',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['DAI'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    network: 'bsc',
  },

  {
    id: 'venus-fil',
    logo: 'single-assets/FIL.svg',
    name: 'FIL',
    token: 'FIL',
    tokenDescription: 'Venus',
    tokenAddress: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusFIL',
    earnedTokenAddress: '0x8B80E58328aEF3796D70D29F65d516d29ACD944d',
    earnContractAddress: '0x8B80E58328aEF3796D70D29F65d516d29ACD944d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'FIL',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['FIL'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
    network: 'bsc',
  },
  {
    id: 'cake-balbt-bnb',
    logo: 'bnb-pairs/BALBT-BNB.svg',
    name: 'bALBT-BNB LP',
    token: 'bALBT-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xbe14f3a89A4F7f279Af9d99554cf12E8C29dB921',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakebALBT-BNB',
    earnedTokenAddress: '0xc2c792807A32fB9F9f985749Ef90673D8e1Bad44',
    earnContractAddress: '0xc2c792807A32fB9F9f985749Ef90673D8e1Bad44',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-balbt-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['bALBT', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x72faa679e1008ad8382959ff48e392042a8b06f7',
    network: 'bsc',
  },
  {
    id: 'bakery-btc-bnb',
    logo: 'bnb-pairs/BTC-BNB.png',
    name: 'BTC-BNB BLP',
    token: 'BTC-BNB BLP',
    tokenDescription: 'Bakery',
    tokenAddress: '0x58521373474810915b02FE968D1BCBe35Fc61E09',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBakeryBTC-BNB',
    earnedTokenAddress: '0xeD4ea30c755676C07d3e0e0f74Ff84C0193B4551',
    earnContractAddress: '0xeD4ea30c755676C07d3e0e0f74Ff84C0193B4551',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bakery-btc-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Bakery',
    assets: ['BTCB', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://www.bakeryswap.org/#/add/ETH/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    buyTokenUrl:
      'https://www.bakeryswap.org/#/swap?outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    network: 'bsc',
  },

  {
    id: 'street-bhc-bnb',
    logo: 'bnb-pairs/BHC-BNB.png',
    name: 'BHC-BNB TLP',
    token: 'BHC-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xd46E7f33f8788f87D6017074dC4e4d781D3df91E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBHC-BNB',
    earnedTokenAddress: '0x7EED74dacB305C03A8f624282d6AEF15f1CD3777',
    earnContractAddress: '0x7EED74dacB305C03A8f624282d6AEF15f1CD3777',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-bhc-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BHC', 'BNB'],
    risks: [],
    callFee: 1,
    network: 'bsc',
  },

  {
    id: 'cake-unfi-bnb',
    logo: 'bnb-pairs/UNFI-BNB.svg',
    name: 'UNFI-BNB LP',
    token: 'UNFI-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xbEA35584b9a88107102ABEf0BDeE2c4FaE5D8c31',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeUNFI-BNB',
    earnedTokenAddress: '0x03090F5E40934E0F1AAe73b2F1cFD240DBD7Af5E',
    earnContractAddress: '0x03090F5E40934E0F1AAe73b2F1cFD240DBD7Af5E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-unfi-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['UNFI', 'BNB'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x728c5bac3c3e370e372fc4671f9ef6916b814d8b',
    network: 'bsc',
  },

  {
    id: 'cake-reef-bnb',
    logo: 'bnb-pairs/REEF-BNB.svg',
    name: 'REEF-BNB LP',
    token: 'REEF-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x58B58cab6C5cF158f63A2390b817710826d116D0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeREEF-BNB',
    earnedTokenAddress: '0x6E0F2e199FC10B3F354C6825fCbC24a5de96B4bF',
    earnContractAddress: '0x6E0F2e199FC10B3F354C6825fCbC24a5de96B4bF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-reef-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['REEF', 'BNB'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e',
    network: 'bsc',
  },

  {
    id: 'cake-nar-cake',
    logo: 'cake-pairs/NAR-CAKE.svg',
    name: 'NAR-CAKE LP',
    token: 'NAR-CAKE LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x745C4fD226E169d6da959283275A8E0EcDd7F312',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeNAR-CAKE',
    earnedTokenAddress: '0xFaa95d27A91eb2305bD1B4b12a9cE564874eE349',
    earnContractAddress: '0xFaa95d27A91eb2305bD1B4b12a9cE564874eE349',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-nar-cake',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['NAR', 'CAKE'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/https://v1exchange.pancakeswap.finance/#/remove/0xa1303e6199b319a891b79685f0537d289af1fc83/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    network: 'bsc',
  },
  {
    id: 'cake-nya-cake',
    logo: 'cake-pairs/NYA-CAKE.svg',
    name: 'NYA-CAKE LP',
    token: 'NYA-CAKE LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x2730bf486d658838464A4ef077880998D944252d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeNYA-CAKE',
    earnedTokenAddress: '0x5f640a217026FeFD0C4F1E581b44794c3d5F4638',
    earnContractAddress: '0x5f640a217026FeFD0C4F1E581b44794c3d5F4638',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-nya-cake',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['NYA', 'CAKE'],
    risks: [],
    callFee: 1,
    network: 'bsc',
  },

  {
    id: 'thugs-drugs-drugs',
    logo: 'single-assets/DRUGS.png',
    name: 'DRUGS v2',
    token: 'DRUGS v2',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDrugsGunsV2',
    earnedTokenAddress: '0x8AE31751A226B0C5357a377E53B6DB12bDF5e64d',
    earnContractAddress: '0x8AE31751A226B0C5357a377E53B6DB12bDF5e64d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DRUGS',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['DRUGS'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'thugs-drugs-bhc',
    logo: 'single-assets/DRUGS.png',
    name: 'DRUGS BHC',
    token: 'DRUGS',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDrugsBHC',
    earnedTokenAddress: '0xE7408598DB157Cd4A24Bc26B579910AF36a72fad',
    earnContractAddress: '0xE7408598DB157Cd4A24Bc26B579910AF36a72fad',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DRUGS',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['DRUGS'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'thugs-drugs-jgn',
    logo: 'single-assets/DRUGS.png',
    name: 'DRUGS JGN',
    token: 'DRUGS',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDrugsJGN',
    earnedTokenAddress: '0x40e39743dBcb30c7Fc4152F57F29Cd22D9fc7932',
    earnContractAddress: '0x40e39743dBcb30c7Fc4152F57F29Cd22D9fc7932',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DRUGS',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['DRUGS'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'jetfuel-fuel-bnb-ss-eol',
    logo: 'bnb-pairs/FUEL-BNB.png',
    name: 'FUEL-BNB TLP',
    token: 'FUEL-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xd26092c6ce6FB359302A9453dc0c7f312794Ef7E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfFUEL-BNB-SS',
    earnedTokenAddress: '0xda643fBC5faA4FB3a95c62dBF729fE2B0Ff4F577',
    earnContractAddress: '0xda643fBC5faA4FB3a95c62dBF729fE2B0Ff4F577',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-fuel-bnb-ss',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['FUEL', 'BNB'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2090c8295769791ab7a3cf1cc6e0aa19f35e441a',
    network: 'bsc',
  },

  {
    id: 'jetfuel-fuel-bnb-pcs-eol',
    logo: 'bnb-pairs/FUEL-BNB.png',
    name: 'FUEL-BNB LP',
    token: 'FUEL-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x3763A3263CEaca5e7Cc1Bc22A43920bAd9f743Cd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfFUEL-BNB-PCS',
    earnedTokenAddress: '0x9d3Aa54172D49659Ee9948d1b7D602989d39Ffa6',
    earnContractAddress: '0x9d3Aa54172D49659Ee9948d1b7D602989d39Ffa6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-fuel-bnb-pcs',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['FUEL', 'BNB'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A',
    network: 'bsc',
  },

  {
    id: 'jetfuel-bhc-bnb-eol',
    logo: 'bnb-pairs/BHC-BNB.png',
    name: 'BHC-BNB LP',
    token: 'BHC-BNB LP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xd46E7f33f8788f87D6017074dC4e4d781D3df91E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfBHC-BNB',
    earnedTokenAddress: '0xfb62a8d9d162632C5AaD94f6bf353a19AeCd2855',
    earnContractAddress: '0xfb62a8d9d162632C5AaD94f6bf353a19AeCd2855',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-bhc-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BHC', 'BNB'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4',
    network: 'bsc',
  },

  {
    id: 'jetfuel-eth-bnb-eol',
    logo: 'bnb-pairs/ETH-BNB.svg',
    name: 'ETH-BNB LP',
    token: 'ETH-BNB LP',
    tokenDescription: 'PancakeSwap (Fuel)',
    tokenAddress: '0x70D8929d04b60Af4fb9B58713eBcf18765aDE422',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfETH-BNB',
    earnedTokenAddress: '0x25Fb99cf1DCe844cD823bD1e8FB0c1DCc85dAD0E',
    earnContractAddress: '0x25Fb99cf1DCe844cD823bD1e8FB0c1DCc85dAD0E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-eth-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['ETH', 'BNB'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    network: 'bsc',
  },

  {
    id: 'jetfuel-cake-bnb-eol',
    logo: 'bnb-pairs/CAKE-BNB.svg',
    name: 'CAKE-BNB LP',
    token: 'CAKE-BNB LP',
    tokenDescription: 'PancakeSwap (Fuel)',
    tokenAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfCAKE-BNB',
    earnedTokenAddress: '0xC0766D0F482c7174274bE653dcB0B80c0436dFCc',
    earnContractAddress: '0xC0766D0F482c7174274bE653dcB0B80c0436dFCc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-cake-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['CAKE', 'BNB'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    network: 'bsc',
  },

  {
    id: 'jetfuel-uni-bnb-eol',
    logo: 'bnb-pairs/UNI-BNB.svg',
    name: 'UNI-BNB LP',
    token: 'UNI-BNB LP',
    tokenDescription: 'PancakeSwap (Fuel)',
    tokenAddress: '0x4269e7F43A63CEA1aD7707Be565a94a9189967E9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfUNI-BNB',
    earnedTokenAddress: '0xD96c8527d20acfa7D5D2054Bfb375fd0cB428322',
    earnContractAddress: '0xD96c8527d20acfa7D5D2054Bfb375fd0cB428322',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-uni-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['UNI', 'BNB'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
    network: 'bsc',
  },

  {
    id: 'jetfuel-link-bnb-eol',
    logo: 'bnb-pairs/LINK-BNB.svg',
    name: 'LINK-BNB LP',
    token: 'LINK-BNB LP',
    tokenDescription: 'PancakeSwap (Fuel)',
    tokenAddress: '0xaeBE45E3a03B734c68e5557AE04BFC76917B4686',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfLINK-BNB',
    earnedTokenAddress: '0x73fA0bbAb2e3288123674200553fBfCb94037Fcb',
    earnContractAddress: '0x73fA0bbAb2e3288123674200553fBfCb94037Fcb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-link-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['LINK', 'BNB'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    network: 'bsc',
  },

  {
    id: 'jetfuel-busd-bnb-eol',
    logo: 'bnb-pairs/BUSD-BNB.svg',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB LP',
    tokenDescription: 'PancakeSwap (Fuel)',
    tokenAddress: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfBUSD-BNB',
    earnedTokenAddress: '0x8af2D7289A093CF1019D9e20554F6c7aD5791A94',
    earnContractAddress: '0x8af2D7289A093CF1019D9e20554F6c7aD5791A94',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-busd-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BUSD', 'BNB'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'jetfuel-usdt-bnb-eol',
    logo: 'bnb-pairs/USDT-BNB.svg',
    name: 'USDT-BNB LP',
    token: 'USDT-BNB LP',
    tokenDescription: 'PancakeSwap (Fuel)',
    tokenAddress: '0x20bCC3b8a0091dDac2d0BC30F68E6CBb97de59Cd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfUSDT-BNB',
    earnedTokenAddress: '0x629C4ca0F3492476FAe31dE6FD456D7da1dfCEb7',
    earnContractAddress: '0x629C4ca0F3492476FAe31dE6FD456D7da1dfCEb7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-usdt-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['USDT', 'BNB'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955',
    network: 'bsc',
  },

  {
    id: 'cake-smart-eol',
    logo: 'single-assets/SMART.png',
    name: 'CAKE SMART',
    token: 'CAKE',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://docs.beefy.finance/beefyfinance/products/pancake-swap#cake-smart',
    earnedToken: 'mooCakeSmart',
    earnedTokenAddress: '0xB0BDBB9E507dBF55f4aC1ef6ADa3E216202e06FD',
    earnContractAddress: '0xB0BDBB9E507dBF55f4aC1ef6ADa3E216202e06FD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['CAKE'],
    risks: [],
    callFee: 0.5,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    network: 'bsc',
  },

  {
    id: 'venus-bch',
    logo: 'single-assets/BCH.svg',
    name: 'BCH',
    token: 'BCH',
    tokenDescription: 'Venus',
    tokenAddress: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusBCH',
    earnedTokenAddress: '0xf19878DE084C3c9753bB0DAD8aC5b9070fb7813c',
    earnContractAddress: '0xf19878DE084C3c9753bB0DAD8aC5b9070fb7813c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BCH',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['BCH'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
    network: 'bsc',
  },

  {
    id: 'venus-dot',
    logo: 'single-assets/DOT.png',
    name: 'DOT',
    token: 'DOT',
    tokenDescription: 'Venus',
    tokenAddress: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusDOT',
    earnedTokenAddress: '0x2211191ff72505ED54Aa6aD3F6Fcc72c87CAb338',
    earnContractAddress: '0x2211191ff72505ED54Aa6aD3F6Fcc72c87CAb338',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DOT',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['DOT'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    network: 'bsc',
  },

  {
    id: 'venus-link',
    logo: 'single-assets/LINK.png',
    name: 'LINK',
    token: 'LINK',
    tokenDescription: 'Venus',
    tokenAddress: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusLINK',
    earnedTokenAddress: '0x8da7167860EDfc2bFfd790f217AB5e398803Bbc8',
    earnContractAddress: '0x8da7167860EDfc2bFfd790f217AB5e398803Bbc8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'LINK',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['LINK'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    network: 'bsc',
  },

  {
    id: 'street-twt-bnb',
    logo: 'bnb-pairs/TWT-BNB.svg',
    name: 'TWT-BNB TLP',
    token: 'TWT-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xf7f21A56B19546A77EABABf23d8dca726CaF7577',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetTWT-BNB',
    earnedTokenAddress: '0x23f81b26e7dfB44832f627Ee533399285a11e711',
    earnContractAddress: '0x23f81b26e7dfB44832f627Ee533399285a11e711',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-twt-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['TWT', 'BNB'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0x4b0f1812e5df2a09796481ff14017e6005508003/BNB',
    network: 'bsc',
  },
  {
    id: 'cake-fil-bnb',
    logo: 'bnb-pairs/FIL-BNB.svg',
    name: 'FIL-BNB LP',
    token: 'FIL-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x35FE9787F0eBF2a200BAc413D3030CF62D312774',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeFIL-BNB',
    earnedTokenAddress: '0xA74b54B24106B008881e627EC57D9629C2fdA5e2',
    earnContractAddress: '0xA74b54B24106B008881e627EC57D9629C2fdA5e2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-fil-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['FIL', 'BNB'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
    network: 'bsc',
  },

  {
    id: 'cake-atom-bnb',
    logo: 'bnb-pairs/ATOM-BNB.svg',
    name: 'ATOM-BNB LP',
    token: 'ATOM-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x2333c77FC0B2875c11409cdCD3C75D42D402E834',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeATOM-BNB',
    earnedTokenAddress: '0x4884cD2C9F11Dc9e8f90d177576c46fdbd1b7641',
    earnContractAddress: '0x4884cD2C9F11Dc9e8f90d177576c46fdbd1b7641',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-atom-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['ATOM', 'BNB'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x0eb3a705fc54725037cc9e008bdede697f62f335',
    network: 'bsc',
  },

  {
    id: 'cake-vai-busd',
    logo: 'busd-pairs/VAI-BUSD.svg',
    name: 'VAI-BUSD LP',
    token: 'VAI-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xfF17ff314925Dff772b71AbdFF2782bC913B3575',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeVAI-BUSD',
    earnedTokenAddress: '0xa40488536B1BAeECA9D2277Fde28394D3c04aD73',
    earnContractAddress: '0xa40488536B1BAeECA9D2277Fde28394D3c04aD73',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-vai-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['VAI', 'BUSD'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0x4bd17003473389a42daf6a0a729f6fdb328bbbd7/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'cake-broobee-cake',
    logo: 'cake-pairs/BROOBEE-CAKE.svg',
    name: 'BROOBEE-CAKE LP',
    token: 'BROOBEE-CAKE LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x970858016C963b780E06f7DCfdEf8e809919BcE8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBROOBEE-CAKE',
    earnedTokenAddress: '0x4CcF7dC91389396801ec1f4A5E6cC675549f39E6',
    earnContractAddress: '0x4CcF7dC91389396801ec1f4A5E6cC675549f39E6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-broobee-cake',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['bROOBEE', 'CAKE'],
    risks: [],
    callFee: 1,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    network: 'bsc',
  },

  {
    id: 'venus-bnb',
    logo: 'single-assets/BNB.png',
    name: 'BNB',
    token: 'BNB',
    tokenDescription: 'Venus',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusBNB',
    earnedTokenAddress: '0x6BE4741AB0aD233e4315a10bc783a7B923386b71',
    earnContractAddress: '0x6BE4741AB0aD233e4315a10bc783a7B923386b71',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBNB',
    oraclePrice: 0,
    status: 'active',
    platform: 'Venus',
    assets: ['BNB'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    callFee: 1,
    withdrawalFee: '0.05%',
    network: 'bsc',
  },

  {
    id: 'venus-wbnb',
    logo: 'single-assets/WBNB.svg',
    name: 'WBNB',
    token: 'WBNB',
    tokenDescription: 'Venus',
    tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusBNB',
    earnedTokenAddress: '0x6BE4741AB0aD233e4315a10bc783a7B923386b71',
    earnContractAddress: '0x6BE4741AB0aD233e4315a10bc783a7B923386b71',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBNB',
    oraclePrice: 0,
    status: 'active',
    platform: 'Venus',
    assets: ['BNB'],
    risks: [
      'COMPLEXITY_MID',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'Lending',
    callFee: 1,
    withdrawalFee: '0.05%',
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    network: 'bsc',
  },

  {
    id: 'venus-sxp',
    logo: 'single-assets/SXP.svg',
    name: 'SXP',
    token: 'SXP',
    tokenDescription: 'Venus',
    tokenAddress: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusSXP',
    earnedTokenAddress: '0x9aaD247bAc2AFc3027256aD0Cc09A27551069bd6',
    earnContractAddress: '0x9aaD247bAc2AFc3027256aD0Cc09A27551069bd6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'SXP',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['SXP'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
    network: 'bsc',
  },

  {
    id: 'venus-usdc',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Venus',
    tokenAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusUSDC',
    earnedTokenAddress: '0x07D478b679C6c7F4222388856f53F8dca7E7b378',
    earnContractAddress: '0x07D478b679C6c7F4222388856f53F8dca7E7b378',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['USDC'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    network: 'bsc',
  },

  {
    id: 'venus-usdt',
    logo: 'single-assets/USDT.svg',
    name: 'USDT',
    token: 'USDT',
    tokenDescription: 'Venus',
    tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusUSDT',
    earnedTokenAddress: '0x1997D7cF0A63A374A853ca9d5b1dAcCFeCa5cEb8',
    earnContractAddress: '0x1997D7cF0A63A374A853ca9d5b1dAcCFeCa5cEb8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['USDT'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955',
    network: 'bsc',
  },

  {
    id: 'venus-busd',
    logo: 'single-assets/BUSD.svg',
    name: 'BUSD',
    token: 'BUSD',
    tokenDescription: 'Venus',
    tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusBUSD',
    earnedTokenAddress: '0x2d3d152b3b94c0E0B217AC5bc1115E3D8b8018F9',
    earnContractAddress: '0x2d3d152b3b94c0E0B217AC5bc1115E3D8b8018F9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BUSD',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['BUSD'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'venus-ltc',
    logo: 'single-assets/LTC.svg',
    name: 'LTC',
    token: 'LTC',
    tokenDescription: 'Venus',
    tokenAddress: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusLTC',
    earnedTokenAddress: '0xd61420f20e945d0B617fEdA5971f692A3DCE711c',
    earnContractAddress: '0xd61420f20e945d0B617fEdA5971f692A3DCE711c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'LTC',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['LTC'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
    network: 'bsc',
  },

  {
    id: 'venus-xrp',
    logo: 'single-assets/XRP.svg',
    name: 'XRP',
    token: 'XRP',
    tokenDescription: 'Venus',
    tokenAddress: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusXRP',
    earnedTokenAddress: '0x3eb560209B438d4e23833aFf6751fcf0B810F60b',
    earnContractAddress: '0x3eb560209B438d4e23833aFf6751fcf0B810F60b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'XRP',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Venus',
    assets: ['XRP'],
    risks: [],
    callFee: 1,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    network: 'bsc',
  },

  {
    id: 'cake-alpha-bnb',
    logo: 'bnb-pairs/ALPHA-BNB.svg',
    name: 'ALPHA-BNB LP',
    token: 'ALPHA-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x4e0f3385d932F7179DeE045369286FFa6B03d887',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeALPHA-BNB',
    earnedTokenAddress: '0xB1B91AA761F552FEF29A50643D0F93690082960F',
    earnContractAddress: '0xB1B91AA761F552FEF29A50643D0F93690082960F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-alpha-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['ALPHA', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xa1faa113cbe53436df28ff0aee54275c13b40975',
    network: 'bsc',
  },

  {
    id: 'cake-twt-bnb',
    logo: 'bnb-pairs/TWT-BNB.svg',
    name: 'TWT-BNB LP',
    token: 'TWT-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x610e7a287c27dfFcaC0F0a94f547Cc1B770cF483',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeTWT-BNB',
    earnedTokenAddress: '0x3b2c93f9455786e74C68648EaaB09B20ce557537',
    earnContractAddress: '0x3b2c93f9455786e74C68648EaaB09B20ce557537',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-twt-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['TWT', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x4b0f1812e5df2a09796481ff14017e6005508003',
    network: 'bsc',
  },

  {
    id: 'cake-xvs-bnb',
    logo: 'bnb-pairs/XVS-BNB.svg',
    name: 'XVS-BNB LP',
    token: 'XVS-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x41182c32F854dd97bA0e0B1816022e0aCB2fc0bb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeXVS-BNB',
    earnedTokenAddress: '0xbB63c8858b64a856d79A80575Df60867622E4782',
    earnContractAddress: '0xbB63c8858b64a856d79A80575Df60867622E4782',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-xvs-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['XVS', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
    network: 'bsc',
  },

  // Narwhal

  {
    id: 'narwhal-gold-bnb',
    logo: 'bnb-pairs/GOLD-BNB.png',
    name: 'GOLD-BNB NLP',
    token: 'GOLD-BNB NLP',
    tokenDescription: 'Narwhal',
    tokenAddress: '0x903903A2aa2A222360D8f3c4A7205b933Da60e95',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooNarwhalGOLD-BNB',
    earnedTokenAddress: '0x179701FD814F17838605c1990EDA96C1EbeC984e',
    earnContractAddress: '0x179701FD814F17838605c1990EDA96C1EbeC984e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'narwhal-gold-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Other',
    assets: ['GOLD', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    callFee: 1,
    addLiquidityUrl:
      'https://narwhalswap.org/#/page/add/0x8f4087cb09e0f378f4278a314c94a636665de24b/BNB',
    buyTokenUrl:
      'https://narwhalswap.org/#/page/swap?outputCurrency=0x8f4087cb09e0f378f4278a314c94a636665de24b&outputCurrency=BNB',
    network: 'bsc',
  },

  {
    id: 'bakery-bake',
    logo: 'single-assets/BAKE.png',
    name: 'BAKE',
    token: 'BAKE',
    tokenDescription: 'Bakery',
    tokenAddress: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBakeryBAKE',
    earnedTokenAddress: '0xaC112E7744C129ae54E65F5D2cb4eA472E08eA0B',
    earnContractAddress: '0xaC112E7744C129ae54E65F5D2cb4eA472E08eA0B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BAKE',
    oraclePrice: 0,
    status: 'active',
    platform: 'Bakery',
    assets: ['BAKE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    callFee: 0.5,
    buyTokenUrl:
      'https://bakeryswap.org/#/swap?outputCurrency=0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    network: 'bsc',
  },

  {
    id: 'bakery-bake-bnb',
    logo: 'bnb-pairs/BAKE-BNB.png',
    name: 'BAKE-BNB BLP',
    token: 'BAKE-BNB BLP',
    tokenDescription: 'Bakery',
    tokenAddress: '0xc2Eed0F5a0dc28cfa895084bC0a9B8B8279aE492',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBakeryBAKE-BNB',
    earnedTokenAddress: '0x3e1B60EB79b17d41F8E268936d853A43BEA24259',
    earnContractAddress: '0x3e1B60EB79b17d41F8E268936d853A43BEA24259',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bakery-bake-bnb',
    oraclePrice: 0,
    status: 'active',
    platform: 'Bakery',
    assets: ['BAKE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    callFee: 0.5,
    addLiquidityUrl:
      'https://www.bakeryswap.org/#/add/ETH/0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    buyTokenUrl:
      'https://bakeryswap.org/#/swap?outputCurrency=0x072e3d0d4f14dd82a16b0a33678c6104e9009ed9',
    network: 'bsc',
  },

  {
    id: 'street-nar-bnb',
    logo: 'bnb-pairs/NAR-BNB.png',
    name: 'NAR-BNB TLP',
    token: 'NAR-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x1389003bcb228586648721A4F6187004dAE01c51',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetNAR-BNB',
    earnedTokenAddress: '0x06fb2e27155c2a551aF34E80aE67f569673dDD73',
    earnContractAddress: '0x06fb2e27155c2a551aF34E80aE67f569673dDD73',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-nar-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['NAR', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xa1303e6199b319a891b79685f0537d289af1fc83/BNB',
    network: 'bsc',
  },
  {
    id: 'street-bifi-bnb',
    logo: 'bnb-pairs/BIFI-BNB.png',
    name: 'BIFI-BNB TLP',
    token: 'BIFI-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x223B53b64AA5F87D2De194a15701Fc0bC7474A31',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBIFI-BNB',
    earnedTokenAddress: '0x29C36e5fcB1dC4a3D4B46C00F82b2cee39c5540e',
    earnContractAddress: '0x29C36e5fcB1dC4a3D4B46C00F82b2cee39c5540e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-bifi-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BIFI', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xCa3F508B8e4Dd382eE878A314789373D80A5190A/BNB',
    network: 'bsc',
  },

  {
    id: 'street-drugs-bnb',
    logo: 'bnb-pairs/DRUGS-BNB.png',
    name: 'DRUGS-BNB LP v2',
    token: 'DRUGS-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x421DF185Ff87bc5f19bd5a90102A51452b70c4A4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetDRUGS-BNBV2',
    earnedTokenAddress: '0x9D729179E429153a6aca26389e6a9b625431C79B',
    earnContractAddress: '0x9D729179E429153a6aca26389e6a9b625431C79B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-drugs-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['DRUGS', 'BNB'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'street-thugs-bnb',
    logo: 'bnb-pairs/THUGS-BNB.png',
    name: 'THUGS-BNB LP v2',
    token: 'THUGS-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x9354caB0F1083135439B23aA6c364329e578F39a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetTHUGS-BNBV2',
    earnedTokenAddress: '0xe789711992020575e07381fdb3dFDc251Ec4d027',
    earnContractAddress: '0xe789711992020575e07381fdb3dFDc251Ec4d027',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-thugs-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['THUGS', 'BNB'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'street-cred-bnb',
    logo: 'bnb-pairs/CRED-BNB.png',
    name: 'CRED-BNB LP',
    token: 'CRED-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x9CBE4F200b474a1C33bfFe7c6e531e1D47ECab46',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetCRED-BNB',
    earnedTokenAddress: '0x0adc208151e6b7AE1D884b64fDA662d10B4F6174',
    earnContractAddress: '0x0adc208151e6b7AE1D884b64fDA662d10B4F6174',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-cred-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['CRED', 'BNB'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'street-guns-bnb',
    logo: 'bnb-pairs/GUNS-BNB.png',
    name: 'GUNS-BNB LP v2',
    token: 'GUNS-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xa5e5eeB6b51fa0fDc59915C81d75C83470BFA4f0',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/thugs#farming-drugs-with-guns-bnb-lp',
    earnedToken: 'mooStreetGUNS-BNBV2',
    earnedTokenAddress: '0x68aE2aB6448884714DDf11C8d55ea7578C65D502',
    earnContractAddress: '0x68aE2aB6448884714DDf11C8d55ea7578C65D502',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-guns-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['GUNS', 'BNB'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'street-busd-bnb',
    logo: 'bnb-pairs/BUSD-BNB.svg',
    name: 'BUSD-BNB LP v2',
    token: 'BUSD-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xf2e4E3F9B58b3eDaC88Ad11D689a23f3119a782D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBUSD-BNBV2',
    earnedTokenAddress: '0x2091b25f02275a4D791075f83c904Fa5Fb96B5A7',
    earnContractAddress: '0x2091b25f02275a4D791075f83c904Fa5Fb96B5A7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-busd-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BUSD', 'BNB'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'street-panacakelp-thugs-bnb',
    logo: 'bnb-pairs/THUGS-BNB.png',
    name: 'THUGS-BNB Pancake LP v2',
    token: 'THUGS-BNB Pancake LP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x40257481f09dB2211a3274B34554Bbb00B0a7Fb1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetPancakeLP-THUGS-BNBV2',
    earnedTokenAddress: '0x7A99078ba8960871233377C7aE6B5d2A61229A43',
    earnContractAddress: '0x7A99078ba8960871233377C7aE6B5d2A61229A43',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-panacakelp-thugs-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['THUGS', 'BNB'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'street-dai-bnb',
    logo: 'bnb-pairs/DAI-BNB.svg',
    name: 'DAI-BNB LP',
    token: 'DAI-BNB LP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xed8Ecb790Be568461d85Cf82C386E51124E46a52',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetDAI-BNB',
    earnedTokenAddress: '0x3CAd2fF669865359b80a2d948A10e1810f7486D7',
    earnContractAddress: '0x3CAd2fF669865359b80a2d948A10e1810f7486D7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-dai-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['DAI', 'BNB'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'street-bliq-bnb',
    logo: 'bnb-pairs/BLIQ-BNB.png',
    name: 'BLIQ-BNB LP',
    token: 'BLIQ-BNB LP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xe835ed82C7d9d018f8F5173EECfBb56f29f38570',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBLIQ-BNB',
    earnedTokenAddress: '0x84d95337c83B84688DFE678117e1539786f8c4Cb',
    earnContractAddress: '0x84d95337c83B84688DFE678117e1539786f8c4Cb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-bliq-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BLIQ', 'BNB'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'street-drugs-bnb-v1',
    logo: 'bnb-pairs/DRUGS-BNB.png',
    name: 'DRUGS-BNB LP v1',
    token: 'DRUGS-BNB LP v1',
    tokenDescription: 'Please migrate to v2',
    tokenAddress: '0x5BcE5d58682f15541EFc72d8f56C7d6221d541f1',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/thugs#farming-drugs-with-drugs-bnb-lp',
    earnedToken: 'mooStreetDRUGS-BNB',
    earnedTokenAddress: '0xa4aB709f5eB75f3213734fC227D9e27352d1DB6c',
    earnContractAddress: '0xa4aB709f5eB75f3213734fC227D9e27352d1DB6c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-drugs-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['DRUGS', 'BNB'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },
  {
    id: 'street-guns-bnb-v1',
    logo: 'bnb-pairs/GUNS-BNB.png',
    name: 'GUNS-BNB LP v1',
    token: 'GUNS-BNB LP v1',
    tokenDescription: 'Please migrate to v2',
    tokenAddress: '0xa5e5eeB6b51fa0fDc59915C81d75C83470BFA4f0',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/thugs#farming-drugs-with-guns-bnb-lp',
    earnedToken: 'mooStreetGUNS-BNB',
    earnedTokenAddress: '0xA2bcB1D7955AEa2F5B93C308E199337a77E7bAd4',
    earnContractAddress: '0xA2bcB1D7955AEa2F5B93C308E199337a77E7bAd4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-guns-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['GUNS', 'BNB'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  //
  // < ---- > PANCAKE < ---- >
  //
  {
    id: 'cake-cake-eol',
    logo: 'single-assets/CAKE.png',
    name: 'CAKE',
    token: 'CAKE',
    tokenDescription: 'Please use Smart Cake',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-cake',
    earnedToken: 'mooCake',
    earnedTokenAddress: '0xc713ca7cb8edfE238ea652D772d41dde47A9a62d',
    earnContractAddress: '0xc713ca7cb8edfE238ea652D772d41dde47A9a62d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['CAKE'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'cake-twt',
    logo: 'single-assets/cakeTWT.png',
    name: 'CAKE TWT v2',
    token: 'CAKE',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-twt-with-cake',
    earnedToken: 'mooCakeTWTV2',
    earnedTokenAddress: '0x260879777DB293541DbC9a27c3C2fAD9c9bb48D3',
    earnContractAddress: '0x260879777DB293541DbC9a27c3C2fAD9c9bb48D3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['CAKE'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'cake-hard',
    logo: 'single-assets/cakeHARD.png',
    name: 'CAKE HARD',
    token: 'CAKE',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-hard-with-cake',
    earnedToken: 'mooCakeHARD',
    earnedTokenAddress: '0x4B4e5F2CeEdfF00D2DD13874714C1Ef6Ea1Cd8a7',
    earnContractAddress: '0x4B4e5F2CeEdfF00D2DD13874714C1Ef6Ea1Cd8a7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['CAKE'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },

  {
    id: 'cake-cake-bnb-eol',
    logo: 'bnb-pairs/CAKE-BNB.svg',
    name: 'CAKE-BNB LP',
    token: 'CAKE-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-cake-bnb-lp',
    earnedToken: 'mooPancakeCAKE-BNB',
    earnedTokenAddress: '0x502AB55Cf22f38c4fd8663dEE041A96B72264c53',
    earnContractAddress: '0x502AB55Cf22f38c4fd8663dEE041A96B72264c53',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-cake-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['CAKE', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    network: 'bsc',
  },

  {
    id: 'cake-link-bnb',
    logo: 'bnb-pairs/LINK-BNB.svg',
    name: 'LINK-BNB LP',
    token: 'LINK-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xaeBE45E3a03B734c68e5557AE04BFC76917B4686',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-link-bnb-lp',
    earnedToken: 'mooPancakeLINK-BNB',
    earnedTokenAddress: '0xaB9C4a0fD179315b005a5f6D3793cb30Af718152',
    earnContractAddress: '0xaB9C4a0fD179315b005a5f6D3793cb30Af718152',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-link-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['LINK', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    network: 'bsc',
  },

  {
    id: 'cake-busd-bnb',
    logo: 'bnb-pairs/BUSD-BNB.svg',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-busd-bnb-lp',
    earnedToken: 'mooPancakeBNB-BUSD',
    earnedTokenAddress: '0x0c36Bee5cF5E5b1BE5371574cEBF9A5f01DE5Fc4',
    earnContractAddress: '0x0c36Bee5cF5E5b1BE5371574cEBF9A5f01DE5Fc4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-busd-bnb',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['BUSD', 'BNB'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    network: 'bsc',
  },

  {
    id: 'cake-usdt-busd',
    logo: 'busd-pairs/USDT-BUSD.svg',
    name: 'USDT-BUSD LP',
    token: 'USDT-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-usdt-busd-lp',
    earnedToken: 'mooPancakeUSDT-BUSD',
    earnedTokenAddress: '0x348039C69B5fD7b333CE0a17E8361E501D2e3fa8',
    earnContractAddress: '0x348039C69B5fD7b333CE0a17E8361E501D2e3fa8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-usdt-busd',
    oraclePrice: 0,
    status: 'eol',
    platform: 'PancakeSwap',
    assets: ['USDT', 'BUSD'],
    risks: [],
    callFee: 0.5,
    removeLiquidityUrl:
      'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x55d398326f99059ff775485246999027b3197955',
    network: 'bsc',
  },

  {
    id: 'fortube-fil',
    logo: 'single-assets/FIL.svg',
    name: 'FIL',
    token: 'FIL',
    tokenDescription: 'ForTube',
    tokenAddress: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://filecoin.io/',
    earnedToken: 'mooForFIL',
    earnedTokenAddress: '0x5Bea18aBa4825bbE6450c280fBE8c22B1489cDd4',
    earnContractAddress: '0x5Bea18aBa4825bbE6450c280fBE8c22B1489cDd4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'FIL',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['FIL'],
    risks: [],
    callFee: 1,
    network: 'bsc',
  },

  {
    id: 'fortube-busd',
    logo: 'single-assets/BUSD.svg',
    name: 'BUSD',
    token: 'BUSD',
    tokenDescription: 'ForTube',
    tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://www.paxos.com/busd/',
    earnedToken: 'mooBUSD',
    earnedTokenAddress: '0xD6fca61Edb1cD0259320A6E0899E8aD1044BBCda',
    earnContractAddress: '0xD6fca61Edb1cD0259320A6E0899E8aD1044BBCda',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BUSD',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BUSD'],
    risks: [],
    callFee: 1,
    network: 'bsc',
  },

  {
    id: 'fortube-dot',
    logo: 'single-assets/DOT.png',
    name: 'DOT',
    token: 'DOT',
    tokenDescription: 'ForTube',
    tokenAddress: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://polkadot.network/',
    earnedToken: 'mooDOT',
    earnedTokenAddress: '0x63a2CEF28904956552714ddb226ceE96c3F0C9EF',
    earnContractAddress: '0x63a2CEF28904956552714ddb226ceE96c3F0C9EF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DOT',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['DOT'],
    risks: [],
    callFee: 1,
    network: 'bsc',
  },

  {
    id: 'fortube-usdt',
    logo: 'single-assets/USDT.svg',
    name: 'USDT',
    token: 'USDT',
    tokenDescription: 'ForTube',
    tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://tether.to',
    earnedToken: 'mooUSDT',
    earnedTokenAddress: '0xc16187F3eb15aEE949e7d784906d53fd7E364B26',
    earnContractAddress: '0xc16187F3eb15aEE949e7d784906d53fd7E364B26',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['USDT'],
    risks: [],
    callFee: 1,
    network: 'bsc',
  },

  {
    id: 'fortube-btcb',
    logo: 'single-assets/BTCB.svg',
    name: 'BTCB',
    token: 'BTCB',
    tokenDescription: 'ForTube',
    tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://coinmarketcap.com/currencies/bitcoin-bep2/',
    earnedToken: 'mooBTCB',
    earnedTokenAddress: '0x6F0Df0858542fC4A1c7f82c14fAA490d7763592F',
    earnContractAddress: '0x6F0Df0858542fC4A1c7f82c14fAA490d7763592F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BTCB',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BTCB'],
    risks: [],
    callFee: 1,
    network: 'bsc',
  },

  {
    id: 'fry-burger-v2',
    logo: 'single-assets/BURGER.png',
    name: 'BURGER v2',
    token: 'BURGER',
    tokenDescription: 'Retired Vault',
    tokenAddress: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/fryworld#farming-fries-with-burgers',
    earnedToken: 'mooFryBURGERv2',
    earnedTokenAddress: '0x2fd5f579e2cF9e3cb8127BBAd1B52c25750b1aCE',
    earnContractAddress: '0x2fd5f579e2cF9e3cb8127BBAd1B52c25750b1aCE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BURGER',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BURGER'],
    risks: [],
    callFee: 1,
    network: 'bsc',
  },

  {
    id: 'cake-syrup-twt',
    logo: 'single-assets/cakeTWT.png',
    name: 'CAKE TWT v1',
    token: 'CAKE',
    tokenDescription: 'Refunds available',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-twt-with-cake',
    earnedToken: 'mooCakeTWT',
    earnedTokenAddress: '0x214e640c638fbe68Fb24e11BfBd8Cd7d6cb8E37b',
    earnContractAddress: '0x214e640c638fbe68Fb24e11BfBd8Cd7d6cb8E37b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'CAKE',
    oraclePrice: 0,
    status: 'refund',
    refundContractAddress: '0x2fd5caAB20191dcd4423F588e254CC298C7F66A1',
    platform: 'PancakeSwap',
    assets: ['CAKE'],
    risks: [],
    callFee: 0.5,
    network: 'bsc',
  },
  {
    id: 'fry-burger-v1',
    logo: 'single-assets/BURGER.png',
    name: 'BURGER v1',
    token: 'BURGER',
    tokenDescription: 'Please migrate to v2',
    tokenAddress: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://fry.world/',
    earnedToken: 'mooFryBURGER',
    earnedTokenAddress: '0x78d60Da18c09bdE41397010198F454021Ec810Ad',
    earnContractAddress: '0x78d60Da18c09bdE41397010198F454021Ec810Ad',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BURGER',
    oraclePrice: 0,
    status: 'eol',
    platform: 'Other',
    assets: ['BURGER'],
    risks: [],
    callFee: 1,
    network: 'bsc',
  },
];
