export const HOME_FETCH_POOLS_BEGIN = 'HOME_FETCH_POOLS_BEGIN';
export const HOME_FETCH_POOLS_DONE = 'HOME_FETCH_POOLS_DONE';
export const WALLET_CREATE_MODAL = 'WALLET_CREATE_MODAL';
export const WALLET_CONNECT_BEGIN = 'WALLET_CONNECT_BEGIN';
export const WALLET_CONNECT_DONE = 'WALLET_CONNECT_DONE';
export const WALLET_DISCONNECT = 'WALLET_DISCONNECT';
export const WALLET_ACTION = 'WALLET_ACTION';
export const WALLET_ACTION_RESET = 'WALLET_ACTION_RESET';
export const BALANCE_FETCH_BALANCES_BEGIN = 'BALANCE_FETCH_BALANCES_BEGIN';
export const BALANCE_FETCH_BALANCES_DONE = 'BALANCE_FETCH_BALANCES_DONE';
export const SET_NETWORK = 'SET_NETWORK';
export const UNSUPPORTED_NETWORK = 'UNSUPPORTED_NETWORK';
